<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> Badges </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Components</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Badges</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 d-md-block d-none">
                <app-component-sidebar></app-component-sidebar>
            </div>

            <div class="col-lg-9 col-md-8 col-12">
                <div class="row">
                    <!-- Badges Start -->
                    <div class="col-12">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> Badges </h5>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted mb-2 pb-2">Ex. <code class="text-danger">&lt;span class="badge bg-primary"&gt; primary &lt;/span&gt;</code></h6>

                                <span class="badge bg-primary me-2 mt-2">Primary</span>
                                <span class="badge bg-secondary me-2 mt-2">Secondary</span>
                                <span class="badge bg-success me-2 mt-2">Success</span>
                                <span class="badge bg-danger me-2 mt-2">Danger</span>
                                <span class="badge bg-warning me-2 mt-2">Warning</span>
                                <span class="badge bg-info me-2 mt-2">Info</span>
                                <span class="badge bg-light text-dark me-2 mt-2">Light</span>
                                <span class="badge bg-dark me-2 mt-2">Dark</span>
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Badges End -->

                    <!-- Pill Badges Start -->
                    <div class="col-12 mt-4 pt-2">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> Pill Badges </h5>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted mb-2 pb-2">Ex. <code class="text-danger">&lt;span class="badge rounded-pill bg-primary"&gt; primary &lt;/span&gt;</code></h6>

                                <span class="badge rounded-pill bg-primary me-2 mt-2">Primary</span>
                                <span class="badge rounded-pill bg-secondary me-2 mt-2">Secondary</span>
                                <span class="badge rounded-pill bg-success me-2 mt-2">Success</span>
                                <span class="badge rounded-pill bg-danger me-2 mt-2">Danger</span>
                                <span class="badge rounded-pill bg-warning me-2 mt-2">Warning</span>
                                <span class="badge rounded-pill bg-info me-2 mt-2">Info</span>
                                <span class="badge rounded-pill bg-light text-dark me-2 mt-2">Light</span>
                                <span class="badge rounded-pill bg-dark me-2 mt-2">Dark</span>
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Pill Badges End -->

                    <!-- Badges Start -->
                    <div class="col-12 mt-4 pt-2">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0">Soft Badges </h5>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted mb-2 pb-2">Ex. <code class="text-danger">&lt;span class="badge bg-soft-primary"&gt; primary &lt;/span&gt;</code></h6>

                                <span class="badge bg-soft-primary me-2 mt-2">Primary</span>
                                <span class="badge bg-soft-secondary me-2 mt-2">Secondary</span>
                                <span class="badge bg-soft-success me-2 mt-2">Success</span>
                                <span class="badge bg-soft-danger me-2 mt-2">Danger</span>
                                <span class="badge bg-soft-warning me-2 mt-2">Warning</span>
                                <span class="badge bg-soft-info me-2 mt-2">Info</span>
                                <span class="badge bg-soft-light text-dark me-2 mt-2">Light</span>
                                <span class="badge bg-soft-dark me-2 mt-2">Dark</span>
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Badges End -->

                    <!-- Pill Badges Start -->
                    <div class="col-12 mt-4 pt-2">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0">Soft Pill Badges </h5>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted mb-2 pb-2">Ex. <code class="text-danger">&lt;span class="badge rounded-pill bg-soft-primary"&gt; primary &lt;/span&gt;</code></h6>

                                <span class="badge rounded-pill bg-soft-primary me-2 mt-2">Primary</span>
                                <span class="badge rounded-pill bg-soft-secondary me-2 mt-2">Secondary</span>
                                <span class="badge rounded-pill bg-soft-success me-2 mt-2">Success</span>
                                <span class="badge rounded-pill bg-soft-danger me-2 mt-2">Danger</span>
                                <span class="badge rounded-pill bg-soft-warning me-2 mt-2">Warning</span>
                                <span class="badge rounded-pill bg-soft-info me-2 mt-2">Info</span>
                                <span class="badge rounded-pill bg-soft-light text-dark me-2 mt-2">Light</span>
                                <span class="badge rounded-pill bg-soft-dark me-2 mt-2">Dark</span>
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Pill Badges End -->

                    <!-- Link Badges Start -->
                    <div class="col-12 mt-4 pt-2">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> Link Badges </h5>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted mb-2 pb-2">Ex. <code class="text-danger">&lt;a href="javascript:void(0)" class="badge bg-primary"&gt; primary &lt;/a&gt;</code></h6>

                                <a href="javascript:void(0)" class="badge badge-link bg-primary me-2 mt-2">Primary</a>
                                <a href="javascript:void(0)" class="badge badge-link bg-secondary me-2 mt-2">Secondary</a>
                                <a href="javascript:void(0)" class="badge badge-link bg-success me-2 mt-2">Success</a>
                                <a href="javascript:void(0)" class="badge badge-link bg-danger me-2 mt-2">Danger</a>
                                <a href="javascript:void(0)" class="badge badge-link bg-warning me-2 mt-2">Warning</a>
                                <a href="javascript:void(0)" class="badge badge-link bg-info me-2 mt-2">Info</a>
                                <a href="javascript:void(0)" class="badge badge-link bg-light text-dark me-2 mt-2">Light</a>
                                <a href="javascript:void(0)" class="badge badge-link bg-dark me-2 mt-2">Dark</a>
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Link Badges End -->
                </div><!--end row-->
            </div><!--end col-->
        </div>
    </div>
</section>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->

