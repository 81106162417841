<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> Cards </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Components</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Cards</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 d-md-block d-none">
                <app-component-sidebar></app-component-sidebar>
            </div>

            <div class="col-lg-9 col-md-8 col-12">
                <div class="row">
                    <!-- Card Start -->
                    <div class="col">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> Cards </h5>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted pb-2">Vertical Card<br> 
                                    <h6 class="text-muted mb-2 pb-2">Ex. 
                                        <pre><code class="text-danger">
&lt;div class="card shadow rounded border-0 overflow-hidden"&gt;
&lt;img src="assets/images/blog/01.jpg" class="img-fluid" alt=""&gt;
&lt;div class="card-body"&gt;
&lt;h5 class="card-title"&gt;Saas & Software : Landrick&lt;/h5&gt;
&lt;p class="text-muted"&gt;Due to its widespread use as filler text for layouts, non-readability is of great importance&lt;/p&gt;
&lt;a href="javascript:void(0)" class="text-primary"&gt;Read More &lt;i class="uil uil-angle-right-b align-middle"&gt;&lt;/i&gt;&lt;/a&gt;
&lt;/div&gt;
&lt;/div&gt;
</code></pre>
                                </h6>
</h6>
</div>
                                <div class="row">
                                    <div class="col-lg-6 col-12 mt-4 pt-2">
                                        <div class="card shadow rounded border-0 overflow-hidden">
                                            <img src="assets/images/blog/01.jpg" class="img-fluid" alt="">
                                            <div class="card-body">
                                                <h5 class="card-title">Saas & Software : Landrick</h5>
                                                <p class="text-muted">Due to its widespread use as filler text for layouts, non-readability is of great importance</p>
                                                <a href="javascript:void(0)" class="text-primary">Read More <i class="uil uil-angle-right-b align-middle"></i></a>
                                            </div>
                                        </div>
                                    </div><!--end col-->

                                    <div class="col-lg-6 col-12 mt-4 pt-2">
                                        <div class="card shadow rounded border-0 overflow-hidden">
                                            <div class="card-body">
                                                <h5 class="card-title">Saas & Software : Landrick</h5>
                                                <p class="text-muted">Due to its widespread use as filler text for layouts, non-readability is of great importance</p>
                                                <a href="javascript:void(0)" class="text-primary">Read More <i class="uil uil-angle-right-b align-middle"></i></a>
                                            </div>
                                            <img src="assets/images/blog/01.jpg" class="img-fluid" alt="">
                                        </div>
                                    </div><!--end col-->
                                </div>

                                <div class="mt-4 pt-4 border-top">
                                    <h6 class="text-muted pb-2">Horizontal Card<br> 
                                        <h6 class="text-muted mb-2 pb-2">Ex. 
                                            <pre><code class="text-danger">
&lt;div class="card shadow rounded border-0 overflow-hidden"&gt;
&lt;div class="row g-0"&gt;
&lt;div class="col-md-5"&gt;
    &lt;img src="assets/images/work/14.jpg" class="img-fluid" alt="..."&gt;
&lt;/div&gt;
&lt;div class="col-md-7"&gt;
    &lt;div class="card-body"&gt;
        &lt;h5 class="card-title"&gt;Saas & Software : Landrick&lt;/h5&gt;
        &lt;p class="card-text"&gt;This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.&lt;/p&gt;
        &lt;p class="card-text"&gt;&lt;small class="text-muted"&gt;Last updated 3 mins ago&lt;/small&gt;&lt;/p&gt;
    &lt;/div&gt;
&lt;/div&gt;
&lt;/div&gt;
&lt;/div&gt;
</code></pre>
                                    </h6>
                                    </h6>
                                <div class="row">
                                    <div class="col-lg-12 mt-4 pt-2">
                                        <div class="card shadow rounded border-0 overflow-hidden">
                                            <div class="row g-0">
                                                <div class="col-md-5">
                                                    <img src="assets/images/work/14.jpg" class="img-fluid" alt="...">
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="card-body">
                                                        <h5 class="card-title">Saas & Software : Landrick</h5>
                                                        <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                                        <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div><!--end col-->
                                </div><!--end row-->
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Card End -->
                </div><!--end row-->
            </div><!--end col-->
        </div>
    </div>
</section>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->

