import { Component } from '@angular/core';

@Component({
  selector: 'app-hosting-blog-detail',
  templateUrl: './hosting-blog-detail.component.html',
  styleUrls: ['./hosting-blog-detail.component.css']
})
export class HostingBlogDetailComponent {
  navClass = 'nav-light';
    // Set Topbar Option
  Menuoption = 'hosting';
  Settingicon = true
  footerVariant = 'footer-ten';
}
