<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Documentation </h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Docs</a></li>
                <li class="breadcrumb-item active" aria-current="page">Documentation</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

      <!-- Documentation Start -->
      <section class="section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h4 class="mb-4">Introduction</h4>
                    <p class="text-muted h6 fw-normal">Get Start With <span class="text-primary fw-bold">Landrick.</span> Launch your campaign and benefit from our expertise on designing and managing conversion centered <a href="https://getbootstrap.com/" target="_blank" class="fw-bold text-primary">Bootstrap 5</a> angular page.</p>
                    <p class="text-muted h6 fw-normal">Landrick is a Powerful Saas & Software Bootstrap Template. It is an excellent Angular template for startup, coworking space, cloud hosting, car ride, classic saas, classic application, event, business, application, educational course, personal portfolio, services, enterprise, minimal portfolio single product, saas, Social media marketing, Digital marketing/agency, Email Templates, Online Learning Course, marketing, agency, Careers, Customer Supports, Onepage Landing, and much more. Landrick is fully updated with the latest Bootstrap v5.1.3 and Gulp file. We are provide a Dark version with RTL supported in both the Light and Dark versions.</p>

                    <p class="text-muted h6 fw-normal"><b>Admin:</b> We are provide fully responsive Admin Dashboard template. Also, We are provide a Dark version with RTL supported in both the Light and Dark versions</p>

                    <h4 class="mt-5">Setup Angular</h4>

                    <h5 class="mt-4">Prerequisites</h5>
                    <p class="text-muted mb-0">Please follow below steps to install and setup all prerequisites:</p>

                    <ul class="list-unstyled mt-3">
                        <li class="d-flex mt-2">
                            <i class="mdi mdi-arrow-right"></i>
                            <div class="ms-2">
                                <h6>Nodejs</h6>
                                <p class="text-muted">Make sure to have the <a href="https://nodejs.org/" class="text-primary fw-bold" target="_blank">Node.js</a> installed & running in your computer. If you already have installed Node on your computer, you can skip this step if your existing node version is greater than 18. We suggest you to use LTS version of Node.js.</p>
                            </div>
                        </li>

                        <li class="d-flex mt-2">
                            <i class="mdi mdi-arrow-right"></i>
                            <div class="ms-2">
                                <h6>Git</h6>
                                <p class="text-muted">Make sure to have the <a href="https://git-scm.com/" class="text-primary fw-bold" target="_blank">Git</a> installed globally & running on your computer. If you already have installed git on your computer, you can skip this step.</p>
                            </div>
                        </li>
                    </ul>

                    <h5 class="mt-4">Installation</h5>
                    <p class="text-muted mb-0">To setup the admin theme, follow below-mentioned steps:</p>

                    <ul class="list-unstyled mt-3">
                        <li class="d-flex">
                            <i class="mdi mdi-arrow-right"></i>
                            <div class="ms-2">
                                <h6>Install Prerequisites</h6>
                                <p class="text-muted">Make sure to have all above prerequisites installed & running on your computer</p>
                            </div>
                        </li>
                    </ul>

                    <p class="text-muted mb-4">After you finished with the above steps, you can run the following commands into the terminal / command prompt from the root directory of the project to run the project locally or build for production use:</p>

                    <div class="table-responsive bg-white rounded">
                        <table class="table table-bordered mb-0 table-center">
                            <thead>
                                <tr>
                                    <th style="width: 20%;"><i class="ti-file"></i> Command</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="fs-5">
                                        <code>npm install</code>
                                    </td>
                                    <td>
                                        This would install all the required dependencies in the <code class="fw-bold">node_modules</code> folder.
                                    </td>
                                </tr>

                                <tr>
                                    <td class="fs-5">
                                        <code>ng serve</code>
                                    </td>
                                    <td>
                                        Runs the project locally, starts the development server and watches for any changes in your code. The development server is accessible at <a href="http://localhost:3000" class="text-primary fw-bold" target="_blank">http://localhost:4200</a>.
                                    </td>
                                </tr>

                                <tr>
                                    <td class="fs-5">
                                        <code>ng build</code>
                                    </td>
                                    <td>
                                        Generates a <code class="fw-bold">/dist</code> directory with all the production files.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!-- structure Start -->
    <section class="section bg-light" id="structure">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="mb-4">
                        <h4 class="title">Folder & File Structure</h4>
                    </div>
                    
                    <div class="row">
                        <div class="col-lg-6 col-12 mt-4">
<pre class="mb-0">
  <h5>Landing Template</h5>
  ├── Admin
  ├── StarterKit
  ├── Documentation
  
  ├── Admin
      ├── e2e
      ├── src
          ├── app 
          ├── assets 
              ├── fonts
              ├── images
              └── css
                  ├── custom
                  ├── _variables-dark.scss
                  ├── _variables.scss
                  ├── app-dark.scss 
                  ├── app.scss
                  ├── bootstrap-dark.scss
                  ├── bootstrap.scss 
                  └── icons.scss
          ├── environments
          ├── favicon.ico
          ├── index.html
          ├── main.ts
          ├── polyfills.ts
          ├── styles.scss
          └── test.ts
      ├── angular.json
      ├── karma.conf.js
      ├── package-lock.json
      ├── package.json
      ├── README.md
      ├── tsconfig.app.json
      ├── tsconfig.json
      ├── tsconfig.spec.json
      └── tslint.json
  </pre>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
    <!-- structure End -->

    <section class="section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h4>Theme Styles for both Admin Dashboard and Landing Template</h4>

                    <h5 class="mt-5">Dark Version</h5>
                    <h6 class="h6 mt-4"><i class="uil uil-angle-right-b"></i> Dark Version : </h6>
                    <div class="mt-4 p-4 bg-light rounded shadow">
                        <p class="mb-0 text-muted">In order to have Dark mode enabled, replace the reference of <code class="fw-bold">bootstrap.min.css</code> to <code class="fw-bold">bootstrap-dark.min.css</code> and also change <code class="fw-bold">style.css</code> to <code class="fw-bold">style-dark.css</code> in the <code class="fw-bold">src/index.html</code></p>
                    </div><!--end Dark-->
                    
                    <h6 class="h6 mt-4"><i class="uil uil-angle-right-b"></i> Dark RTL Version : </h6>
                    <div class="mt-4 p-4 bg-light rounded shadow">
                        <p class="mb-0 text-muted">In order to have Dark RTL mode enabled, Please changes in the <code class="fw-bold">src/index.html</code> file update the html tag like <code class="fw-bold">&lt;html lang="en" dir="rtl"&gt;</code> and than replace the reference of <code class="fw-bold">bootstrap.min.css</code> to <code class="fw-bold">bootstrap-dark-rtl.min.css</code> and also change <code class="fw-bold">style.css</code> to <code class="fw-bold">style-dark-rtl.css</code></p>
                    </div><!--end Dark RTL-->

                    <h5 class="mt-5">Light Version</h5>
                    <h6 class="h6 mt-4"><i class="uil uil-angle-right-b"></i> Light Version : </h6>
                    <div class="mt-4 p-4 bg-light rounded shadow">
                        <p class="mb-0 text-muted">In order to have Dark mode enabled, replace the reference of <code class="fw-bold">bootstrap-dark.min.css</code> to <code class="fw-bold">bootstrap.min.css</code> and also change <code class="fw-bold">style-dark.css</code> to <code class="fw-bold">style.css</code> in the <code class="fw-bold">src/index.html</code></p>
                    </div><!--end Dark-->
                    
                    <h6 class="h6 mt-4"><i class="uil uil-angle-right-b"></i> Light RTL Version : </h6>
                    <div class="mt-4 p-4 bg-light rounded shadow">
                        <p class="mb-0 text-muted">In order to have Dark RTL mode enabled, Please changes in the <code class="fw-bold">src/index.html</code> file update the html tag like <code class="fw-bold">&lt;html lang="en" dir="rtl"&gt;</code> and than replace the reference of <code class="fw-bold">bootstrap.min.css</code> to <code class="fw-bold">bootstrap-rtl.min.css</code> and also change <code class="fw-bold">style.css</code> to <code class="fw-bold">style-rtl.css</code></p>
                    </div><!--end Dark RTL-->

                    
                </div><!--end col-->
            </div><!--end row-->
        </div><!--end container-->
    </section><!--en d section-->
    <!-- Documentation End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->