<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> Tooltips </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Components</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Tooltips & Popovers</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 d-md-block d-none">
                <app-component-sidebar></app-component-sidebar>
            </div>

            <div class="col-lg-9 col-md-8 col-12">
                <div class="row">
                    <!-- Tooltips Start -->
                    <div class="col">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h4 class="title mb-0"> Tooltips </h4>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted mb-2 pb-2">Ex. <br>
                                    <pre><code class="text-danger">
&lt;a href="javascript:void" class="btn btn-primary me-2 mt-2" placement="top" ngbTooltip="Top Tooltip"&gt;Top&lt;/a&gt;
&lt;a href="javascript:void" class="btn btn-primary me-2 mt-2" placement="left" ngbTooltip="Left Tooltip"&gt;Left&lt;/a&gt;
&lt;a href="javascript:void" class="btn btn-primary me-2 mt-2" placement="bottom" ngbTooltip="Bottom Tooltip"&gt;Bottom&lt;/a&gt;
&lt;a href="javascript:void" class="btn btn-primary me-2 mt-2" placement="right" ngbTooltip="Right Tooltip"&gt;Right&lt;/a&gt;
</code></pre>
                                </h6>

                                <a href="javascript:void" class="btn btn-primary me-2 mt-2" placement="top"
                                    ngbTooltip="Top Tooltip">Top</a>
                                <a href="javascript:void" class="btn btn-primary me-2 mt-2" placement="left"
                                    ngbTooltip="Left Tooltip">Left</a>
                                <a href="javascript:void" class="btn btn-primary me-2 mt-2" placement="bottom"
                                    ngbTooltip="Bottom Tooltip">Bottom</a>
                                <a href="javascript:void" class="btn btn-primary me-2 mt-2" placement="right"
                                    ngbTooltip="Right Tooltip">Right</a>
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Tooltips End -->

                    <!-- Popovers Start -->
                    <div class="col">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h4 class="title mb-0"> Popovers </h4>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted mb-2 pb-2">Ex. <br>
                                    <pre><code class="text-danger">
&lt;a href="javascript:void" class="btn btn-primary me-2 mt-2" placement="top" triggers="click:blur" ngbTooltip="Top Tooltip"&gt;Top&lt;/a&gt;
&lt;a href="javascript:void" class="btn btn-primary me-2 mt-2" placement="left" triggers="click:blur" ngbTooltip="Left Tooltip"&gt;Left&lt;/a&gt;
&lt;a href="javascript:void" class="btn btn-primary me-2 mt-2" placement="bottom" triggers="click:blur" ngbTooltip="Bottom Tooltip"&gt;Bottom&lt;/a&gt;
&lt;a href="javascript:void" class="btn btn-primary me-2 mt-2" placement="right" triggers="click:blur" ngbTooltip="Right Tooltip"&gt;Right&lt;/a&gt;
</code></pre>
                                </h6>
                                <a href="javascript:void" class="btn btn-primary me-2 mt-2" triggers="click:blur" placement="top" ngbTooltip="Top popover">Top</a>
                                <a href="javascript:void" class="btn btn-primary me-2 mt-2" triggers="click:blur" placement="left" ngbTooltip="Left popover">Left</a>
                                <a href="javascript:void" class="btn btn-primary me-2 mt-2" triggers="click:blur" placement="bottom" ngbTooltip="Bottom popover">Bottom</a>
                                <a href="javascript:void" class="btn btn-primary me-2 mt-2" triggers="click:blur" placement="right" ngbTooltip="Right popover">Right</a>
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Popovers End -->
                </div><!--end row-->
            </div><!--end col-->
        </div>
    </div>
</section>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->