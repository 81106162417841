<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> Avatar </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Components</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Avatar</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 d-md-block d-none">
                <app-component-sidebar></app-component-sidebar>
            </div>

            <div class="col-lg-9 col-md-8 col-12">
                <div class="row">
                    <!-- Default Avatars Start -->
                    <div class="col-12">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> Default Avatars </h5>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted mb-4 pb-2">Ex. <code class="text-danger">&lt;img src="assets/images/home/01.jpg" class="avatar avatar-ex-sm rounded"&gt;</code></h6>
                                
                                <img src="assets/images/client/05.jpg" class="avatar avatar-ex-sm me-2 rounded" data-bs-toggle="tooltip" data-bs-placement="bottom" title="avatar-ex-sm" alt="">
                                <img src="assets/images/client/05.jpg" class="avatar avatar-ex-small me-2 rounded" data-bs-toggle="tooltip" data-bs-placement="bottom" title="avatar-ex-small" alt="">
                                <img src="assets/images/client/05.jpg" class="avatar avatar-md-sm me-2 rounded" data-bs-toggle="tooltip" data-bs-placement="bottom" title="avatar-md-sm" alt="">
                                <img src="assets/images/client/05.jpg" class="avatar avatar-small me-2 rounded" data-bs-toggle="tooltip" data-bs-placement="bottom" title="avatar-small" alt="">
                                <img src="assets/images/client/05.jpg" class="avatar avatar-md-md mt-sm-0 me-2 rounded" data-bs-toggle="tooltip" data-bs-placement="bottom" title="avatar-md-md" alt="">
                                <img src="assets/images/client/05.jpg" class="avatar avatar-medium mt-sm-0 me-2 rounded" data-bs-toggle="tooltip" data-bs-placement="bottom" title="avatar-medium" alt="">
                                <img src="assets/images/client/05.jpg" class="avatar avatar-large mt-sm-0 me-2 rounded" data-bs-toggle="tooltip" data-bs-placement="bottom" title="avatar-large" alt="">
                                <img src="assets/images/client/05.jpg" class="avatar avatar-ex-large mt-sm-0 me-2 rounded" data-bs-toggle="tooltip" data-bs-placement="bottom" title="avatar-ex-large" alt="">
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Default Avatars End -->

                    <!-- Circle Avatars Start -->
                    <div class="col-12 mt-4 pt-2">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> Circle Avatars </h5>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted mb-4 pb-2">Ex. <code class="text-danger">&lt;img src="assets/images/home/01.jpg" class="avatar avatar-ex-sm rounded-circle"&gt;</code></h6>
                                
                                <img src="assets/images/client/05.jpg" class="avatar avatar-ex-sm me-2 rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="avatar-ex-sm" alt="">
                                <img src="assets/images/client/05.jpg" class="avatar avatar-ex-small me-2 rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="avatar-ex-small" alt="">
                                <img src="assets/images/client/05.jpg" class="avatar avatar-md-sm me-2 rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="avatar-md-sm" alt="">
                                <img src="assets/images/client/05.jpg" class="avatar avatar-small me-2 rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="avatar-small" alt="">
                                <img src="assets/images/client/05.jpg" class="avatar avatar-md-md mt-sm-0 me-2 rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="avatar-md-md" alt="">
                                <img src="assets/images/client/05.jpg" class="avatar avatar-medium mt-sm-0 me-2 rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="avatar-medium" alt="">
                                <img src="assets/images/client/05.jpg" class="avatar avatar-large mt-sm-0 me-2 rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="avatar-large" alt="">
                                <img src="assets/images/client/05.jpg" class="avatar avatar-ex-large mt-sm-0 me-2 rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="avatar-ex-large" alt="">
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Circle Avatars End -->
                </div><!--end row-->
            </div><!--end col-->
        </div>
    </div>
</section>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->
