<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" style="background-image: url('assets/images/hosting/pages.png');" id="home">
    <div class="bg-overlay bg-gradient-primary opacity-9"></div>
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading title-heading">
                    <h4 class="title text-white title-dark mb-4"> Blogs </h4>
                    <p class="text-white-50 para-desc mx-auto mb-0">Launch your campaign and benefit from our expertise
                        on designing and managing conversion centered bootstrap v5 html page.</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a routerLink="/index-hosting">Hosting</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Blogs & News</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Hero End -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2">
                <div class="card blog blog-primary shadow border-0 rounded overflow-hidden">
                    <img src="assets/images/hosting/blog/01.jpg" class="img-fluid" alt="">

                    <div class="card-body content">
                        <a routerLink="/hosting-blog-detail" class="title text-dark h5">Quickly formulate backend</a>

                        <p class="text-muted mt-3">This is required when, for example, the final text is not yet
                            available.</p>

                        <div class="post-meta d-flex justify-content-between mt-3">
                            <ul class="list-unstyled mb-0">
                                <li class="list-inline-item me-2 mb-0"><a href="javascript:void(0)"
                                        class="text-muted like"><i class="uil uil-heart me-1"></i>33</a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                                            class="uil uil-comment me-1"></i>08</a></li>
                            </ul>
                            <a routerLink="/hosting-blog-detail" class="text-muted readmore">Read More <i
                                    class="uil uil-angle-right-b align-middle"></i></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2">
                <div class="card blog blog-primary shadow border-0 rounded overflow-hidden">
                    <img src="assets/images/hosting/blog/02.jpg" class="img-fluid" alt="">

                    <div class="card-body content">
                        <a routerLink="/hosting-blog-detail" class="title text-dark h5">Progressively visualize
                            enabled</a>

                        <p class="text-muted mt-3">This is required when, for example, the final text is not yet
                            available.</p>

                        <div class="post-meta d-flex justify-content-between mt-3">
                            <ul class="list-unstyled mb-0">
                                <li class="list-inline-item me-2 mb-0"><a href="javascript:void(0)"
                                        class="text-muted like"><i class="uil uil-heart me-1"></i>33</a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                                            class="uil uil-comment me-1"></i>08</a></li>
                            </ul>
                            <a routerLink="/hosting-blog-detail" class="text-muted readmore">Read More <i
                                    class="uil uil-angle-right-b align-middle"></i></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2">
                <div class="card blog blog-primary shadow border-0 rounded overflow-hidden">
                    <img src="assets/images/hosting/blog/03.jpg" class="img-fluid" alt="">

                    <div class="card-body content">
                        <a routerLink="/hosting-blog-detail" class="title text-dark h5">Credibly implement maximizing</a>

                        <p class="text-muted mt-3">This is required when, for example, the final text is not yet
                            available.</p>

                        <div class="post-meta d-flex justify-content-between mt-3">
                            <ul class="list-unstyled mb-0">
                                <li class="list-inline-item me-2 mb-0"><a href="javascript:void(0)"
                                        class="text-muted like"><i class="uil uil-heart me-1"></i>33</a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                                            class="uil uil-comment me-1"></i>08</a></li>
                            </ul>
                            <a routerLink="/hosting-blog-detail" class="text-muted readmore">Read More <i
                                    class="uil uil-angle-right-b align-middle"></i></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2">
                <div class="card blog blog-primary shadow border-0 rounded overflow-hidden">
                    <img src="assets/images/hosting/blog/04.jpg" class="img-fluid" alt="">

                    <div class="card-body content">
                        <a routerLink="/hosting-blog-detail" class="title text-dark h5">Quickly formulate backend</a>

                        <p class="text-muted mt-3">This is required when, for example, the final text is not yet
                            available.</p>

                        <div class="post-meta d-flex justify-content-between mt-3">
                            <ul class="list-unstyled mb-0">
                                <li class="list-inline-item me-2 mb-0"><a href="javascript:void(0)"
                                        class="text-muted like"><i class="uil uil-heart me-1"></i>33</a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                                            class="uil uil-comment me-1"></i>08</a></li>
                            </ul>
                            <a routerLink="/hosting-blog-detail" class="text-muted readmore">Read More <i
                                    class="uil uil-angle-right-b align-middle"></i></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2">
                <div class="card blog blog-primary shadow border-0 rounded overflow-hidden">
                    <img src="assets/images/hosting/blog/05.jpg" class="img-fluid" alt="">

                    <div class="card-body content">
                        <a routerLink="/hosting-blog-detail" class="title text-dark h5">Progressively visualize
                            enabled</a>

                        <p class="text-muted mt-3">This is required when, for example, the final text is not yet
                            available.</p>

                        <div class="post-meta d-flex justify-content-between mt-3">
                            <ul class="list-unstyled mb-0">
                                <li class="list-inline-item me-2 mb-0"><a href="javascript:void(0)"
                                        class="text-muted like"><i class="uil uil-heart me-1"></i>33</a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                                            class="uil uil-comment me-1"></i>08</a></li>
                            </ul>
                            <a routerLink="/hosting-blog-detail" class="text-muted readmore">Read More <i
                                    class="uil uil-angle-right-b align-middle"></i></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2">
                <div class="card blog blog-primary shadow border-0 rounded overflow-hidden">
                    <img src="assets/images/hosting/blog/06.jpg" class="img-fluid" alt="">

                    <div class="card-body content">
                        <a routerLink="/hosting-blog-detail" class="title text-dark h5">Quickly formulate backend</a>

                        <p class="text-muted mt-3">This is required when, for example, the final text is not yet
                            available.</p>

                        <div class="post-meta d-flex justify-content-between mt-3">
                            <ul class="list-unstyled mb-0">
                                <li class="list-inline-item me-2 mb-0"><a href="javascript:void(0)"
                                        class="text-muted like"><i class="uil uil-heart me-1"></i>33</a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                                            class="uil uil-comment me-1"></i>08</a></li>
                            </ul>
                            <a routerLink="/hosting-blog-detail" class="text-muted readmore">Read More <i
                                    class="uil uil-angle-right-b align-middle"></i></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2">
                <div class="card blog blog-primary shadow border-0 rounded overflow-hidden">
                    <img src="assets/images/hosting/blog/07.jpg" class="img-fluid" alt="">

                    <div class="card-body content">
                        <a routerLink="/hosting-blog-detail" class="title text-dark h5">Credibly implement maximizing</a>

                        <p class="text-muted mt-3">This is required when, for example, the final text is not yet
                            available.</p>

                        <div class="post-meta d-flex justify-content-between mt-3">
                            <ul class="list-unstyled mb-0">
                                <li class="list-inline-item me-2 mb-0"><a href="javascript:void(0)"
                                        class="text-muted like"><i class="uil uil-heart me-1"></i>33</a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                                            class="uil uil-comment me-1"></i>08</a></li>
                            </ul>
                            <a routerLink="/hosting-blog-detail" class="text-muted readmore">Read More <i
                                    class="uil uil-angle-right-b align-middle"></i></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2">
                <div class="card blog blog-primary shadow border-0 rounded overflow-hidden">
                    <img src="assets/images/hosting/blog/08.jpg" class="img-fluid" alt="">

                    <div class="card-body content">
                        <a routerLink="/hosting-blog-detail" class="title text-dark h5">Progressively visualize
                            enabled</a>

                        <p class="text-muted mt-3">This is required when, for example, the final text is not yet
                            available.</p>

                        <div class="post-meta d-flex justify-content-between mt-3">
                            <ul class="list-unstyled mb-0">
                                <li class="list-inline-item me-2 mb-0"><a href="javascript:void(0)"
                                        class="text-muted like"><i class="uil uil-heart me-1"></i>33</a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                                            class="uil uil-comment me-1"></i>08</a></li>
                            </ul>
                            <a routerLink="/hosting-blog-detail" class="text-muted readmore">Read More <i
                                    class="uil uil-angle-right-b align-middle"></i></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mb-4 pb-2">
                <div class="card blog blog-primary shadow border-0 rounded overflow-hidden">
                    <img src="assets/images/hosting/blog/09.jpg" class="img-fluid" alt="">

                    <div class="card-body content">
                        <a routerLink="/hosting-blog-detail" class="title text-dark h5">Credibly implement maximizing</a>

                        <p class="text-muted mt-3">This is required when, for example, the final text is not yet
                            available.</p>

                        <div class="post-meta d-flex justify-content-between mt-3">
                            <ul class="list-unstyled mb-0">
                                <li class="list-inline-item me-2 mb-0"><a href="javascript:void(0)"
                                        class="text-muted like"><i class="uil uil-heart me-1"></i>33</a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                                            class="uil uil-comment me-1"></i>08</a></li>
                            </ul>
                            <a routerLink="/hosting-blog-detail" class="text-muted readmore">Read More <i
                                    class="uil uil-angle-right-b align-middle"></i></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <!-- PAGINATION START -->
            <div class="col-12">
                <ul class="pagination justify-content-center mb-0">
                    <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Previous">Prev</a>
                    </li>
                    <li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Next">Next</a></li>
                </ul>
            </div><!--end col-->
            <!-- PAGINATION END -->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->