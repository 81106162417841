        <!-- Start -->
        <section class="bg-half-170 d-table w-100 bg-primary" style="background-image: url('assets/images/hosting/cloud.png');" id="home">
            <div class="bg-overlay opacity-4"></div>
            <div class="container">
                <div class="row mt-5 align-items-center">
                    <div class="col-lg-8 col-md-7 col-12">
                        <div class="title-heading me-lg-5">
                            <h4 class="heading text-white title-dark mb-3">Cloud Hosting</h4>
                            <p class="text-white-50 para-desc">Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap v5 html page.</p>
                            
                            <ul class="feature-list list-unstyled mb-0">
                                <li class="text-white-50 my-1"><i class="mdi mdi-arrow-right text-warning me-2"></i><span class="fw-bold">10GB</span> Ram</li>
                                <li class="text-white-50 my-1"><i class="mdi mdi-arrow-right text-warning me-2"></i><span class="fw-bold">100GB</span> Bandwith</li>
                                <li class="text-white-50 my-1"><i class="mdi mdi-arrow-right text-warning me-2"></i><span class="fw-bold">100GB SSD</span> Storage</li>
                                <li class="text-white-50 my-1"><i class="mdi mdi-arrow-right text-warning me-2"></i><span class="fw-bold">3 Domain</span> Hosted Support</li>
                                <li class="text-white-50 my-1"><i class="mdi mdi-arrow-right text-warning me-2"></i><span class="fw-bold">50</span> Email Account</li>
                                <li class="text-white-50 my-1"><i class="mdi mdi-arrow-right text-warning me-2"></i><span class="fw-bold">5</span> Database</li>
                                <li class="text-white-50 my-1"><i class="mdi mdi-arrow-right text-warning me-2"></i><span class="fw-bold">Unlimited</span> Traffic</li>
                            </ul>
                        </div>
                    </div><!--end col-->

                    <div class="col-lg-4 col-md-5 col-12">
                        <div class="card border-0 rounded-md shadow text-center">
                            <div class="card-body px-4 py-5">
                                <h5 class="text-primary">Special Offer Price</h5>
                                <div class="d-flex justify-content-center my-3">
                                    <span class="h5 text-uted mb-0 mt-2">$</span>
                                    <span class="price text-dark display-4 fw-bold mb-0">4.99</span>
                                    <span class="h5 text-uted align-self-end mb-1">/mo</span>
                                </div>  
                                <h6 class="mb-0">Original Price <span class="text-warning">$10.99</span></h6>
                                <a href="javascript:void(0)" class="btn btn-soft-primary mt-4">Get Started</a>
                            </div>
                        </div>  
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- End -->

        <!-- Start -->
        <section class="section">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <div class="section-title text-center mb-4 pb-2">
                            <h4 class="title mb-3">Server Features</h4>
                            <p class="text-muted para-desc mx-auto mb-0">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->

                <div class="row">
                    <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                        <div class="card features feature-primary explore-feature border-0 rounded text-center">
                            <div class="card-body">
                                <span class="icons rounded-circle shadow-lg d-inline-block h4">
                                    <i class="uil uil-panel-add"></i>
                                </span>
                                <div class="content mt-3">
                                    <h5 class="mb-3"><a href="javascript:void(0)" class="title text-dark">Free cPanel</a></h5>
                                    <p class="text-muted mb-3">We provide cloud based enterprise hosting, server and storage solutions of unmatched quality.</p>
                                    <a href="javascript:void(0)" class="read-more">Read More <i-feather name="chevron-right" class="fea icon-sm"></i-feather></a>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                        <div class="card features feature-primary explore-feature border-0 rounded text-center">
                            <div class="card-body">
                                <span class="icons rounded-circle shadow-lg d-inline-block h4">
                                    <i class="uil uil-cell"></i>
                                </span>
                                <div class="content mt-3">
                                    <h5 class="mb-3"><a href="javascript:void(0)" class="title text-dark">Easy Installation SSL</a></h5>
                                    <p class="text-muted mb-3">We provide cloud based enterprise hosting, server and storage solutions of unmatched quality.</p>
                                    <a href="javascript:void(0)" class="read-more">Read More <i-feather name="chevron-right" class="fea icon-sm"></i-feather></a>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                        <div class="card features feature-primary explore-feature border-0 rounded text-center">
                            <div class="card-body">
                                <span class="icons rounded-circle shadow-lg d-inline-block h4">
                                    <i class="uil uil-webcam"></i>
                                </span>
                                <div class="content mt-3">
                                    <h5 class="mb-3"><a href="javascript:void(0)" class="title text-dark">Easy 1-click-installer</a></h5>
                                    <p class="text-muted mb-3">We provide cloud based enterprise hosting, server and storage solutions of unmatched quality.</p>
                                    <a href="javascript:void(0)" class="read-more">Read More <i-feather name="chevron-right" class="fea icon-sm"></i-feather></a>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                        <div class="card features feature-primary explore-feature border-0 rounded text-center">
                            <div class="card-body">
                                <span class="icons rounded-circle shadow-lg d-inline-block h4">
                                    <i class="uil uil-tachometer-fast-alt"></i>
                                </span>
                                <div class="content mt-3">
                                    <h5 class="mb-3"><a href="javascript:void(0)" class="title text-dark">Fast Websites</a></h5>
                                    <p class="text-muted mb-3">We provide cloud based enterprise hosting, server and storage solutions of unmatched quality.</p>
                                    <a href="javascript:void(0)" class="read-more">Read More <i-feather name="chevron-right" class="fea icon-sm"></i-feather></a>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                        <div class="card features feature-primary explore-feature border-0 rounded text-center">
                            <div class="card-body">
                                <span class="icons rounded-circle shadow-lg d-inline-block h4">
                                    <i class="uil uil-webcam"></i>
                                </span>
                                <div class="content mt-3">
                                    <h5 class="mb-3"><a href="javascript:void(0)" class="title text-dark">Website Migrations</a></h5>
                                    <p class="text-muted mb-3">We provide cloud based enterprise hosting, server and storage solutions of unmatched quality.</p>
                                    <a href="javascript:void(0)" class="read-more">Read More <i-feather name="chevron-right" class="fea icon-sm"></i-feather></a>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                        <div class="card features feature-primary explore-feature border-0 rounded text-center">
                            <div class="card-body">
                                <span class="icons rounded-circle shadow-lg d-inline-block h4">
                                    <i class="uil uil-users-alt"></i>
                                </span>
                                <div class="content mt-3">
                                    <h5 class="mb-3"><a href="javascript:void(0)" class="title text-dark">Customer Support</a></h5>
                                    <p class="text-muted mb-3">We provide cloud based enterprise hosting, server and storage solutions of unmatched quality.</p>
                                    <a href="javascript:void(0)" class="read-more">Read More <i-feather name="chevron-right" class="fea icon-sm"></i-feather></a>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- End -->

        <!-- Price Start -->
        <section class="section bg-primary">
            <div class="container">
                <div class="row mb-md-4 pb-md-2 justify-content-center">
                    <div class="col-12">
                        <div class="section-title text-center mb-md-5">
                            <h4 class="title text-white title-dark mb-3">Hosting / Server Rates</h4>
                            <p class="text-white-50 para-desc mx-auto mb-0">Create, collaborate, and turn your ideas into incredible products with the definitive platform for digital design.</p>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
        </section><!--end section-->

        <!-- Start -->
        <section class="section">
            <div class="features-absolute">
                <div class="container">
                    <div class="row mt-5 mt-sm-0">
                        <div class="col-lg-3 col-md-6 col-12">
                            <div class="card pricing pricing-primary business-rate shadow bg-light border-0 rounded">
                                <div class="card-body">
                                    <h6 class="title name fw-bold text-uppercase mb-4">Free</h6>
                                    <div class="d-flex mb-4">
                                        <span class="h4 mb-0 mt-2">$</span>
                                        <span class="price h1 mb-0">0</span>
                                        <span class="h4 align-self-end mb-1">/mo</span>
                                    </div>
                                    
                                    <ul class="list-unstyled mb-0 ps-0">
                                        <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                                        <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                                    </ul>
                                    <a href="javascript:void(0)" class="btn btn-primary mt-4">Buy Now</a>
                                </div>
                            </div>
                        </div><!--end col-->
                        
                        <div class="col-lg-3 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div class="card pricing pricing-primary business-rate shadow border-0 rounded">
                                <div class="ribbon ribbon-right ribbon-warning overflow-hidden"><span class="text-center d-block shadow small h6">Best</span></div>
                                <div class="card-body">
                                    <h6 class="title name fw-bold text-uppercase mb-4">Starter</h6>
                                    <div class="d-flex mb-4">
                                        <span class="h4 mb-0 mt-2">$</span>
                                        <span class="price h1 mb-0">39</span>
                                        <span class="h4 align-self-end mb-1">/mo</span>
                                    </div>
    
                                    <ul class="list-unstyled mb-0 ps-0">
                                        <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                                        <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                                        <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Free Appointments</li>
                                    </ul>
                                    <a href="javascript:void(0)" class="btn btn-primary mt-4">Get Started</a>
                                </div>
                            </div>
                        </div><!--end col-->
                        
                        <div class="col-lg-3 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                            <div class="card pricing pricing-primary business-rate shadow bg-light border-0 rounded">
                                <div class="card-body">
                                    <h6 class="title name fw-bold text-uppercase mb-4">Business</h6>
                                    <div class="d-flex mb-4">
                                        <span class="h4 mb-0 mt-2">$</span>
                                        <span class="price h1 mb-0">59</span>
                                        <span class="h4 align-self-end mb-1">/mo</span>
                                    </div>
                                    
                                    <ul class="list-unstyled mb-0 ps-0">
                                        <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                                        <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                                        <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>1 Domain Free</li>
                                        <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Enhanced Security</li>
                                    </ul>
                                    <a href="javascript:void(0)" class="btn btn-primary mt-4">Try It Now</a>
                                </div>
                            </div>
                        </div><!--end col-->
                        
                        <div class="col-lg-3 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                            <div class="card pricing pricing-primary business-rate shadow bg-light border-0 rounded">
                                <div class="card-body">
                                    <h6 class="title name fw-bold text-uppercase mb-4">Enterprise</h6>
                                    <div class="d-flex mb-4">
                                        <span class="h4 mb-0 mt-2">$</span>
                                        <span class="price h1 mb-0">79</span>
                                        <span class="h4 align-self-end mb-1">/mo</span>
                                    </div>
                                    
                                    <ul class="list-unstyled mb-0 ps-0">
                                        <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                                        <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Enhanced Security</li>
                                        <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                                        <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>1 Domain Free</li>
                                        <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Free Installment</li>
                                    </ul>
                                    <a href="javascript:void(0)" class="btn btn-primary mt-4">Started Now</a>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->

            <div class="container mt-100 mt-60">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <div class="section-title text-center mb-4 pb-2">
                            <h4 class="title mb-3">Frequently Aksed Questions</h4>
                            <p class="text-muted para-desc mx-auto mb-0">Create, collaborate, and turn your ideas into incredible products with the definitive platform for digital design.</p>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->

                <div class="row align-items-center mb-md-5 mb-4 pb-lg-5">
                    <div class="col-lg-6 col-md-6 col-12 mt-4 pt-2 order-1 order-md-2">
                        <div class="ms-lg-5">
                            <img src="assets/images/hosting/faqs.svg" class="img-fluid" alt="">
                        </div>
                    </div>

                    <div class="col-md-6 col-12 mt-4 pt-2 order-2 order-md-1">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item rounded shadow">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button border-0 bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                        aria-expanded="true" aria-controls="collapseOne">
                                        How our Landrick work ?
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse border-0 collapse show" aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item rounded shadow mt-2">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        What is the main process open account ?
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse border-0 collapse" aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item rounded shadow mt-2">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        How to make unlimited data entry ?
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse border-0 collapse" aria-labelledby="headingThree"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item rounded shadow mt-2">
                                <h2 class="accordion-header" id="headingFour">
                                    <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        Is Landrick safer to use with my account ?
                                    </button>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse border-0 collapse" aria-labelledby="headingFour"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item rounded shadow mt-2">
                                <h2 class="accordion-header" id="headingFive">
                                    <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        How can i contact your technical team ?
                                    </button>
                                </h2>
                                <div id="collapseFive" class="accordion-collapse border-0 collapse" aria-labelledby="headingFive"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!--end row-->
            </div><!--end container-->
        </section><!--end section-->

        <!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
