<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> Progressbar </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Components</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Progressbar</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 d-md-block d-none">
                <app-component-sidebar></app-component-sidebar>
            </div>

            <div class="col-lg-9 col-md-8 col-12">
                <div class="row">
                    <!-- Progressbar Start -->
                    <div class="col-12">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> Progressbar </h5>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted mb-4 pb-2">Ex. <br> 
                                    <pre><code class="text-danger">
&lt;div class="progress-box"&gt;
&lt;h6 class="title text-muted"&gt;WordPress&lt;/h6&gt;
&lt;div class="progress"&gt;
&lt;div class="progress-bar position-relative bg-primary" style="width:84%;"&gt;
    &lt;div class="progress-value d-block text-muted h6"&gt;84%&lt;/div&gt;
&lt;/div&gt;
&lt;/div&gt;
&lt;/div&gt;&lt;!--end process box--&gt;
&lt;div class="progress-box mt-4"&gt;
&lt;h6 class="title text-muted"&gt;PHP / MYSQL&lt;/h6&gt;
&lt;div class="progress"&gt;
&lt;div class="progress-bar position-relative bg-primary" style="width:75%;"&gt;
    &lt;div class="progress-value d-block text-muted h6"&gt;75%&lt;/div&gt;
&lt;/div&gt;
&lt;/div&gt;
&lt;/div&gt;&lt;!--end process box--&gt;
&lt;div class="progress-box mt-4"&gt;
&lt;h6 class="title text-muted"&gt;Angular / JavaScript&lt;/h6&gt;
&lt;div class="progress"&gt;
&lt;div class="progress-bar position-relative bg-primary" style="width:79%;"&gt;
    &lt;div class="progress-value d-block text-muted h6"&gt;79%&lt;/div&gt;
&lt;/div&gt;
&lt;/div&gt;
&lt;/div&gt;&lt;!--end process box--&gt;
&lt;div class="progress-box mt-4"&gt;
&lt;h6 class="title text-muted"&gt;HTML&lt;/h6&gt;
&lt;div class="progress"&gt;
&lt;div class="progress-bar position-relative bg-primary" style="width:95%;"&gt;
    &lt;div class="progress-value d-block text-muted h6"&gt;95%&lt;/div&gt;
&lt;/div&gt;
&lt;/div&gt;
&lt;/div&gt;&lt;!--end process box--&gt;
</code></pre>
                                </h6>
                                
                                <div class="progress-box">
                                    <h6 class="title text-muted">WordPress</h6>
                                    <div class="progress">
                                        <div class="progress-bar position-relative bg-primary" style="width:84%;">
                                            <div class="progress-value d-block text-muted h6">84%</div>
                                        </div>
                                    </div>
                                </div><!--end process box-->
                                <div class="progress-box mt-4">
                                    <h6 class="title text-muted">PHP / MYSQL</h6>
                                    <div class="progress">
                                        <div class="progress-bar position-relative bg-primary" style="width:75%;">
                                            <div class="progress-value d-block text-muted h6">75%</div>
                                        </div>
                                    </div>
                                </div><!--end process box-->
                                <div class="progress-box mt-4">
                                    <h6 class="title text-muted">Angular / JavaScript</h6>
                                    <div class="progress">
                                        <div class="progress-bar position-relative bg-primary" style="width:79%;">
                                            <div class="progress-value d-block text-muted h6">79%</div>
                                        </div>
                                    </div>
                                </div><!--end process box-->
                                <div class="progress-box mt-4">
                                    <h6 class="title text-muted">HTML</h6>
                                    <div class="progress">
                                        <div class="progress-bar position-relative bg-primary" style="width:95%;">
                                            <div class="progress-value d-block text-muted h6">95%</div>
                                        </div>
                                    </div>
                                </div><!--end process box-->
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Progressbar End -->
                </div><!--end row-->
            </div><!--end col-->
        </div>
    </div>
</section>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->

