<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" style="background-image: url('assets/images/hosting/pages.png');" id="home">
    <div class="bg-overlay bg-gradient-primary opacity-9"></div>
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading title-heading">
                    <h4 class="title text-white title-dark mb-4"> Company Story </h4>
                    <p class="text-white-50 para-desc mx-auto mb-0">Launch your campaign and benefit from our expertise
                        on designing and managing conversion centered bootstrap v5 html page.</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a routerLink="/index-hosting">Hosting</a></li>
                    <li class="breadcrumb-item active" aria-current="page">About us</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Hero End -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-12">
                <div class="me-lg-4">
                    <img src="assets/images/hosting/2.png" class="img-fluid" alt="">
                </div>
            </div><!--end col-->

            <div class="col-lg-6 col-md-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="section-title">
                    <h6 class="text-primary">Company Story</h6>
                    <h4 class="title mb-4">Landrick Hosting</h4>
                    <p class="text-muted para-desc">The most well-known dummy text is the 'Lorem Ipsum', which is said
                        to have originated in the 16th century.</p>
                    <p class="text-muted para-desc mb-0">Lorem Ipsum is composed in a pseudo-Latin language which more
                        or less corresponds to 'proper' Latin. It contains a series of real Latin words. This ancient
                        dummy text is also incomprehensible, but it imitates the rhythm of most European languages in
                        Latin script. The advantage of its Latin origin and the relative meaninglessness of Lorum Ipsum
                        is that the text does not attract attention to itself or distract the viewer's attention from
                        the layout.</p>

                    <div class="mt-4">
                        <a href="javascript:void(0)" class="btn btn-soft-primary">Get Started <i
                                class="mdi mdi-arrow-right"></i></a>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h4 class="title mb-4">Why choose Hosting ?</h4>
                    <p class="text-muted mx-auto para-desc mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row">
            <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
                <div
                    class="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
                    <span class="h1 icon-color">
                        <i class="uil uil-server-alt"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>SSD storage</h5>
                        <p class="para text-muted mb-0">If the distribution of letters and 'words' is random, the reader
                            from making a neutral judgement</p>
                    </div>
                    <div class="position-absolute top-0 end-0">
                        <i class="uil uil-server-alt display-1 opacity-05"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
                <div
                    class="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
                    <span class="h1 icon-color">
                        <i class="uil uil-data-sharing"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Easy upgrade</h5>
                        <p class="para text-muted mb-0">If the distribution of letters and 'words' is random, the reader
                            from making a neutral judgement</p>
                    </div>
                    <div class="position-absolute top-0 end-0">
                        <i class="uil uil-data-sharing display-1 opacity-05"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
                <div
                    class="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
                    <span class="h1 icon-color">
                        <i class="uil uil-cloud-database-tree"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Multiple Linux Distros</h5>
                        <p class="para text-muted mb-0">If the distribution of letters and 'words' is random, the reader
                            from making a neutral judgement</p>
                    </div>
                    <div class="position-absolute top-0 end-0">
                        <i class="uil uil-cloud-database-tree display-1 opacity-05"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
                <div
                    class="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
                    <span class="h1 icon-color">
                        <i class="uil uil-user-check"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Admin Support</h5>
                        <p class="para text-muted mb-0">If the distribution of letters and 'words' is random, the reader
                            from making a neutral judgement</p>
                    </div>
                    <div class="position-absolute top-0 end-0">
                        <i class="uil uil-user-check display-1 opacity-05"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
                <div
                    class="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
                    <span class="h1 icon-color">
                        <i class="uil uil-create-dashboard"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Guaranteed Resources</h5>
                        <p class="para text-muted mb-0">If the distribution of letters and 'words' is random, the reader
                            from making a neutral judgement</p>
                    </div>
                    <div class="position-absolute top-0 end-0">
                        <i class="uil uil-create-dashboard display-1 opacity-05"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
                <div
                    class="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
                    <span class="h1 icon-color">
                        <i class="uil uil-desktop"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Full Root Access</h5>
                        <p class="para text-muted mb-0">If the distribution of letters and 'words' is random, the reader
                            from making a neutral judgement</p>
                    </div>
                    <div class="position-absolute top-0 end-0">
                        <i class="uil uil-desktop display-1 opacity-05"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
                <div
                    class="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
                    <span class="h1 icon-color">
                        <i class="uil uil-shield-check"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Quick Provisioning</h5>
                        <p class="para text-muted mb-0">If the distribution of letters and 'words' is random, the reader
                            from making a neutral judgement</p>
                    </div>
                    <div class="position-absolute top-0 end-0">
                        <i class="uil uil-shield-check display-1 opacity-05"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
                <div
                    class="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
                    <span class="h1 icon-color">
                        <i class="uil uil-monitor-heart-rate"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Intuitive Dashboard</h5>
                        <p class="para text-muted mb-0">If the distribution of letters and 'words' is random, the reader
                            from making a neutral judgement</p>
                    </div>
                    <div class="position-absolute top-0 end-0">
                        <i class="uil uil-monitor-heart-rate display-1 opacity-05"></i>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->

    <div class="container-fluid mt-100 mt-60">
        <div class="rounded-md bg-primary py-5 px-4">
            <div class="row py-md-5 py-4">
                <div class="container">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="section-title">
                                <h6 class="text-white title-dark mb-3">Powered by SSD</h6>
                                <h2 class="text-white title-dark mb-0">100% Faster Solid State Drive Server</h2>
                            </div>
                        </div><!--end col-->

                        <div class="col-lg-6 col-md-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <ul class="list-unstyled mb-0 ms-lg-5">
                                <li class="text-white-50 my-2"><i-feather name="arrow-right-circle"
                                        class="fea icon-ex-md me-1"></i-feather> Ultrafast Data Read/Write Speeds</li>
                                <li class="text-white-50 my-2"><i-feather name="arrow-right-circle"
                                        class="fea icon-ex-md me-1"></i-feather> Enterprise Grade Hardware</li>
                                <li class="text-white-50 my-2"><i-feather name="arrow-right-circle"
                                        class="fea icon-ex-md me-1"></i-feather> Highest Data Protection Reliability</li>
                            </ul>
                        </div><!--end col-->
                    </div><!--end row-->
                </div><!--end container-->
            </div><!--end row-->
        </div><!--end div-->
    </div><!--end container-fluid-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h4 class="title mb-4">Team Members</h4>
                    <p class="text-muted mx-auto para-desc mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row">
            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div class="card team team-primary text-center border-0">
                    <div class="position-relative">
                        <img src="assets/images/client/01.jpg"
                            class="img-fluid avatar avatar-ex-large rounded-circle shadow" alt="">
                        <ul class="list-unstyled mb-0 team-icon">
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                                    class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="facebook"
                                        class="icons"></i-feather></a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                                    class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="instagram"
                                        class="icons"></i-feather></a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                                    class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="twitter"
                                        class="icons"></i-feather></a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                                    class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="linkedin"
                                        class="icons"></i-feather></a></li>
                        </ul><!--end icon-->
                    </div>
                    <div class="card-body py-3 px-0 content">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Ronny Jofra</a></h5>
                        <small class="designation text-muted">C.E.O</small>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div class="card team team-primary text-center border-0">
                    <div class="position-relative">
                        <img src="assets/images/client/04.jpg"
                            class="img-fluid avatar avatar-ex-large rounded-circle shadow" alt="">
                        <ul class="list-unstyled mb-0 team-icon">
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                                    class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="facebook"
                                        class="icons"></i-feather></a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                                    class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="instagram"
                                        class="icons"></i-feather></a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                                    class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="twitter"
                                        class="icons"></i-feather></a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                                    class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="linkedin"
                                        class="icons"></i-feather></a></li>
                        </ul><!--end icon-->
                    </div>
                    <div class="card-body py-3 px-0 content">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Micheal Carlo</a></h5>
                        <small class="designation text-muted">Director</small>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div class="card team team-primary text-center border-0">
                    <div class="position-relative">
                        <img src="assets/images/client/02.jpg"
                            class="img-fluid avatar avatar-ex-large rounded-circle shadow" alt="">
                        <ul class="list-unstyled mb-0 team-icon">
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                                    class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="facebook"
                                        class="icons"></i-feather></a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                                    class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="instagram"
                                        class="icons"></i-feather></a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                                    class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="twitter"
                                        class="icons"></i-feather></a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                                    class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="linkedin"
                                        class="icons"></i-feather></a></li>
                        </ul><!--end icon-->
                    </div>
                    <div class="card-body py-3 px-0 content">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Aliana Rosy</a></h5>
                        <small class="designation text-muted">Manager</small>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div class="card team team-primary text-center border-0">
                    <div class="position-relative">
                        <img src="assets/images/client/03.jpg"
                            class="img-fluid avatar avatar-ex-large rounded-circle shadow" alt="">
                        <ul class="list-unstyled mb-0 team-icon">
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                                    class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="facebook"
                                        class="icons"></i-feather></a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                                    class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="instagram"
                                        class="icons"></i-feather></a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                                    class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="twitter"
                                        class="icons"></i-feather></a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)"
                                    class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="linkedin"
                                        class="icons"></i-feather></a></li>
                        </ul><!--end icon-->
                    </div>
                    <div class="card-body py-3 px-0 content">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Sofia Razaq</a></h5>
                        <small class="designation text-muted">Developer</small>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-center mb-4 pb-2">
            <div class="col-md-6">
                <div class="section-title">
                    <h4 class="title mb-md-0 mb-4">Frequently asked <br> questions</h4>
                </div>
            </div>

            <div class="col-md-6">
                <div class="section-title">
                    <p class="text-muted para-desc mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-help-circle fea icon-ex-md text-primary me-2 mt-1">
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                    </svg>
                    <div class="flex-1">
                        <h5 class="mt-0">How our <span class="text-primary">Landrick</span> work ?</h5>
                        <p class="answer text-muted mb-0">Due to its widespread use as filler text for layouts,
                            non-readability is of great importance: human perception is tuned to recognize certain
                            patterns and repetitions in texts.</p>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-help-circle fea icon-ex-md text-primary me-2 mt-1">
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                    </svg>
                    <div class="flex-1">
                        <h5 class="mt-0"> What is the main process open account ?</h5>
                        <p class="answer text-muted mb-0">If the distribution of letters and 'words' is random, the
                            reader will not be distracted from making a neutral judgement on the visual impact</p>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-help-circle fea icon-ex-md text-primary me-2 mt-1">
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                    </svg>
                    <div class="flex-1">
                        <h5 class="mt-0"> How to make unlimited data entry ?</h5>
                        <p class="answer text-muted mb-0">Furthermore, it is advantageous when the dummy text is
                            relatively realistic so that the layout impression of the final publication is not
                            compromised.</p>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-help-circle fea icon-ex-md text-primary me-2 mt-1">
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                    </svg>
                    <div class="flex-1">
                        <h5 class="mt-0"> Is <span class="text-primary">Landrick</span> safer to use with my account ?
                        </h5>
                        <p class="answer text-muted mb-0">The most well-known dummy text is the 'Lorem Ipsum', which is
                            said to have originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin
                            language which more or less corresponds to 'proper' Latin.</p>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row mt-5 pt-4 justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title">
                    <h4 class="title mb-3">Have Question ? Get in touch!</h4>
                    <p class="text-muted para-desc mx-auto">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                    <a href="javascript:void(0)" class="btn btn-primary mt-4"><i class="mdi mdi-phone"></i> Contact
                        us</a>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->

<section class="py-md-4 py-5 bg-primary">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-6 col-md-6 col-12 text-center text-md-start">
                <h4 class="text-white title-dark mb-0">Start Building Your Website Today!</h4>
            </div><!--end col-->
            <div class="col-lg-3 col-md-3 col-12 mt-4 mt-sm-0 text-center">
                <div class="d-flex justify-content-center">
                    <span class="h6 text-white-50 mb-0 mt-2">$</span>
                    <span class="price text-white h1 mb-0">9.99</span>
                    <span class="h6 text-white-50 align-self-end mb-1">/month</span>
                </div>
            </div><!--end col-->
            <div class="col-lg-3 col-md-3 col-12 mt-4 mt-sm-0 text-center text-md-end">
                <a href="javascript:void(0)" class="btn btn-warning">Get Started</a>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->