        <!-- Start -->
        <section class="bg-half-260 bg-primary d-table w-100" style="background-image: url('assets/images/hosting/shared.png');" id="home">
            <div class="container">
                <div class="row mt-5 justify-content-center">
                    <div class="col-12">
                        <div class="pages-heading title-heading text-center">
                            <h4 class="title text-white title-dark mb-4">Shared Web Hosting</h4>
                            <p class="text-white-50 para-desc mx-auto mb-0">Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap v5 html page.</p>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- End -->
        <!-- Shape Start -->
        <div class="position-relative">
            <div class="shape overflow-hidden text-color-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <!--Shape End-->

        <!-- Start -->
        <section class="section">
            <div class="features-absolute">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-12">
                            <div class="card pricing pricing-primary business-rate shadow bg-light border-0 rounded">
                                <div class="card-body">
                                    <h6 class="title name fw-bold text-uppercase mb-4">Free</h6>
                                    <div class="d-flex mb-4">
                                        <span class="h4 mb-0 mt-2">$</span>
                                        <span class="price h1 mb-0">0</span>
                                        <span class="h4 align-self-end mb-1">/mo</span>
                                    </div>
                                    
                                    <ul class="list-unstyled mb-0 ps-0">
                                        <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                                        <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                                    </ul>
                                    <a href="javascript:void(0)" class="btn btn-primary mt-4">Buy Now</a>
                                </div>
                            </div>
                        </div><!--end col-->
                        
                        <div class="col-lg-3 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div class="card pricing pricing-primary business-rate shadow border-0 rounded">
                                <div class="ribbon ribbon-right ribbon-warning overflow-hidden"><span class="text-center d-block shadow small h6">Best</span></div>
                                <div class="card-body">
                                    <h6 class="title name fw-bold text-uppercase mb-4">Starter</h6>
                                    <div class="d-flex mb-4">
                                        <span class="h4 mb-0 mt-2">$</span>
                                        <span class="price h1 mb-0">39</span>
                                        <span class="h4 align-self-end mb-1">/mo</span>
                                    </div>
    
                                    <ul class="list-unstyled mb-0 ps-0">
                                        <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                                        <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                                        <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Free Appointments</li>
                                    </ul>
                                    <a href="javascript:void(0)" class="btn btn-primary mt-4">Get Started</a>
                                </div>
                            </div>
                        </div><!--end col-->
                        
                        <div class="col-lg-3 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                            <div class="card pricing pricing-primary business-rate shadow bg-light border-0 rounded">
                                <div class="card-body">
                                    <h6 class="title name fw-bold text-uppercase mb-4">Business</h6>
                                    <div class="d-flex mb-4">
                                        <span class="h4 mb-0 mt-2">$</span>
                                        <span class="price h1 mb-0">59</span>
                                        <span class="h4 align-self-end mb-1">/mo</span>
                                    </div>
                                    
                                    <ul class="list-unstyled mb-0 ps-0">
                                        <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                                        <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                                        <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>1 Domain Free</li>
                                        <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Enhanced Security</li>
                                    </ul>
                                    <a href="javascript:void(0)" class="btn btn-primary mt-4">Try It Now</a>
                                </div>
                            </div>
                        </div><!--end col-->
                        
                        <div class="col-lg-3 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                            <div class="card pricing pricing-primary business-rate shadow bg-light border-0 rounded">
                                <div class="card-body">
                                    <h6 class="title name fw-bold text-uppercase mb-4">Enterprise</h6>
                                    <div class="d-flex mb-4">
                                        <span class="h4 mb-0 mt-2">$</span>
                                        <span class="price h1 mb-0">79</span>
                                        <span class="h4 align-self-end mb-1">/mo</span>
                                    </div>
                                    
                                    <ul class="list-unstyled mb-0 ps-0">
                                        <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                                        <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Enhanced Security</li>
                                        <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                                        <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>1 Domain Free</li>
                                        <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Free Installment</li>
                                    </ul>
                                    <a href="javascript:void(0)" class="btn btn-primary mt-4">Started Now</a>
                                </div>
                            </div>
                        </div><!--end col-->
                    </div><!--end row-->
                </div><!--end container-->
            </div>

            <div class="container mt-100 mt-60">
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <div class="section-title mb-4 pb-2">
                            <h4 class="title mb-4">Why Shared Hosting ?</h4>
                            <p class="text-muted para-desc mx-auto mb-0">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->

                <div class="row">
                    <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                        <div class="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
                            <span class="h1 icon-color">
                                <i class="uil uil-tachometer-fast-alt"></i>
                            </span>
                            <div class="card-body p-0 content">
                                <h5>Speedy</h5>
                                <p class="para text-muted mb-0">It is a long established fact that a reader will be of at its layout.</p>
                            </div>

                            <div class="position-absolute top-0 end-0">
                                <i class="uil uil-tachometer-fast-alt display-1 opacity-05"></i>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                        <div class="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
                            <span class="h1 icon-color">
                                <i class="uil uil-compact-disc"></i>
                            </span>
                            <div class="card-body p-0 content">
                                <h5>Reliable</h5>
                                <p class="para text-muted mb-0">It is a long established fact that a reader will be of at its layout.</p>
                            </div>

                            <div class="position-absolute top-0 end-0">
                                <i class="uil uil-compact-disc display-1 opacity-05"></i>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                        <div class="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
                            <span class="h1 icon-color">
                                <i class="uil uil-weight"></i>
                            </span>
                            <div class="card-body p-0 content">
                                <h5>Scalable</h5>
                                <p class="para text-muted mb-0">It is a long established fact that a reader will be of at its layout.</p>
                            </div>

                            <div class="position-absolute top-0 end-0">
                                <i class="uil uil-weight display-1 opacity-05"></i>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                        <div class="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
                            <span class="h1 icon-color">
                                <i class="uil uil-envelope-question"></i>
                            </span>
                            <div class="card-body p-0 content">
                                <h5>Supported</h5>
                                <p class="para text-muted mb-0">It is a long established fact that a reader will be of at its layout.</p>
                            </div>

                            <div class="position-absolute top-0 end-0">
                                <i class="uil uil-envelope-question display-1 opacity-05"></i>
                            </div>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->

            <div class="container mt-100 mt-60">
                <div class="row">
                    <div class="col-md-6 col-12">
                        <div class="d-flex">
                            <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1" style="width: 20px;"></i-feather>
                            <div class="flex-1">
                                <h5 class="mt-0">How our <span class="text-primary">Landrick</span> work ?</h5>
                                <p class="answer text-muted mb-0">Due to its widespread use as filler text for layouts, non-readability is of great importance: human perception is tuned to recognize certain patterns and repetitions in texts.</p>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div class="d-flex">
                            <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1" style="width: 20px;"></i-feather>
                            <div class="flex-1">
                                <h5 class="mt-0"> What is the main process open account ?</h5>
                                <p class="answer text-muted mb-0">If the distribution of letters and 'words' is random, the reader will not be distracted from making a neutral judgement on the visual impact</p>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-md-6 col-12 mt-4 pt-2">
                        <div class="d-flex">
                            <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1" style="width: 20px;"></i-feather>
                            <div class="flex-1">
                                <h5 class="mt-0"> How to make unlimited data entry ?</h5>
                                <p class="answer text-muted mb-0">Furthermore, it is advantageous when the dummy text is relatively realistic so that the layout impression of the final publication is not compromised.</p>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-md-6 col-12 mt-4 pt-2">
                        <div class="d-flex">
                            <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1" style="width: 20px;"></i-feather>
                            <div class="flex-1">
                                <h5 class="mt-0"> Is <span class="text-primary">Landrick</span> safer to use with my account ?</h5>
                                <p class="answer text-muted mb-0">The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin language which more or less corresponds to 'proper' Latin.</p>
                            </div>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- End -->

        <!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
