<!-- Hero Start -->
<section class="bg-half-170 bg-primary d-table w-100" style="background-image: url('assets/images/job/bg.png');" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title text-white title-dark mb-0"> All Companies </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a routerLink="/index-job">Careers</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Companies</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Hero End -->


<section class="section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-9 col-md-8">
                <div class="section-title">
                    <h6 class="mb-0">Showing 1 – 8 of 10 results</h6>
                </div>
            </div><!--end col-->

            <div class="col-lg-3 col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="form custom-form">
                    <div class="mb-0">
                        <select class="form-select form-control" aria-label="Default select example"
                            id="Sortbylist-job">
                            <option selected>All Companies</option>
                            <option>Popular</option>
                            <option>Newest</option>
                        </select>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row">
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="job-box job-primary company-list card border-0 rounded shadow">
                    <div class="text-center py-5 border-bottom">
                        <img src="assets/images/job/Circleci.svg"
                            class="avatar avatar-small mx-auto rounded-circle d-block mb-3" alt="">
                        <a routerLink="/job-company" class="text-dark h5 name">CircleCi</a>
                        <p class="text-muted mt-1 mb-0">Internet Services</p>
                    </div>

                    <div class="p-4">
                        <h5>Company Details :</h5>
                        <ul class="list-unstyled mb-4">
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-map-pin fea icon-sm text-warning me-2">
                                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                    <circle cx="12" cy="10" r="3"></circle>
                                </svg><span class="text-muted">Location :</span> San Francisco</li>
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-link fea icon-sm text-warning me-2">
                                    <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
                                    <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
                                </svg><span class="text-muted">Comapny :</span> circleci.com</li>
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-dollar-sign fea icon-sm text-warning me-2">
                                    <line x1="12" y1="1" x2="12" y2="23"></line>
                                    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                                </svg><span class="text-muted">Revenue :</span> $ 5M / Annual</li>
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-users fea icon-sm text-warning me-2">
                                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="9" cy="7" r="4"></circle>
                                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                </svg><span class="text-muted">No. of employees :</span> 200</li>
                        </ul>
                        <div class="d-grid">
                            <a routerLink="/job-company" class="btn btn-soft-primary">View Detail <i
                                    class="uil uil-angle-right-b align-middle"></i></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="job-box job-primary company-list card border-0 rounded shadow">
                    <div class="text-center py-5 border-bottom">
                        <img src="assets/images/job/Mg.svg"
                            class="avatar avatar-small mx-auto rounded-circle d-block mb-3" alt="">
                        <a routerLink="/job-company" class="text-dark h5 name">Mg</a>
                        <p class="text-muted mt-1 mb-0">Internet Services</p>
                    </div>

                    <div class="p-4">
                        <h5>Company Details :</h5>
                        <ul class="list-unstyled mb-4">
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-map-pin fea icon-sm text-warning me-2">
                                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                    <circle cx="12" cy="10" r="3"></circle>
                                </svg><span class="text-muted">Location :</span> San Francisco</li>
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-link fea icon-sm text-warning me-2">
                                    <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
                                    <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
                                </svg><span class="text-muted">Comapny :</span> circleci.com</li>
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-dollar-sign fea icon-sm text-warning me-2">
                                    <line x1="12" y1="1" x2="12" y2="23"></line>
                                    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                                </svg><span class="text-muted">Revenue :</span> $ 5M / Annual</li>
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-users fea icon-sm text-warning me-2">
                                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="9" cy="7" r="4"></circle>
                                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                </svg><span class="text-muted">No. of employees :</span> 200</li>
                        </ul>
                        <div class="d-grid">
                            <a routerLink="/job-company" class="btn btn-soft-primary">View Detail <i
                                    class="uil uil-angle-right-b align-middle"></i></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="job-box job-primary company-list card border-0 rounded shadow">
                    <div class="text-center py-5 border-bottom">
                        <img src="assets/images/job/Twilio.svg"
                            class="avatar avatar-small mx-auto rounded-circle d-block mb-3" alt="">
                        <a routerLink="/job-company" class="text-dark h5 name">Twilio</a>
                        <p class="text-muted mt-1 mb-0">Internet Services</p>
                    </div>

                    <div class="p-4">
                        <h5>Company Details :</h5>
                        <ul class="list-unstyled mb-4">
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-map-pin fea icon-sm text-warning me-2">
                                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                    <circle cx="12" cy="10" r="3"></circle>
                                </svg><span class="text-muted">Location :</span> San Francisco</li>
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-link fea icon-sm text-warning me-2">
                                    <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
                                    <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
                                </svg><span class="text-muted">Comapny :</span> circleci.com</li>
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-dollar-sign fea icon-sm text-warning me-2">
                                    <line x1="12" y1="1" x2="12" y2="23"></line>
                                    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                                </svg><span class="text-muted">Revenue :</span> $ 5M / Annual</li>
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-users fea icon-sm text-warning me-2">
                                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="9" cy="7" r="4"></circle>
                                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                </svg><span class="text-muted">No. of employees :</span> 200</li>
                        </ul>
                        <div class="d-grid">
                            <a routerLink="/job-company" class="btn btn-soft-primary">View Detail <i
                                    class="uil uil-angle-right-b align-middle"></i></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="job-box job-primary company-list card border-0 rounded shadow">
                    <div class="text-center py-5 border-bottom">
                        <img src="assets/images/job/Udemy.svg"
                            class="avatar avatar-small mx-auto rounded-circle d-block mb-3" alt="">
                        <a routerLink="/job-company" class="text-dark h5 name">Udemy</a>
                        <p class="text-muted mt-1 mb-0">Internet Services</p>
                    </div>

                    <div class="p-4">
                        <h5>Company Details :</h5>
                        <ul class="list-unstyled mb-4">
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-map-pin fea icon-sm text-warning me-2">
                                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                    <circle cx="12" cy="10" r="3"></circle>
                                </svg><span class="text-muted">Location :</span> San Francisco</li>
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-link fea icon-sm text-warning me-2">
                                    <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
                                    <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
                                </svg><span class="text-muted">Comapny :</span> circleci.com</li>
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-dollar-sign fea icon-sm text-warning me-2">
                                    <line x1="12" y1="1" x2="12" y2="23"></line>
                                    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                                </svg><span class="text-muted">Revenue :</span> $ 5M / Annual</li>
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-users fea icon-sm text-warning me-2">
                                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="9" cy="7" r="4"></circle>
                                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                </svg><span class="text-muted">No. of employees :</span> 200</li>
                        </ul>
                        <div class="d-grid">
                            <a routerLink="/job-company" class="btn btn-soft-primary">View Detail <i
                                    class="uil uil-angle-right-b align-middle"></i></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="job-box job-primary company-list card border-0 rounded shadow">
                    <div class="text-center py-5 border-bottom">
                        <img src="assets/images/job/Webhooks.svg"
                            class="avatar avatar-small mx-auto rounded-circle d-block mb-3" alt="">
                        <a routerLink="/job-company" class="text-dark h5 name">Webhooks</a>
                        <p class="text-muted mt-1 mb-0">Internet Services</p>
                    </div>

                    <div class="p-4">
                        <h5>Company Details :</h5>
                        <ul class="list-unstyled mb-4">
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-map-pin fea icon-sm text-warning me-2">
                                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                    <circle cx="12" cy="10" r="3"></circle>
                                </svg><span class="text-muted">Location :</span> San Francisco</li>
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-link fea icon-sm text-warning me-2">
                                    <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
                                    <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
                                </svg><span class="text-muted">Comapny :</span> circleci.com</li>
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-dollar-sign fea icon-sm text-warning me-2">
                                    <line x1="12" y1="1" x2="12" y2="23"></line>
                                    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                                </svg><span class="text-muted">Revenue :</span> $ 5M / Annual</li>
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-users fea icon-sm text-warning me-2">
                                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="9" cy="7" r="4"></circle>
                                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                </svg><span class="text-muted">No. of employees :</span> 200</li>
                        </ul>
                        <div class="d-grid">
                            <a routerLink="/job-company" class="btn btn-soft-primary">View Detail <i
                                    class="uil uil-angle-right-b align-middle"></i></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="job-box job-primary company-list card border-0 rounded shadow">
                    <div class="text-center py-5 border-bottom">
                        <img src="assets/images/job/Discord.svg"
                            class="avatar avatar-small mx-auto rounded-circle d-block mb-3" alt="">
                        <a routerLink="/job-company" class="text-dark h5 name">Discord</a>
                        <p class="text-muted mt-1 mb-0">Internet Services</p>
                    </div>

                    <div class="p-4">
                        <h5>Company Details :</h5>
                        <ul class="list-unstyled mb-4">
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-map-pin fea icon-sm text-warning me-2">
                                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                    <circle cx="12" cy="10" r="3"></circle>
                                </svg><span class="text-muted">Location :</span> San Francisco</li>
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-link fea icon-sm text-warning me-2">
                                    <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
                                    <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
                                </svg><span class="text-muted">Comapny :</span> circleci.com</li>
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-dollar-sign fea icon-sm text-warning me-2">
                                    <line x1="12" y1="1" x2="12" y2="23"></line>
                                    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                                </svg><span class="text-muted">Revenue :</span> $ 5M / Annual</li>
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-users fea icon-sm text-warning me-2">
                                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="9" cy="7" r="4"></circle>
                                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                </svg><span class="text-muted">No. of employees :</span> 200</li>
                        </ul>
                        <div class="d-grid">
                            <a routerLink="/job-company" class="btn btn-soft-primary">View Detail <i
                                    class="uil uil-angle-right-b align-middle"></i></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="job-box job-primary company-list card border-0 rounded shadow">
                    <div class="text-center py-5 border-bottom">
                        <img src="assets/images/job/Eslint.svg"
                            class="avatar avatar-small mx-auto rounded-circle d-block mb-3" alt="">
                        <a routerLink="/job-company" class="text-dark h5 name">Eslint</a>
                        <p class="text-muted mt-1 mb-0">Internet Services</p>
                    </div>

                    <div class="p-4">
                        <h5>Company Details :</h5>
                        <ul class="list-unstyled mb-4">
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-map-pin fea icon-sm text-warning me-2">
                                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                    <circle cx="12" cy="10" r="3"></circle>
                                </svg><span class="text-muted">Location :</span> San Francisco</li>
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-link fea icon-sm text-warning me-2">
                                    <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
                                    <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
                                </svg><span class="text-muted">Comapny :</span> circleci.com</li>
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-dollar-sign fea icon-sm text-warning me-2">
                                    <line x1="12" y1="1" x2="12" y2="23"></line>
                                    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                                </svg><span class="text-muted">Revenue :</span> $ 5M / Annual</li>
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-users fea icon-sm text-warning me-2">
                                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="9" cy="7" r="4"></circle>
                                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                </svg><span class="text-muted">No. of employees :</span> 200</li>
                        </ul>
                        <div class="d-grid">
                            <a routerLink="/job-company" class="btn btn-soft-primary">View Detail <i
                                    class="uil uil-angle-right-b align-middle"></i></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="job-box job-primary company-list card border-0 rounded shadow">
                    <div class="text-center py-5 border-bottom">
                        <img src="assets/images/job/Gitlab.svg"
                            class="avatar avatar-small mx-auto rounded-circle d-block mb-3" alt="">
                        <a routerLink="/job-company" class="text-dark h5 name">Gitlab</a>
                        <p class="text-muted mt-1 mb-0">Internet Services</p>
                    </div>

                    <div class="p-4">
                        <h5>Company Details :</h5>
                        <ul class="list-unstyled mb-4">
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-map-pin fea icon-sm text-warning me-2">
                                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                    <circle cx="12" cy="10" r="3"></circle>
                                </svg><span class="text-muted">Location :</span> San Francisco</li>
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-link fea icon-sm text-warning me-2">
                                    <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
                                    <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
                                </svg><span class="text-muted">Comapny :</span> circleci.com</li>
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-dollar-sign fea icon-sm text-warning me-2">
                                    <line x1="12" y1="1" x2="12" y2="23"></line>
                                    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                                </svg><span class="text-muted">Revenue :</span> $ 5M / Annual</li>
                            <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-users fea icon-sm text-warning me-2">
                                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="9" cy="7" r="4"></circle>
                                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                </svg><span class="text-muted">No. of employees :</span> 200</li>
                        </ul>
                        <div class="d-grid">
                            <a routerLink="/job-company" class="btn btn-soft-primary">View Detail <i
                                    class="uil uil-angle-right-b align-middle"></i></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <!-- PAGINATION START -->
            <div class="col-12 mt-4 pt-2">
                <ul class="pagination justify-content-center mb-0">
                    <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Previous">Prev</a>
                    </li>
                    <li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Next">Next</a></li>
                </ul>
            </div><!--end col-->
            <!-- PAGINATION END -->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->