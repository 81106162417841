import { Component } from '@angular/core';

@Component({
  selector: 'app-nft-explore',
  templateUrl: './nft-explore.component.html',
  styleUrls: ['./nft-explore.component.css']
})
export class NftExploreComponent {
  // Set Topbar Option
  Menuoption = 'nft';
  Nfticons = true
  footerVariant = 'footer-three';
}
