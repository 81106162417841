<!-- Start -->
<section class="bg-half-170 d-table w-100" id="home">
    <div class="container">
        <div class="row justify-content-center mt-5">
            <div class="col-lg-10">
                <div class="section-title text-center">
                    <img src="assets/images/client/03.jpg" class="img-fluid avatar avatar-large rounded-pill shadow-md"
                        alt="">

                    <h4 class="title mt-4 mb-0">Cristina Jota</h4>
                    <small class="fw-bold text-primary">PHOTOGRAPHER & BLOGGER</small>
                </div>
            </div><!--end col-->

            <div class="col-lg-10 mt-4 pt-2">
                <p class="text-muted">Appropriately empower dynamic leadership skills after business portals. Globally
                    myocardinate interactive supply chains with distinctive quality vectors. Globally revolutionize
                    global sources through interoperable services.Quickly aggregate and worldwide potentialities.
                    spirits boom yardarm supply chains with distinctive quality vectors. Globally revolutionize global
                    sources through interoperable services.</p>
                <p class="text-muted">Dynamically reinvent market-driven opportunities, fingerstache single-origin
                    coffee Enthusiastically mesh long-term high-impact infrastructures vis-a-vis efficient customer
                    service. Professionally fashion wireless leadership rather than prospective experiences.
                    Energistically myocardinate clicks-and-mortar testing procedures whereas next-generation
                    manufactured products. knausgaard portland keytar pop-up. Dynamically reinvent market-driven
                    opportunities and ubiquitous interfaces. Energistically fabricate an expanded array of niche markets
                    through robust products.</p>
                <p class="text-muted">Compellingly embrace empowered e-business after user friendly intellectual
                    capital. Interactively actualize front-end processes with effective convergence. Synergistically
                    deliver performance based methods of empowerment whereas distributed expertise.</p>

                <ul class="list-unstyled text-center social-icon social mb-0 mt-4 pt-2">
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded-pill">
                        <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather></a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded-pill">
                        <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather></a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded-pill">
                        <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather></a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded-pill">
                        <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather></a></li>
                </ul><!--end icon-->
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->
<!-- End -->

    <!-- Insta Post Start -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 px-0">
                <swiper class="tiny-twelve-item" [config]="config">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="work-container work-primary work-modern position-relative d-block client-testi rounded-0 overflow-hidden">
                            <div class="card-img position-relative">
                                <img src="assets/images/blog/travel/01.jpg" class="img-fluid" alt="">
                                <div class="card-overlay"></div>
  
                                <div class="icons text-center">
                                    <a href="assets/images/blog/travel/01.jpg" class="work-icon bg-white d-inline-block rounded-pill lightbox"><i class="uil uil-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="swiper-slide">
                        <div class="work-container work-primary work-modern position-relative d-block client-testi rounded-0 overflow-hidden">
                            <div class="card-img position-relative">
                                <img src="assets/images/blog/travel/02.jpg" class="img-fluid" alt="">
                                <div class="card-overlay"></div>
  
                                <div class="icons text-center">
                                    <a href="assets/images/blog/travel/02.jpg" class="work-icon bg-white d-inline-block rounded-pill lightbox"><i class="uil uil-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="swiper-slide">
                        <div class="work-container work-primary work-modern position-relative d-block client-testi rounded-0 overflow-hidden">
                            <div class="card-img position-relative">
                                <img src="assets/images/blog/travel/03.jpg" class="img-fluid" alt="">
                                <div class="card-overlay"></div>
  
                                <div class="icons text-center">
                                    <a href="assets/images/blog/travel/03.jpg" class="work-icon bg-white d-inline-block rounded-pill lightbox"><i class="uil uil-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="swiper-slide">
                        <div class="work-container work-primary work-modern position-relative d-block client-testi rounded-0 overflow-hidden">
                            <div class="card-img position-relative">
                                <img src="assets/images/blog/travel/04.jpg" class="img-fluid" alt="">
                                <div class="card-overlay"></div>
  
                                <div class="icons text-center">
                                    <a href="assets/images/blog/travel/04.jpg" class="work-icon bg-white d-inline-block rounded-pill lightbox"><i class="uil uil-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="swiper-slide">
                        <div class="work-container work-primary work-modern position-relative d-block client-testi rounded-0 overflow-hidden">
                            <div class="card-img position-relative">
                                <img src="assets/images/blog/travel/05.jpg" class="img-fluid" alt="">
                                <div class="card-overlay"></div>
  
                                <div class="icons text-center">
                                    <a href="assets/images/blog/travel/05.jpg" class="work-icon bg-white d-inline-block rounded-pill lightbox"><i class="uil uil-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="swiper-slide">
                        <div class="work-container work-primary work-modern position-relative d-block client-testi rounded-0 overflow-hidden">
                            <div class="card-img position-relative">
                                <img src="assets/images/blog/travel/06.jpg" class="img-fluid" alt="">
                                <div class="card-overlay"></div>
  
                                <div class="icons text-center">
                                    <a href="assets/images/blog/travel/06.jpg" class="work-icon bg-white d-inline-block rounded-pill lightbox"><i class="uil uil-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="swiper-slide">
                        <div class="work-container work-primary work-modern position-relative d-block client-testi rounded-0 overflow-hidden">
                            <div class="card-img position-relative">
                                <img src="assets/images/blog/travel/07.jpg" class="img-fluid" alt="">
                                <div class="card-overlay"></div>
  
                                <div class="icons text-center">
                                    <a href="assets/images/blog/travel/07.jpg" class="work-icon bg-white d-inline-block rounded-pill lightbox"><i class="uil uil-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="swiper-slide">
                        <div class="work-container work-primary work-modern position-relative d-block client-testi rounded-0 overflow-hidden">
                            <div class="card-img position-relative">
                                <img src="assets/images/blog/travel/08.jpg" class="img-fluid" alt="">
                                <div class="card-overlay"></div>
  
                                <div class="icons text-center">
                                    <a href="assets/images/blog/travel/08.jpg" class="work-icon bg-white d-inline-block rounded-pill lightbox"><i class="uil uil-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="swiper-slide">
                        <div class="work-container work-primary work-modern position-relative d-block client-testi rounded-0 overflow-hidden">
                            <div class="card-img position-relative">
                                <img src="assets/images/blog/travel/09.jpg" class="img-fluid" alt="">
                                <div class="card-overlay"></div>
  
                                <div class="icons text-center">
                                    <a href="assets/images/blog/travel/09.jpg" class="work-icon bg-white d-inline-block rounded-pill lightbox"><i class="uil uil-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="swiper-slide">
                        <div class="work-container work-primary work-modern position-relative d-block client-testi rounded-0 overflow-hidden">
                            <div class="card-img position-relative">
                                <img src="assets/images/blog/travel/10.jpg" class="img-fluid" alt="">
                                <div class="card-overlay"></div>
  
                                <div class="icons text-center">
                                    <a href="assets/images/blog/travel/10.jpg" class="work-icon bg-white d-inline-block rounded-pill lightbox"><i class="uil uil-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="swiper-slide">
                        <div class="work-container work-primary work-modern position-relative d-block client-testi rounded-0 overflow-hidden">
                            <div class="card-img position-relative">
                                <img src="assets/images/blog/travel/11.jpg" class="img-fluid" alt="">
                                <div class="card-overlay"></div>
  
                                <div class="icons text-center">
                                    <a href="assets/images/blog/travel/11.jpg" class="work-icon bg-white d-inline-block rounded-pill lightbox"><i class="uil uil-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="swiper-slide">
                        <div class="work-container work-primary work-modern position-relative d-block client-testi rounded-0 overflow-hidden">
                            <div class="card-img position-relative">
                                <img src="assets/images/blog/travel/12.jpg" class="img-fluid" alt="">
                                <div class="card-overlay"></div>
  
                                <div class="icons text-center">
                                    <a href="assets/images/blog/travel/12.jpg" class="work-icon bg-white d-inline-block rounded-pill lightbox"><i class="uil uil-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </swiper>
  
                <div class="position-absolute top-50 start-50 translate-middle d-md-block d-none">
                    <a href="javascript:void(0)" class="btn btn-sm btn-primary text-uppercase">Follow Now</a>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
    <!-- Insta Post End -->
  
  <!-- Back to top -->
  <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->