<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> Carousel </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Components</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Carousel</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 d-md-block d-none">
                <app-component-sidebar></app-component-sidebar>
            </div>

            <div class="col-lg-9 col-md-8 col-12">
                <div class="row">
                    <div class="col-12">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> Carousel #1 </h5>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted mb-2 pb-2">Ex. 
                                    <pre><code class="text-danger">
&lt;ngb-carousel&gt;
    &lt;ng-template ngbSlide&gt;
        &lt;div class="picsum-img-wrapper"&gt;
            &lt;img src="assets/images/blog/slide01.jpg" class="d-block w-100" alt="..."&gt;
        &lt;/div&gt;
    &lt;/ng-templat&gt;
    &lt;ng-template ngbSlide&gt;
        &lt;div class="picsum-img-wrapper"&gt;
            &lt;img src="assets/images/blog/slide02.jpg" class="d-block w-100" alt="..."&gt;
        &lt;/div&gt;
    &lt;/ng-templat&gt;
    &lt;ng-template ngbSlide&gt;
        &lt;div class="picsum-img-wrapper"&gt;                                
            &lt;img src="assets/images/blog/slide03.jpg" class="d-block w-100" alt="..."&gt;
        &lt;/div&gt;
    &lt;/ng-templat&gt;
&lt;/ngb-carousel&gt;                           
</code></pre>
                                </h6>
                                
                                <ngb-carousel>
                                    <ng-template ngbSlide>
                                        <div class="picsum-img-wrapper">
                                            <img src="assets/images/blog/slide01.jpg" alt="Random first slide" />
                                        </div>
                                    </ng-template>
                                    <ng-template ngbSlide>
                                        <div class="picsum-img-wrapper">
                                            <img src="assets/images/blog/slide02.jpg" alt="Random second slide" />
                                        </div>
                                    </ng-template>
                                    <ng-template ngbSlide>
                                        <div class="picsum-img-wrapper">
                                            <img src="assets/images/blog/slide03.jpg" alt="Random third slide" />
                                        </div>
                                    </ng-template>
                                </ngb-carousel>
                            </div>
                        </div>
                    </div><!--end col-->

                    <div class="col-12 mt-4 pt-2">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> Carousel #2 </h5>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted mb-2 pb-2">Ex. 
                                    <pre><code class="text-danger">


&lt;section class="home-slider position-relative"&gt;
&lt;ngb-carousel [showNavigationArrows]="true"&gt;
    &lt;ng-template ngbSlide&gt;
        &lt;div class="bg-home-75vh d-flex align-items-center" style="background: url('assets/images/blog/bg1.jpg') center center;"&gt;
            &lt;div class="bg-overlay"&gt;&lt;/div&gt;
            &lt;div class="container"&gt;
                &lt;div class="row mt-5 justify-content-center"&gt;
                    &lt;div class="col-12 text-center text-white"&gt;
                        &lt;span&gt;Your Content&lt;/span&gt;
                    &lt;/div&gt;&lt;!--end col--&gt;
                &lt;/div&gt;&lt;!--end row--&gt;
            &lt;/div&gt;
        &lt;/div&gt;&lt;!--end slide--&gt;
    &lt;/ng-template&gt;

    &lt;ng-template ngbSlide&gt;
        &lt;div class="bg-home-75vh d-flex align-items-center" style="background: url('assets/images/blog/bg2.jpg') center center;"&gt;
            &lt;div class="bg-overlay"&gt;&lt;/div&gt;
            &lt;div class="container"&gt;
                &lt;div class="row mt-5 justify-content-center"&gt;
                    &lt;div class="col-12 text-center text-white"&gt;
                        &lt;span&gt;Your Content&lt;/span&gt;
                    &lt;/div&gt;&lt;!--end col--&gt;
                &lt;/div&gt;&lt;!--end row--&gt;
            &lt;/div&gt;
        &lt;/div&gt;&lt;!--end slide--&gt;
    &lt;/ng-template&gt;

    &lt;ng-template ngbSlide&gt;
        &lt;div class="bg-home-75vh d-flex align-items-center" style="background: url('assets/images/blog/bg3.jpg') center center;"&gt;
            &lt;div class="bg-overlay"&gt;&lt;/div&gt;
            &lt;div class="container"&gt;
                &lt;div class="row mt-5 justify-content-center"&gt;
                    &lt;div class="col-12 text-center text-white"&gt;
                        &lt;span&gt;Your Content&lt;/span&gt;
                    &lt;/div&gt;&lt;!--end col--&gt;
                &lt;/div&gt;&lt;!--end row--&gt;
            &lt;/div&gt;
        &lt;/div&gt;&lt;!--end slide--&gt;
    &lt;/ng-template&gt;
&lt;/ngb-carousel&gt;
&lt;/section&gt;                   
</code></pre>
                                </h6>
                                
                                <section class="home-slider position-relative">
                                    <ngb-carousel [showNavigationArrows]="true">
                                        <ng-template ngbSlide>
                                            <div class="bg-home-75vh d-flex align-items-center" style="background: url('assets/images/blog/bg1.jpg') center center;">
                                                <div class="bg-overlay"></div>
                                                <div class="container">
                                                    <div class="row mt-5 justify-content-center">
                                                        <div class="col-12 text-center text-white">
                                                            <span>Your Content</span>
                                                        </div><!--end col-->
                                                    </div><!--end row-->
                                                </div>
                                            </div><!--end slide-->
                                        </ng-template>
                                        <ng-template ngbSlide>
                                            <div class="bg-home-75vh d-flex align-items-center" style="background: url('assets/images/blog/bg2.jpg') center center;">
                                                <div class="bg-overlay"></div>
                                                <div class="container">
                                                    <div class="row mt-5 justify-content-center">
                                                        <div class="col-12 text-center text-white">
                                                            <span>Your Content</span>
                                                        </div><!--end col-->
                                                    </div><!--end row-->
                                                </div>
                                            </div><!--end slide-->
                                        </ng-template>
                                        <ng-template ngbSlide>
                                            <div class="bg-home-75vh d-flex align-items-center" style="background: url('assets/images/blog/bg3.jpg') center center;">
                                                <div class="bg-overlay"></div>
                                                <div class="container">
                                                    <div class="row mt-5 justify-content-center">
                                                        <div class="col-12 text-center text-white">
                                                            <span>Your Content</span>
                                                        </div><!--end col-->
                                                    </div><!--end row-->
                                                </div>
                                            </div><!--end slide-->
                                        </ng-template>
                                    </ngb-carousel>
                                </section><!--end section-->
                            </div>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end col-->
        </div>
    </div>
</section>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->

