        <!-- Hero Start -->
        <section class="bg-half-170 d-table w-100" style="background: url('assets/images/corporate/pages.jpg') top;" id="home">
            <div class="bg-overlay"></div>
            <div class="container">
                <div class="row mt-5 justify-content-center">
                    <div class="col-lg-12 text-center">
                        <div class="pages-heading title-heading">
                            <h2 class="text-white title-dark"> Team </h2>
                            <p class="text-white-50 para-desc mb-0 mx-auto">Start working with Landrick that can provide everything you need to generate awareness, drive traffic, connect.</p>
                        </div>
                    </div><!--end col-->
                </div><!--end row--> 
                
                <div class="position-breadcrumb">
                    <nav aria-label="breadcrumb" class="d-inline-block">
                        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                            <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                            <li class="breadcrumb-item"><a routerLink="/index-corporate">Business</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Team</li>
                        </ul>
                    </nav>
                </div>
            </div> <!--end container-->
        </section><!--end section-->
        <div class="position-relative">
            <div class="shape overflow-hidden text-color-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <!-- Hero End -->

        <!-- Start -->
        <section class="section">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-5 col-md-6">
                        <img src="assets/images/landing/2.jpg" class="img-fluid rounded-md shadow-lg" alt="">
                    </div><!--end col-->

                    <div class="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div class="section-title text-md-start text-center ms-lg-4">
                            <h4 class="title mb-4">Collaborate with your <br> team anytime and anywhare.</h4>
                            <p class="text-muted mb-0 para-desc">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                            
                            <div class="d-flex align-items-center text-start mt-4 pt-2">
                                <div class="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
                                    <i class="uil uil-capture"></i>
                                </div>
                                <div class="flex-1">
                                    <a href="javascript:void(0)" class="text-dark h6">Find Better Leads</a>
                                </div>
                            </div>

                            <div class="d-flex align-items-center text-start mt-4">
                                <div class="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
                                    <i class="uil uil-file"></i>
                                </div>
                                <div class="flex-1">
                                    <a href="javascript:void(0)" class="text-dark h6">Set Instant Metting</a>
                                </div>
                            </div>

                            <div class="d-flex align-items-center text-start mt-4">
                                <div class="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
                                    <i class="uil uil-credit-card-search"></i>
                                </div>
                                <div class="flex-1">
                                    <a href="javascript:void(0)" class="text-dark h6">Get Paid Seemlessly</a>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->

            <div class="container mt-100 mt-60">
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <div class="section-title mb-4 pb-2">
                            <h4 class="title mb-4">Our Team</h4>
                            <p class="text-muted para-desc mx-auto mb-0">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->

                <div class="row">
                    <div class="col-lg-3 col-md-6 mt-4 pt-2">
                        <div class="card team team-primary text-center border-0">
                            <div class="position-relative">
                                <img src="assets/images/client/01.jpg" class="img-fluid rounded shadow" alt="">
                                <ul class="list-unstyled mb-0 team-icon">
                                    <li class="list-inline-item"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="facebook" class="icons"></i-feather></a></li>
                                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="instagram" class="icons"></i-feather></a></li>
                                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="twitter" class="icons"></i-feather></a></li>
                                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="linkedin" class="icons"></i-feather></a></li>
                                </ul><!--end icon-->
                            </div>
                            <div class="card-body py-3 px-0 content">
                                <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Ronny Jofra</a></h5>
                                <small class="designation text-muted">C.E.O</small>
                            </div>
                        </div>
                    </div><!--end col-->

                    <div class="col-lg-3 col-md-6 mt-4 pt-2">
                        <div class="card team team-primary text-center border-0">
                            <div class="position-relative">
                                <img src="assets/images/client/04.jpg" class="img-fluid rounded shadow" alt="">
                                <ul class="list-unstyled mb-0 team-icon">
                                    <li class="list-inline-item"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="facebook" class="icons"></i-feather></a></li>
                                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="instagram" class="icons"></i-feather></a></li>
                                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="twitter" class="icons"></i-feather></a></li>
                                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="linkedin" class="icons"></i-feather></a></li>
                                </ul><!--end icon-->
                            </div>
                            <div class="card-body py-3 px-0 content">
                                <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Micheal Carlo</a></h5>
                                <small class="designation text-muted">Director</small>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-3 col-md-6 mt-4 pt-2">
                        <div class="card team team-primary text-center border-0">
                            <div class="position-relative">
                                <img src="assets/images/client/02.jpg" class="img-fluid rounded shadow" alt="">
                                <ul class="list-unstyled mb-0 team-icon">
                                    <li class="list-inline-item"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="facebook" class="icons"></i-feather></a></li>
                                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="instagram" class="icons"></i-feather></a></li>
                                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="twitter" class="icons"></i-feather></a></li>
                                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="linkedin" class="icons"></i-feather></a></li>
                                </ul><!--end icon-->
                            </div>
                            <div class="card-body py-3 px-0 content">
                                <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Aliana Rosy</a></h5>
                                <small class="designation text-muted">Manager</small>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-3 col-md-6 mt-4 pt-2">
                        <div class="card team team-primary text-center border-0">
                            <div class="position-relative">
                                <img src="assets/images/client/03.jpg" class="img-fluid rounded shadow" alt="">
                                <ul class="list-unstyled mb-0 team-icon">
                                    <li class="list-inline-item"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="facebook" class="icons"></i-feather></a></li>
                                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="instagram" class="icons"></i-feather></a></li>
                                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="twitter" class="icons"></i-feather></a></li>
                                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="linkedin" class="icons"></i-feather></a></li>
                                </ul><!--end icon-->
                            </div>
                            <div class="card-body py-3 px-0 content">
                                <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Sofia Razaq</a></h5>
                                <small class="designation text-muted">Developer</small>
                            </div>
                        </div>
                    </div><!--end col-->

                    <div class="col-lg-3 col-md-6 mt-4 pt-2">
                        <div class="card team team-primary text-center border-0">
                            <div class="position-relative">
                                <img src="assets/images/client/06.jpg" class="img-fluid rounded shadow" alt="">
                                <ul class="list-unstyled mb-0 team-icon">
                                    <li class="list-inline-item"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="facebook" class="icons"></i-feather></a></li>
                                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="instagram" class="icons"></i-feather></a></li>
                                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="twitter" class="icons"></i-feather></a></li>
                                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="linkedin" class="icons"></i-feather></a></li>
                                </ul><!--end icon-->
                            </div>
                            <div class="card-body py-3 px-0 content">
                                <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Calvin Carlo</a></h5>
                                <small class="designation text-muted">C.E.O</small>
                            </div>
                        </div>
                    </div><!--end col-->

                    <div class="col-lg-3 col-md-6 mt-4 pt-2">
                        <div class="card team team-primary text-center border-0">
                            <div class="position-relative">
                                <img src="assets/images/client/05.jpg" class="img-fluid rounded shadow" alt="">
                                <ul class="list-unstyled mb-0 team-icon">
                                    <li class="list-inline-item"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="facebook" class="icons"></i-feather></a></li>
                                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="instagram" class="icons"></i-feather></a></li>
                                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="twitter" class="icons"></i-feather></a></li>
                                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="linkedin" class="icons"></i-feather></a></li>
                                </ul><!--end icon-->
                            </div>
                            <div class="card-body py-3 px-0 content">
                                <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Juhi Chawla</a></h5>
                                <small class="designation text-muted">Director</small>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-3 col-md-6 mt-4 pt-2">
                        <div class="card team team-primary text-center border-0">
                            <div class="position-relative">
                                <img src="assets/images/client/07.jpg" class="img-fluid rounded shadow" alt="">
                                <ul class="list-unstyled mb-0 team-icon">
                                    <li class="list-inline-item"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="facebook" class="icons"></i-feather></a></li>
                                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="instagram" class="icons"></i-feather></a></li>
                                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="twitter" class="icons"></i-feather></a></li>
                                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="linkedin" class="icons"></i-feather></a></li>
                                </ul><!--end icon-->
                            </div>
                            <div class="card-body py-3 px-0 content">
                                <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Arlo Walker</a></h5>
                                <small class="designation text-muted">Manager</small>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-3 col-md-6 mt-4 pt-2">
                        <div class="card team team-primary text-center border-0">
                            <div class="position-relative">
                                <img src="assets/images/client/08.jpg" class="img-fluid rounded shadow" alt="">
                                <ul class="list-unstyled mb-0 team-icon">
                                    <li class="list-inline-item"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="facebook" class="icons"></i-feather></a></li>
                                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="instagram" class="icons"></i-feather></a></li>
                                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="twitter" class="icons"></i-feather></a></li>
                                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon"><i-feather name="linkedin" class="icons"></i-feather></a></li>
                                </ul><!--end icon-->
                            </div>
                            <div class="card-body py-3 px-0 content">
                                <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Randeep Huda</a></h5>
                                <small class="designation text-muted">Developer</small>
                            </div>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->

            <div class="container mt-100 mt-60">
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <div class="section-title mb-4 pb-2">
                            <h4 class="title mb-4">Our Clients Said</h4>
                            <p class="text-muted para-desc mx-auto mb-0">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->

                <div class="row justify-content-center">
                    <div class="col-lg-12 mt-4">
                        <owl-carousel-o id="customer-testi" [options]="customOptions">
                            <ng-template carouselSlide>
                            <div class="tiny-slide">
                                <div class="d-flex client-testi m-2">
                                <img src="assets/images/client/01.jpg" class="avatar avatar-small client-image rounded shadow" alt="">
                                    <div class="card flex-1 content p-3 shadow rounded position-relative">
                                        <ul class="list-unstyled mb-0">
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                        </ul>
                                        <p class="text-muted mt-2">" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. "</p>
                                        <h6 class="text-primary">- Thomas Israel <small class="text-muted">C.E.O</small></h6>
                                    </div>
                                </div>
                            </div>
                            </ng-template>
                            
                            <ng-template carouselSlide>
                            <div class="tiny-slide">
                                <div class="d-flex client-testi m-2">
                                <img src="assets/images/client/02.jpg" class="avatar avatar-small client-image rounded shadow" alt="">
                                    <div class="card flex-1 content p-3 shadow rounded position-relative">
                                        <ul class="list-unstyled mb-0">
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                            <li class="list-inline-item"><i class="mdi mdi-star-half text-warning"></i></li>
                                        </ul>
                                        <p class="text-muted mt-2">" One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others. "</p>
                                        <h6 class="text-primary">- Barbara McIntosh <small class="text-muted">M.D</small></h6>
                                    </div>
                                </div>
                            </div>
                            </ng-template>

                            <ng-template carouselSlide>
                            <div class="tiny-slide">
                                <div class="d-flex client-testi m-2">
                                <img src="assets/images/client/03.jpg" class="avatar avatar-small client-image rounded shadow" alt="">
                                    <div class="card flex-1 content p-3 shadow rounded position-relative">
                                        <ul class="list-unstyled mb-0">
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                        </ul>
                                        <p class="text-muted mt-2">" The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. "</p>
                                        <h6 class="text-primary">- Carl Oliver <small class="text-muted">P.A</small></h6>
                                    </div>
                                </div>
                            </div>
                            </ng-template>

                            <ng-template carouselSlide>
                            <div class="tiny-slide">
                                <div class="d-flex client-testi m-2">
                                <img src="assets/images/client/04.jpg" class="avatar avatar-small client-image rounded shadow" alt="">
                                    <div class="card flex-1 content p-3 shadow rounded position-relative">
                                        <ul class="list-unstyled mb-0">
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                        </ul>
                                        <p class="text-muted mt-2">" According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero. "</p>
                                        <h6 class="text-primary">- Christa Smith <small class="text-muted">Manager</small></h6>
                                    </div>
                                </div>
                            </div>
                            </ng-template>

                            <ng-template carouselSlide>
                            <div class="tiny-slide">
                                <div class="d-flex client-testi m-2">
                                <img src="assets/images/client/05.jpg" class="avatar avatar-small client-image rounded shadow" alt="">
                                    <div class="card flex-1 content p-3 shadow rounded position-relative">
                                        <ul class="list-unstyled mb-0">
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                        </ul>
                                        <p class="text-muted mt-2">" There is now an abundance of readable dummy texts. These are usually used when a text is required. "</p>
                                        <h6 class="text-primary">- Dean Tolle <small class="text-muted">Developer</small></h6>
                                    </div>
                                </div>
                            </div>
                            </ng-template>

                            <ng-template carouselSlide>
                            <div class="tiny-slide">
                                <div class="d-flex client-testi m-2">
                                <img src="assets/images/client/06.jpg" class="avatar avatar-small client-image rounded shadow" alt="">
                                    <div class="card flex-1 content p-3 shadow rounded position-relative">
                                        <ul class="list-unstyled mb-0">
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                                        </ul>
                                        <p class="text-muted mt-2">" Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts. "</p>
                                        <h6 class="text-primary">- Jill Webb <small class="text-muted">Designer</small></h6>
                                    </div>
                                </div>
                            </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
        </section><!--end section-->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
