<h5>Components</h5>
<ul class="list-unstyled sidebar-nav mb-0">
    <li class="navbar-item"><a routerLink="/ui-button" class="navbar-link">Buttons </a></li>
    <li class="navbar-item"><a routerLink="/ui-alert" class="navbar-link">Alert </a></li>
    <li class="navbar-item"><a routerLink="/ui-typography" class="navbar-link">Typography </a></li>
    <li class="navbar-item"><a routerLink="/ui-dropdown" class="navbar-link">Dropdowns </a></li>
    <li class="navbar-item"><a routerLink="/ui-background" class="navbar-link">Background </a></li>
    <li class="navbar-item"><a routerLink="/ui-text" class="navbar-link">Text Color </a></li>
    <li class="navbar-item"><a routerLink="/ui-badges" class="navbar-link">Badges </a></li>
    <li class="navbar-item"><a routerLink="/ui-accordion" class="navbar-link">Accordions </a></li>
    <li class="navbar-item"><a routerLink="/ui-card" class="navbar-link">Cards </a></li>
    <li class="navbar-item"><a routerLink="/ui-shadow" class="navbar-link">Shadows </a></li>
    <li class="navbar-item"><a routerLink="/ui-border" class="navbar-link">Borders </a></li>
    <li class="navbar-item"><a routerLink="/ui-carousel" class="navbar-link">Carousel </a></li>
    <li class="navbar-item"><a routerLink="/ui-form" class="navbar-link">Form Elements </a></li>
    <li class="navbar-item"><a routerLink="/ui-breadcrumb" class="navbar-link">Breadcrumb </a></li>
    <li class="navbar-item"><a routerLink="/ui-pagination" class="navbar-link">Pagination </a></li>
    <li class="navbar-item"><a routerLink="/ui-avatar" class="navbar-link">Avatars </a></li>
    <li class="navbar-item"><a routerLink="/ui-nav-tabs" class="navbar-link">Nav Tabs </a></li>
    <li class="navbar-item"><a routerLink="/ui-modals" class="navbar-link">Modals </a></li>
    <li class="navbar-item"><a routerLink="/ui-tables" class="navbar-link">Tables </a></li>
    <li class="navbar-item"><a routerLink="/ui-icons" class="navbar-link">Icons </a></li>
    <li class="navbar-item"><a routerLink="/ui-progressbar" class="navbar-link">Progressbar </a></li>
    <li class="navbar-item"><a routerLink="/ui-lightbox" class="navbar-link">Lightbox </a></li>
    <li class="navbar-item"><a routerLink="/ui-tooltip-popover" class="navbar-link">Tooltips & Popovers </a></li>
</ul>
