<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> Typography </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Components</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Typography</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 d-md-block d-none">
                <app-component-sidebar></app-component-sidebar>
            </div>

            <div class="col-lg-9 col-md-8 col-12">
                <div class="row">
                    <!-- Typography Heading Start -->
                    <div class="col-12">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> Heading </h5>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted mb-4 pb-2">Ex. <code class="text-danger">&lt;h1&gt; H1. Bootstrap heading &lt;/h1&gt;</code></h6>
                                
                                <h1>H1. Bootstrap heading</h1>
                                <h2>H2. Bootstrap heading</h2>
                                <h3>H3. Bootstrap heading</h3>
                                <h4>H4. Bootstrap heading</h4>
                                <h5>H5. Bootstrap heading</h5>
                                <h6 class="mb-0">H6. Bootstrap heading</h6>
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Typography Heading End -->

                    <!-- Display Heading Start -->
                    <div class="col-12 mt-4 pt-2">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> Display Heading </h5>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted mb-4 pb-2">Ex. <code class="text-danger">&lt;p class="display-1"&gt; Display 1 &lt;/p&gt;</code></h6>
                                
                                <p class="display-1">Display 1</p>
                                <p class="display-2">Display 2</p>
                                <p class="display-3">Display 3</p>
                                <p class="display-4">Display 4</p>
                                <p class="display-5">Display 5</p>
                                <p class="display-6 mb-0">Display 6</p>
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Display Heading End -->

                    <!-- List Start -->
                    <div class="col-12 mt-4 pt-2">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> List </h5>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted mb-4 pb-2">Ex. <br> <code class="text-danger">
                                    &lt;ul class="list-unstyled text-muted"&gt; <br>
                                        &nbsp;&nbsp;&lt;li class="mb-1"&gt;&lt;span class="text-primary h5 me-2"&gt;&lt;i class="uil uil-check-circle align-middle"&gt;&lt;/i&gt;&lt;/span&gt;Digital Marketing Solutions for Tomorrow&lt;/li&gt;<br>
                                        &nbsp;&nbsp;&lt;li class="mb-1"&gt;&lt;span class="text-primary h5 me-2"&gt;&lt;i class="uil uil-check-circle align-middle"&gt;&lt;/i&gt;&lt;/span&gt;Our Talented &amp; Experienced Marketing Agency&lt;/li&gt;<br>
                                        &nbsp;&nbsp;&lt;li class="mb-1"&gt;&lt;span class="text-primary h5 me-2"&gt;&lt;i class="uil uil-check-circle align-middle"&gt;&lt;/i&gt;&lt;/span&gt;Create your own skin to match your brand&lt;/li&gt;<br>
                                    &lt;/ul&gt;
                                </code></h6>
                                
                                <ul class="list-unstyled text-muted">
                                    <li class="mb-1"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li>
                                    <li class="mb-1"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Our Talented &amp; Experienced Marketing Agency</li>
                                    <li class="mb-1"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Create your own skin to match your brand</li>
                                </ul>
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- List End -->

                    <!-- Blockquote Start -->
                    <div class="col-12 mt-4 pt-2">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> Blockquote </h5>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted mb-4 pb-2">Ex. <br> <code class="text-danger">
                                    &lt;div class="blockquote-wrapper"&gt;<br>
                                        &nbsp;&lt;div class="blockquote p-4 p-md-5 rounded-lg position-relative"&gt;<br>
                                            &nbsp;&nbsp;&lt;h4&gt;Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap v5 html page.&lt;/h4&gt;<br>
            
                                            &nbsp;&nbsp;&nbsp;&lt;div class="position-absolute start-0" style="z-index: -1; top: -30px;"&gt;<br>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&lt;i class="mdi mdi-format-quote-open text-primary opacity-1" style="font-size: 100px;"&gt;&lt;/i&gt;<br>
                                            &nbsp;&nbsp;&nbsp;&lt;/div&gt;<br>
                                        &nbsp;&nbsp;&lt;/div&gt;<br>
                                        
                                        &nbsp;&lt;h6 class="author"&gt;- Shreethemes&lt;/h6&gt;<br>
                                    &lt;/div&gt;
                                </code></h6>
                                
                                <div class="blockquote-wrapper">
                                    <div class="blockquote p-4 p-md-5 rounded-lg position-relative">
                                        <h4>Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap v5 html page.</h4>
        
                                        <div class="position-absolute start-0" style="z-index: -1; top: -30px;">
                                            <i class="mdi mdi-format-quote-open text-primary opacity-1" style="font-size: 100px;"></i>
                                        </div>
                                    </div>
                                    
                                    <h6 class="author">- Shreethemes</h6>
                                </div>

                                <h6 class="text-muted mt-5 mb-4 pt-5 pb-2 border-top">Ex. <br> <code class="text-danger">
                                    &lt;blockquote class="blockquote mt-3 p-3"&gt;<br>
                                        &nbsp;&lt;p class="text-muted mb-0 fst-italic"&gt;" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. "&lt;/p&gt;<br>
                                    &lt;/blockquote&gt;
                                </code></h6>
                                
                                <blockquote class="blockquote mt-3 p-3">
                                    <p class="text-muted mb-0 fst-italic">" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. "</p>
                                </blockquote>
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Blockquote End -->
                </div><!--end row-->
            </div><!--end col-->
        </div>
    </div>
</section>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->
