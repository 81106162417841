import { Component } from '@angular/core';

@Component({
  selector: 'app-ui-card',
  templateUrl: './ui-card.component.html',
  styleUrls: ['./ui-card.component.css']
})
export class UiCardComponent {
  /**
   * Header button list show
   */
  Settingicon = true;
  Menuoption = 'center'
}
