<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h4 class="title mb-0"> My Profile / Account </h4>
        </div>
      </div><!--end col-->
    </div><!--end row-->

    <div class="position-breadcrumb">
      <nav aria-label="breadcrumb" class="d-inline-block">
        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
          <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
          <li class="breadcrumb-item"><a href="index-shop">Shop</a></li>
          <li class="breadcrumb-item active" aria-current="page">My Account</li>
        </ul>
      </nav>
    </div>
  </div> <!--end container-->
</section><!--end section-->

<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<!-- Start -->
<section class="section">
  <div class="container">
    <div class="row align-items-end">
      <div class="col-md-4">
        <div class="d-flex align-items-center">
          <img src="assets/images/client/05.jpg" class="avatar avatar-md-md rounded-circle" alt="">
          <div class="ms-3">
            <h6 class="text-muted mb-0">Hello,</h6>
            <h5 class="mb-0">Cally Joseph</h5>
          </div>
        </div>
      </div><!--end col-->
      <div class="col-md-8 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <p class="text-muted mb-0">Launch your campaign and benefit from our expertise on designing and managing
          conversion centered bootstrap v5 html page.</p>
      </div><!--end col-->
      </div><!--end row-->

      <div class="row">
      <div class="d-flex">
        <ul ngbNav #nav="ngbNav" class="nav nav-pills flex-column rounded mt-4 shadow p-3 mb-0 col-sm-4"
          orientation="vertical">
          <li ngbNavItem="top" class="nav-item">
            <a ngbNavLink class="nav-link rounded">
              <div class="text-start py-1 px-3">
                <h6 class="mb-0"><i class="uil uil-dashboard h5 align-middle me-2 mb-0"></i> Dashboard</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="tab-pane fade show active p-4 rounded shadow" id="developing" role="tabpanel"
                aria-labelledby="proposal">
                <h6 class="text-muted">Hello <span class="text-dark">cally_joseph</span> (not <span
                    class="text-dark">cally_joseph</span>? <a href="javascript:void(0)" class="text-danger">Log out</a>)
                </h6>
                <h6 class="text-muted mb-0">From your account dashboard you can view your <a href="javascript:void(0)"
                    class="text-danger">recent orders</a>, manage your <a href="javascript:void(0)"
                    class="text-danger">shipping and billing addresses</a>, and <a href="javascript:void(0)"
                    class="text-danger">edit your password and account details</a>.</h6>
              </div>
              <!--end teb pane-->
            </ng-template>
          </li>
          <li ngbNavItem="middle" class="nav-item mt-2">
            <a ngbNavLink class="nav-link rounded">
              <div class="text-start py-1 px-3">
                <h6 class="mb-0"><i class="uil uil-list-ul h5 align-middle me-2 mb-0"></i> Orders</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="tab-pane fade show active p-4 rounded shadow" id="developing" role="tabpanel"
                aria-labelledby="proposal">
                <div class="table-responsive bg-white shadow rounded">
                  <table class="table mb-0 table-center table-nowrap">
                    <thead>
                      <tr>
                        <th scope="col" class="border-bottom">Order no.</th>
                        <th scope="col" class="border-bottom">Date</th>
                        <th scope="col" class="border-bottom">Status</th>
                        <th scope="col" class="border-bottom">Total</th>
                        <th scope="col" class="border-bottom">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">7107</th>
                        <td>1st November 2020</td>
                        <td class="text-success">Delivered</td>
                        <td>$ 320 <span class="text-muted">for 2items</span></td>
                        <td><a href="javascript:void(0)" class="text-primary">View <i
                              class="uil uil-arrow-right"></i></a></td>
                      </tr>

                      <tr>
                        <th scope="row">8007</th>
                        <td>4th November 2020</td>
                        <td class="text-muted">Processing</td>
                        <td>$ 800 <span class="text-muted">for 1item</span></td>
                        <td><a href="javascript:void(0)" class="text-primary">View <i
                              class="uil uil-arrow-right"></i></a></td>
                      </tr>

                      <tr>
                        <th scope="row">8008</th>
                        <td>4th November 2020</td>
                        <td class="text-danger">Canceled</td>
                        <td>$ 800 <span class="text-muted">for 1item</span></td>
                        <td><a href="javascript:void(0)" class="text-primary">View <i
                              class="uil uil-arrow-right"></i></a></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!--end teb pane-->
            </ng-template>
          </li>
          <li ngbNavItem="bottom" class="nav-item mt-2">
            <a ngbNavLink class="nav-link rounded">
              <div class="text-start py-1 px-3">
                <h6 class="mb-0"><i class="uil uil-arrow-circle-down h5 align-middle me-2 mb-0"></i> Downloads</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="tab-pane fade show active p-4 rounded shadow" id="developing" role="tabpanel"
                aria-labelledby="proposal">
                <div class="table-responsive bg-white shadow rounded">
                  <table class="table mb-0 table-center table-nowrap">
                    <thead>
                      <tr>
                        <th scope="col" class="border-bottom">Product Name</th>
                        <th scope="col" class="border-bottom">Description</th>
                        <th scope="col" class="border-bottom">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Quick heal</th>
                        <td class="text-muted">It is said that song composers of the past <br> used dummy texts as
                          lyrics when writing <br> melodies in order to have a 'ready-made' <br> text to sing with the
                          melody.</td>
                        <td class="text-success">Downloaded</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!--end teb pane-->
            </ng-template>
          </li>
          <li ngbNavItem="general" class="nav-item mt-2">
            <a ngbNavLink class="nav-link rounded">
              <div class="text-start py-1 px-3">
                <h6 class="mb-0"><i class="uil uil-map-marker h5 align-middle me-2 mb-0"></i> Addresses</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="tab-pane fade show active p-4 rounded shadow" id="developing" role="tabpanel"
                aria-labelledby="proposal">
                <h6 class="text-muted mb-0">The following addresses will be used on the checkout page by default.</h6>

                <div class="row">
                  <div class="col-lg-6 mt-4 pt-2">
                    <div class="d-flex align-items-center mb-4 justify-content-between">
                      <h5 class="mb-0">Billing Address:</h5>
                      <a href="javascript:void(0)" class="text-primary h5 mb-0" data-bs-toggle="tooltip"
                        data-bs-placement="top" title="" data-original-title="Edit"><i
                          class="uil uil-edit align-middle"></i></a>
                    </div>
                    <div class="pt-4 border-top">
                      <p class="h6">Cally Joseph</p>
                      <p class="h6 text-muted">C/54 Northwest Freeway, </p>
                      <p class="h6 text-muted">Suite 558,</p>
                      <p class="h6 text-muted">Houston, USA 485</p>
                      <p class="h6 text-muted mb-0">+123 897 5468</p>
                    </div>
                  </div>

                  <div class="col-lg-6 mt-4 pt-2">
                    <div class="d-flex align-items-center mb-4 justify-content-between">
                      <h5 class="mb-0">Shipping Address:</h5>
                      <a href="javascript:void(0)" class="text-primary h5 mb-0" data-bs-toggle="tooltip"
                        data-bs-placement="top" title="" data-original-title="Edit"><i
                          class="uil uil-edit align-middle"></i></a>
                    </div>
                    <div class="pt-4 border-top">
                      <p class="h6">Cally Joseph</p>
                      <p class="h6 text-muted">C/54 Northwest Freeway, </p>
                      <p class="h6 text-muted">Suite 558,</p>
                      <p class="h6 text-muted">Houston, USA 485</p>
                      <p class="h6 text-muted mb-0">+123 897 5468</p>
                    </div>
                  </div>
                </div>
              </div>
              <!--end teb pane-->
            </ng-template>
          </li>
          <li ngbNavItem="medicine" class="nav-item mt-2">
            <a ngbNavLink class="nav-link rounded">
              <div class="text-start py-1 px-3">
                <h6 class="mb-0"><i class="uil uil-user h5 align-middle me-2 mb-0"></i> Account Details</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="tab-pane fade show active p-4 rounded shadow" id="developing" role="tabpanel"
                aria-labelledby="proposal">
                <form>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label">First Name</label>
                        <div class="form-icon position-relative">
                          <i-feather name="user" class="fea icon-sm icons"></i-feather>
                          <input name="name" id="first-name" type="text" class="form-control ps-5" value="Cally">
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label">Last Name</label>
                        <div class="form-icon position-relative">
                          <i-feather name="user-check" class="fea icon-sm icons"></i-feather>
                          <input name="name" id="last-name" type="text" class="form-control ps-5" value="Joseph">
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label">Your Email</label>
                        <div class="form-icon position-relative">
                          <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                          <input name="email" id="email" type="email" class="form-control ps-5"
                            value="callyjoseph@gmail.com">
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label">Display Name</label>
                        <div class="form-icon position-relative">
                          <i-feather name="user-check" class="fea icon-sm icons"></i-feather>
                          <input name="name" id="display-name" type="text" class="form-control ps-5"
                            value="cally_joseph">
                        </div>
                      </div>
                    </div>
                    <!--end col-->

                    <div class="col-lg-12 mt-2 mb-0">
                      <button class="btn btn-primary">Save Changes</button>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </form>

                <h5 class="mt-4">Change password :</h5>
                <form>
                  <div class="row mt-3">
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label class="form-label">Old password :</label>
                        <div class="form-icon position-relative">
                          <i-feather name="key" class="fea icon-sm icons"></i-feather>
                          <input type="password" class="form-control ps-5" placeholder="Old password" required="">
                        </div>
                      </div>
                    </div>
                    <!--end col-->

                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label class="form-label">New password :</label>
                        <div class="form-icon position-relative">
                          <i-feather name="key" class="fea icon-sm icons"></i-feather>
                          <input type="password" class="form-control ps-5" placeholder="New password" required="">
                        </div>
                      </div>
                    </div>
                    <!--end col-->

                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label class="form-label">Re-type New password :</label>
                        <div class="form-icon position-relative">
                          <i-feather name="key" class="fea icon-sm icons"></i-feather>
                          <input type="password" class="form-control ps-5" placeholder="Re-type New password"
                            required="">
                        </div>
                      </div>
                    </div>
                    <!--end col-->

                    <div class="col-lg-12 mt-2 mb-0">
                      <button class="btn btn-primary">Save Password</button>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </form>
              </div>
              <!--end teb pane-->
            </ng-template>
          </li>
          <li ngbNavItem="orthopadic" class="nav-item mt-2">
            <a routerLink="/auth-login" ngbNavLink class="nav-link rounded">
              <div class="text-start py-1 px-3">
                <h6 class="mb-0"><i class="uil uil-sign-out-alt h5 align-middle me-2 mb-0"></i> Logout</h6>
              </div>
            </a>
          </li>
        </ul>

        <div [ngbNavOutlet]="nav" class="ms-4 mt-4 col-md-8"></div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->