<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> All Products </h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                <li class="breadcrumb-item"><a routerLink="/index-shop">Shop</a></li>
                <li class="breadcrumb-item active" aria-current="page">Products</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<!-- Start Products -->
<section class="section">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-4 col-12">
        <div class="card border-0 sidebar sticky-bar">
          <div class="card-body p-0">
            <!-- SEARCH -->
            <div class="widget">
              <form role="search" method="get">
                <div class="input-group mb-3 border rounded">
                  <input type="text" id="s" name="s" class="form-control border-0" placeholder="Search Keywords...">
                  <button type="submit" class="input-group-text bg-white border-0" id="searchsubmit"><i
                      class="uil uil-search"></i></button>
                </div>
              </form>
            </div>
            <!-- SEARCH -->

            <!-- Categories -->
            <div class="widget mt-4 pt-2">
              <h5 class="widget-title">Categories</h5>
              <ul class="list-unstyled mt-4 mb-0 blog-categories">
                <li><a href="jvascript:void(0)">Men</a></li>
                <li><a href="jvascript:void(0)">Women</a></li>
                <li><a href="jvascript:void(0)">Electronics</a></li>
                <li><a href="jvascript:void(0)">Jewellery</a></li>
                <li><a href="jvascript:void(0)">Shoes</a></li>
                <li><a href="jvascript:void(0)">Kid’s Wear</a></li>
                <li><a href="jvascript:void(0)">Sports</a></li>
                <li><a href="jvascript:void(0)">Toys</a></li>
                <li><a href="jvascript:void(0)">Gift Corners</a></li>
              </ul>
            </div>
            <!-- Categories -->

            <!-- color -->
            <div class="widget mt-4 pt-2">
              <h5 class="widget-title">Color</h5>
              <ul class="list-unstyled mt-4 mb-0">
                <li class="list-inline-item"><a href="javascript:void(0)"
                    class="btn btn-sm btn-icon btn-pills btn-primary"><span class="d-none">.</span></a></li>
                <li class="list-inline-item"><a href="javascript:void(0)"
                    class="btn btn-sm btn-icon btn-pills btn-danger"><span class="d-none">.</span></a></li>
                <li class="list-inline-item"><a href="javascript:void(0)"
                    class="btn btn-sm btn-icon btn-pills btn-success"><span class="d-none">.</span></a></li>
                <li class="list-inline-item"><a href="javascript:void(0)"
                    class="btn btn-sm btn-icon btn-pills btn-info"><span class="d-none">.</span></a></li>
                <li class="list-inline-item"><a href="javascript:void(0)"
                    class="btn btn-sm btn-icon btn-pills btn-secondary"><span class="d-none">.</span></a></li>
                <li class="list-inline-item"><a href="javascript:void(0)"
                    class="btn btn-sm btn-icon btn-pills btn-warning"><span class="d-none">.</span></a></li>
              </ul>
            </div>
            <!-- COlor -->

            <!-- Top Products -->
            <div class="widget mt-4 pt-2">
              <h5 class="widget-title">Top Products</h5>
              <ul class="list-unstyled mt-4 mb-0">
                <li class="d-flex align-items-center">
                  <a href="javascript:void(0)">
                    <img src="assets/images/shop/product/s1.jpg" class="img-fluid avatar avatar-small rounded shadow"
                      style="height:auto;" alt="">
                  </a>
                  <div class="flex-1 content ms-3">
                    <a href="javascript:void(0)" class="text-dark h6">T-Shirt</a>
                    <h6 class="text-muted small fst-italic mb-0 mt-1">$18.00 <del class="text-danger ms-2">$22.00</del>
                    </h6>
                  </div>
                </li>
                <li class="d-flex align-items-center mt-2">
                  <a href="javascript:void(0)">
                    <img src="assets/images/shop/product/s3.jpg" class="img-fluid avatar avatar-small rounded shadow"
                      style="height:auto;" alt="">
                  </a>
                  <div class="flex-1 content ms-3">
                    <a href="javascript:void(0)" class="text-dark h6">Watch</a>
                    <h6 class="text-muted small fst-italic mb-0 mt-1">$18.00 <del class="text-danger ms-2">$22.00</del>
                    </h6>
                  </div>
                </li>
                <li class="d-flex align-items-center mt-2">
                  <a href="javascript:void(0)">
                    <img src="assets/images/shop/product/s6.jpg" class="img-fluid avatar avatar-small rounded shadow"
                      style="height:auto;" alt="">
                  </a>
                  <div class="flex-1 content ms-3">
                    <a href="javascript:void(0)" class="text-dark h6">Coffee Cup</a>
                    <h6 class="text-muted small fst-italic mb-0 mt-1">$18.00 <del class="text-danger ms-2">$22.00</del>
                    </h6>
                  </div>
                </li>
                <li class="d-flex align-items-center mt-2">
                  <a href="javascript:void(0)">
                    <img src="assets/images/shop/product/s8.jpg" class="img-fluid avatar avatar-small rounded shadow"
                      style="height:auto;" alt="">
                  </a>
                  <div class="flex-1 content ms-3">
                    <a href="javascript:void(0)" class="text-dark h6">Wooden Stools</a>
                    <h6 class="text-muted small fst-italic mb-0 mt-1">$18.00 <del class="text-danger ms-2">$22.00</del>
                    </h6>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-9 col-md-8 col-12 mt-5 pt-2 mt-sm-0 pt-sm-0">
        <div class="row align-items-center">
          <div class="col-lg-8 col-md-7">
            <div class="section-title">
              <h5 class="mb-0">Showing 1–15 of 47 results</h5>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div class="d-flex justify-content-md-between align-items-center">
              <div class="form custom-form">
                <div class="mb-0">
                  <select class="form-select form-control" aria-label="Default select example" id="Sortbylist-job">
                    <option selected>Sort by latest</option>
                    <option>Sort by popularity</option>
                    <option>Sort by rating</option>
                    <option>Sort by price: low to high</option>
                    <option>Sort by price: high to low</option>
                  </select>
                </div>
              </div>

              <div class="mx-2">
                <a routerLink="/shop-products" class="h5 text-muted"><i class="uil uil-apps"></i></a>
              </div>

              <div>
                <a routerLink="/shop-lists" class="h5 text-muted"><i class="uil uil-list-ul"></i></a>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative">
              <ul class="label list-unstyled mb-0">
                <li><a href="javascript:void(0)" class="badge badge-link rounded-pill bg-success">Featured</a></li>
              </ul>
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s1.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-1.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#productview"
                      class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Branded T-Shirt</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small fst-italic mb-0 mt-1">$16.00 <del class="text-danger ms-2">$21.00</del>
                  </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative">
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s2.jpg" class="img-fluid"
                    alt=""></a>
                <div class="overlay-work">
                  <div class="py-2 bg-soft-dark rounded-bottom out-stock">
                    <h6 class="mb-0 text-center">Out of stock</h6>
                  </div>
                </div>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Shopping Bag</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small fst-italic mb-0 mt-1">$21.00 <del class="text-danger ms-2">$25.00</del>
                  </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative">
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s3.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-3.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#productview"
                      class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Elegent Watch</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small fst-italic mb-0 mt-1">$5.00 <span class="text-success ms-1">30% off</span>
                  </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative">
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s4.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-4.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#productview"
                      class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Casual Shoes</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small fst-italic mb-0 mt-1">$18.00 <del class="text-danger ms-2">$22.00</del>
                  </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative">
              <ul class="label list-unstyled mb-0">
                <li><a href="javascript:void(0)" class="badge badge-link rounded-pill bg-warning">Sale</a></li>
              </ul>
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s5.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-5.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#productview"
                      class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Earphones</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small fst-italic mb-0 mt-1">$3.00</h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative">
              <ul class="label list-unstyled mb-0">
                <li><a href="javascript:void(0)" class="badge badge-link rounded-pill bg-primary">New</a></li>
                <li><a href="javascript:void(0)" class="badge badge-link rounded-pill bg-success">Featured</a></li>
                <li><a href="javascript:void(0)" class="badge badge-link rounded-pill bg-warning">Sale</a></li>
              </ul>
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s6.jpg" class="img-fluid"
                    alt=""></a>
                <div class="overlay-work">
                  <div class="py-2 bg-soft-dark rounded-bottom out-stock">
                    <h6 class="mb-0 text-center">Out of stock</h6>
                  </div>
                </div>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Elegent Mug</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small fst-italic mb-0 mt-1">$4.50 <del class="text-danger ms-2">$6.50</del>
                  </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative">
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s7.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-7.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#productview"
                      class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Sony Headphones</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small fst-italic mb-0 mt-1">$9.99 <span class="text-success ms-2">20% off</span>
                  </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative">
              <ul class="label list-unstyled mb-0">
                <li><a href="javascript:void(0)" class="badge badge-link rounded-pill bg-primary">New</a></li>
                <li><a href="javascript:void(0)" class="badge badge-link rounded-pill bg-success">Featured</a></li>
                <li><a href="javascript:void(0)" class="badge badge-link rounded-pill bg-warning">Sale</a></li>
              </ul>
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s8.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-8.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#productview"
                      class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Wooden Stools</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small fst-italic mb-0 mt-1">$22.00 <del class="text-danger ms-2">$25.00</del>
                  </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative">
              <ul class="label list-unstyled mb-0">
                <li><a href="javascript:void(0)" class="badge badge-link rounded-pill bg-success">Featured</a></li>
              </ul>
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s9.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-9.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#productview"
                      class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Coffee Cup / Mug</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small fst-italic mb-0 mt-1">$16.00 <del class="text-danger ms-2">$21.00</del>
                  </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative">
              <ul class="label list-unstyled mb-0">
                <li><a href="javascript:void(0)" class="badge badge-link rounded-pill bg-primary">New</a></li>
              </ul>
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s10.jpg" class="img-fluid"
                    alt=""></a>
                <div class="overlay-work">
                  <div class="py-2 bg-soft-dark rounded-bottom out-stock">
                    <h6 class="mb-0 text-center">Out of stock</h6>
                  </div>
                </div>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Sunglasses</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small fst-italic mb-0 mt-1">$21.00 <del class="text-danger ms-2">$25.00</del>
                  </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative">
              <ul class="label list-unstyled mb-0">
                <li><a href="javascript:void(0)" class="badge badge-link rounded-pill bg-success">Featured</a></li>
              </ul>
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s11.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-11.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#productview"
                      class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Loafer Shoes</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small fst-italic mb-0 mt-1">$5.00 <span class="text-success ms-1">30% off</span>
                  </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative">
              <ul class="label list-unstyled mb-0">
                <li><a href="javascript:void(0)" class="badge badge-link rounded-pill bg-primary">New</a></li>
                <li><a href="javascript:void(0)" class="badge badge-link rounded-pill bg-success">Featured</a></li>
                <li><a href="javascript:void(0)" class="badge badge-link rounded-pill bg-warning">Sale</a></li>
              </ul>
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s12.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-12.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#productview"
                      class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">T-Shirts</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small fst-italic mb-0 mt-1">$18.00 <del class="text-danger ms-2">$22.00</del>
                  </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative">
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s13.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-13.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#productview"
                      class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Wooden Chair</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small fst-italic mb-0 mt-1">$16.00 <del class="text-danger ms-2">$21.00</del>
                  </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative">
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s14.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-14.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#productview"
                      class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Women Block Heels</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small fst-italic mb-0 mt-1">$21.00 <del class="text-danger ms-2">$25.00</del>
                  </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card shop-list border-0 position-relative">
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s15.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-15.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#productview"
                      class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">T-Shirts</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small fst-italic mb-0 mt-1">$5.00 <span class="text-success ms-1">30% off</span>
                  </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <!-- PAGINATION START -->
          <div class="col-12 mt-4 pt-2">
            <ul class="pagination justify-content-center mb-0">
              <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Previous"><i
                    class="mdi mdi-arrow-left"></i> Prev</a></li>
              <li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a></li>
              <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
              <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
              <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Next">Next <i
                    class="mdi mdi-arrow-right"></i></a></li>
            </ul>
          </div>
          <!--end col-->
          <!-- PAGINATION END -->
        </div>
        <!--end row-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Products -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->