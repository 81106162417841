<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" style="background: url('assets/images/shape02.png') top left;"
    id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> Blogs & News </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a routerLink="/index-crypto">Crypto</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Blogs</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<!-- Blog Start -->
<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-12">
                <div class="card blog blog-primary border-0 rounded shadow overflow-hidden">
                    <img src="assets/images/blog/01.jpg" class="img-fluid" alt="">

                    <div class="card-body content">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <span class="badge bg-soft-primary">ICO</span>
                            <small class="text-muted">31st May 2021</small>
                        </div>
                        <a routerLink="/crypto-blog-detail" class="title text-dark h5">Start The Redemption Before The
                            ICO Completion</a>

                        <div class="mt-3">
                            <a routerLink="/crypto-blog-detail" class="link">Read More <i
                                    class="uil uil-arrow-right align-middle"></i></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="card blog blog-primary border-0 rounded shadow overflow-hidden">
                    <img src="assets/images/blog/02.jpg" class="img-fluid" alt="">

                    <div class="card-body content">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <span class="badge bg-soft-primary">Bitcoin</span>
                            <small class="text-muted">31st May 2021</small>
                        </div>
                        <a routerLink="/crypto-blog-detail" class="title text-dark h5">New Trends In UI/UX Design World
                            Integration</a>

                        <div class="mt-3">
                            <a routerLink="/crypto-blog-detail" class="link">Read More <i
                                    class="uil uil-arrow-right align-middle"></i></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 mt-lg-0 pt-lg-0">
                <div class="card blog blog-primary border-0 rounded shadow overflow-hidden">
                    <img src="assets/images/blog/03.jpg" class="img-fluid" alt="">

                    <div class="card-body content">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <span class="badge bg-soft-primary">Cryptocurrency</span>
                            <small class="text-muted">31st May 2021</small>
                        </div>
                        <a routerLink="/crypto-blog-detail" class="title text-dark h5">The Crypto Project Has Reached
                            Seven Billions</a>

                        <div class="mt-3">
                            <a routerLink="/crypto-blog-detail" class="link">Read More <i
                                    class="uil uil-arrow-right align-middle"></i></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card blog blog-primary border-0 rounded shadow overflow-hidden">
                    <img src="assets/images/blog/04.jpg" class="img-fluid" alt="">

                    <div class="card-body content">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <span class="badge bg-soft-primary">Blockchain</span>
                            <small class="text-muted">31st May 2021</small>
                        </div>
                        <a routerLink="/crypto-blog-detail" class="title text-dark h5">What is blockchain ?</a>

                        <div class="mt-3">
                            <a routerLink="/crypto-blog-detail" class="link">Read More <i
                                    class="uil uil-arrow-right align-middle"></i></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card blog blog-primary border-0 rounded shadow overflow-hidden">
                    <img src="assets/images/blog/05.jpg" class="img-fluid" alt="">

                    <div class="card-body content">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <span class="badge bg-soft-primary">Crypto</span>
                            <small class="text-muted">31st May 2021</small>
                        </div>
                        <a routerLink="/crypto-blog-detail" class="title text-dark h5">Top 10 cryptocurrency</a>

                        <div class="mt-3">
                            <a routerLink="/crypto-blog-detail" class="link">Read More <i
                                    class="uil uil-arrow-right align-middle"></i></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card blog blog-primary border-0 rounded shadow overflow-hidden">
                    <img src="assets/images/blog/06.jpg" class="img-fluid" alt="">

                    <div class="card-body content">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <span class="badge bg-soft-primary">Trading</span>
                            <small class="text-muted">31st May 2021</small>
                        </div>
                        <a routerLink="/crypto-blog-detail" class="title text-dark h5">Trading Platform</a>

                        <div class="mt-3">
                            <a routerLink="/crypto-blog-detail" class="link">Read More <i
                                    class="uil uil-arrow-right align-middle"></i></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card blog blog-primary border-0 rounded shadow overflow-hidden">
                    <img src="assets/images/blog/07.jpg" class="img-fluid" alt="">

                    <div class="card-body content">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <span class="badge bg-soft-primary">Crypto</span>
                            <small class="text-muted">31st May 2021</small>
                        </div>
                        <a routerLink="/crypto-blog-detail" class="title text-dark h5">The Crypto Project Has Reached
                            Seven Billions</a>

                        <div class="mt-3">
                            <a routerLink="/crypto-blog-detail" class="link">Read More <i
                                    class="uil uil-arrow-right align-middle"></i></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card blog blog-primary border-0 rounded shadow overflow-hidden">
                    <img src="assets/images/blog/08.jpg" class="img-fluid" alt="">

                    <div class="card-body content">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <span class="badge bg-soft-primary">Integration</span>
                            <small class="text-muted">31st May 2021</small>
                        </div>
                        <a routerLink="/crypto-blog-detail" class="title text-dark h5">New Trends In UI/UX Design World
                            Integration</a>

                        <div class="mt-3">
                            <a routerLink="/crypto-blog-detail" class="link">Read More <i
                                    class="uil uil-arrow-right align-middle"></i></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row">
            <!-- PAGINATION START -->
            <div class="col-12 mt-4 pt-2">
                <ul class="pagination justify-content-center mb-0 mt-3 mt-sm-0">
                    <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Previous">Prev</a>
                    </li>
                    <li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Next">Next</a></li>
                </ul>
            </div><!--end col-->
            <!-- PAGINATION END -->
        </div><!--end col-->
    </div><!--end container-->
</section><!--end section-->
<!-- Blog End -->

<!-- Start -->
<section class="section bg-light">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center">
                    <h4 class="title mb-4">Be the first to know about Crypto news everyday</h4>
                    <p class="para-desc mx-auto text-muted mb-0">Get crypto analysis, news and updates right to your
                        inbox! Sign up here so you don't miss a single newsletter.</p>

                    <div class="subcribe-form mt-5">
                        <form>
                            <div class="mb-0">
                                <input type="email" id="email" name="email" class="rounded-pill border" required
                                    placeholder="Enter your email address">
                                <button type="submit" class="btn btn-pills btn-primary">Notify me</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->