<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> Alerts </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Components</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Alerts</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 d-md-block d-none">
                <app-component-sidebar></app-component-sidebar>
            </div>

            <div class="col-lg-9 col-md-8 col-12">
                <div class="row">
                    <!-- Alert Start -->
                    <div class="col-12">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> Alert </h5>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted mb-4 pb-2">Ex. <code
                                        class="text-danger">&lt;ngb-alert class="alert alert-primary" [dismissible]="false"&gt; A simple primary alert—check it out! &lt;/ngb-alert&gt;</code>
                                </h6>

                                <div class="alert alert-primary" role="alert"> A simple primary alert—check it out!
                                </div>
                                <div class="alert alert-secondary" role="alert"> A simple secondary alert—check it out!
                                </div>
                                <div class="alert alert-success" role="alert"> A simple success alert—check it out!
                                </div>
                                <div class="alert alert-danger" role="alert"> A simple danger alert—check it out!</div>
                                <div class="alert alert-warning" role="alert"> A simple warning alert—check it out!
                                </div>
                                <div class="alert alert-info" role="alert"> A simple info alert—check it out!</div>
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Alert End -->

                    <!-- Alert Links Start -->
                    <div class="col-12 mt-4 pt-2">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> Alert with Icons </h5>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted mb-4 pb-2">Ex. <code
                                        class="text-danger">&lt;ngb-alert class="alert bg-soft-primary fw-medium" [dismissible]="false"&gt; &lt;i class="uil uil-info-circle fs-5 align-middle me-1"&gt;&lt;/i&gt; An example alert with an icon &lt;/ngb-alert&gt;</code>
                                </h6>

                                <div class="alert bg-soft-primary fw-medium" role="alert"><i
                                        class="uil uil-info-circle fs-5 align-middle me-1"></i> An example alert with an
                                    icon</div>
                                <div class="alert bg-soft-success fw-medium" role="alert"><i
                                        class="uil uil-check-circle fs-5 align-middle me-1"></i> An example success
                                    alert with an icon</div>
                                <div class="alert bg-soft-warning fw-medium" role="alert"><i
                                        class="uil uil-exclamation-triangle fs-5 align-middle me-1"></i> An example
                                    warning alert with an icon</div>
                                <div class="alert bg-soft-danger fw-medium" role="alert"><i
                                        class="uil uil-exclamation-octagon fs-5 align-middle me-1"></i> An example
                                    danger alert with an icon</div>
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Alert Links End -->

                    <!-- Alert Links Start -->
                    <div class="col-12 mt-4 pt-2">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> Alert Links </h5>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted mb-4 pb-2">Ex. <code
                                        class="text-danger">&lt;ngb-alert class="alert alert-primary" [dismissible]="false"&gt; A simple primary alert with &lt;a href="javascript:void(0)" class="alert-link"&gt;&lt;/a&gt;. &lt;/ngb-alert&gt;</code>
                                </h6>

                                <div class="alert alert-primary" role="alert">A simple primary alert with <a
                                        href="javascript:void(0)" class="alert-link">an link</a>.</div>
                                <div class="alert alert-secondary" role="alert">A simple secondary alert with <a
                                        href="javascript:void(0)" class="alert-link">an link</a>.</div>
                                <div class="alert alert-success" role="alert">A simple success alert with <a
                                        href="javascript:void(0)" class="alert-link">an link</a>.</div>
                                <div class="alert alert-danger" role="alert">A simple danger alert with <a
                                        href="javascript:void(0)" class="alert-link">an link</a>.</div>
                                <div class="alert alert-warning" role="alert">A simple warning alert with <a
                                        href="javascript:void(0)" class="alert-link">an link</a>.</div>
                                <div class="alert alert-info" role="alert">A simple info alert with <a
                                        href="javascript:void(0)" class="alert-link">an link</a>.</div>
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Alert Links End -->

                    <!-- Dismissing Alert Start -->
                    <div class="col-12 mt-4 pt-2">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> Dismissing Alert </h5>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted mb-4 pb-2">Ex. <br>
                                    <code class="text-danger">
                                        &lt;ngb-alert&gt; <br>&nbsp; 
                                        &lt;strong&gt;Well done!&lt;/strong&gt; You successfully read this important alert message. <br>&nbsp;
                                        &lt;/ngb-alert&gt; 
                                    </code>
                                </h6>

                                <div class="alert alert-success alert-dismissible fade show" role="alert">
                                    <strong>Well done!</strong> You successfully read this important alert message.
                                    <button type="button" class="btn-close" data-bs-dismiss="alert"
                                        aria-label="Close"></button>
                                </div>

                                <div class="alert alert-info alert-dismissible fade show" role="alert">
                                    <strong>Heads up!</strong> This alert needs your attention, but it's not super
                                    important.
                                    <button type="button" class="btn-close" data-bs-dismiss="alert"
                                        aria-label="Close"></button>
                                </div>

                                <div class="alert alert-warning alert-dismissible fade show" role="alert">
                                    <strong>Holy guacamole!</strong> You should check in on some of those fields below.
                                    <button type="button" class="btn-close" data-bs-dismiss="alert"
                                        aria-label="Close"></button>
                                </div>

                                <div class="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                    <strong>Oh snap! </strong> Change a few things up and try submitting again.
                                    <button type="button" class="btn-close" data-bs-dismiss="alert"
                                        aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Dismissing Alert End -->

          
                </div><!--end row-->
            </div><!--end col-->
        </div>
    </div>
</section>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->