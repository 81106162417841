import { Component } from '@angular/core';

@Component({
  selector: 'app-hosting-dedicated',
  templateUrl: './hosting-dedicated.component.html',
  styleUrls: ['./hosting-dedicated.component.css']
})
export class HostingDedicatedComponent {
  navClass = 'nav-light';
    // Set Topbar Option
  Menuoption = 'hosting';
  Settingicon = true
  footerVariant = 'footer-ten';
}
