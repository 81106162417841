import { Component } from '@angular/core';

@Component({
  selector: 'app-ui-form',
  templateUrl: './ui-form.component.html',
  styleUrls: ['./ui-form.component.css']
})
export class UiFormComponent {
  /**
   * Header button list show
   */
  Settingicon = true;
  Menuoption = 'center'
}
