import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-login-three',
  templateUrl: './auth-login-three.component.html',
  styleUrls: ['./auth-login-three.component.css']
})

/**
 * Auth Login-Three Component
 */
export class AuthLoginThreeComponent implements OnInit {

  year = new Date().getFullYear()
  constructor() { }

  ngOnInit(): void {
  }

}
