<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" style="background-image: url('assets/images/hosting/pages.png');" id="home">
    <div class="bg-overlay bg-gradient-primary opacity-9"></div>
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading title-heading">
                    <h4 class="title text-white title-dark mb-4"> Services </h4>
                    <p class="text-white-50 para-desc mx-auto mb-0">Launch your campaign and benefit from our expertise
                        on designing and managing conversion centered bootstrap v5 html page.</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a routerLink="/index-hosting">Hosting</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Services</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Hero End -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-12">
                <div class="card features feature-primary explore-feature border-0 rounded text-center">
                    <div class="card-body">
                        <span class="icons rounded-circle shadow-lg d-inline-block h4">
                            <i class="uil uil-database-alt"></i>
                        </span>
                        <div class="content mt-3">
                            <h5 class="mb-3"><a href="javascript:void(0)" class="title text-dark">Shared Hosting</a>
                            </h5>
                            <p class="text-muted mb-3">We provide cloud based enterprise hosting, server and storage
                                solutions of unmatched quality.</p>
                            <a href="javascript:void(0)" class="read-more">Read More <i-feather name="chevron-right"
                                    class="fea icon-sm"></i-feather></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="card features feature-primary explore-feature border-0 rounded text-center">
                    <div class="card-body">
                        <span class="icons rounded-circle shadow-lg d-inline-block h4">
                            <i class="uil uil-cell"></i>
                        </span>
                        <div class="content mt-3">
                            <h5 class="mb-3"><a href="javascript:void(0)" class="title text-dark">VPS Hosting</a></h5>
                            <p class="text-muted mb-3">We provide cloud based enterprise hosting, server and storage
                                solutions of unmatched quality.</p>
                            <a href="javascript:void(0)" class="read-more">Read More <i-feather name="chevron-right"
                                    class="fea icon-sm"></i-feather></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card features feature-primary explore-feature border-0 rounded text-center">
                    <div class="card-body">
                        <span class="icons rounded-circle shadow-lg d-inline-block h4">
                            <i class="uil uil-webcam"></i>
                        </span>
                        <div class="content mt-3">
                            <h5 class="mb-3"><a href="javascript:void(0)" class="title text-dark">Dedicated Hosting</a>
                            </h5>
                            <p class="text-muted mb-3">We provide cloud based enterprise hosting, server and storage
                                solutions of unmatched quality.</p>
                            <a href="javascript:void(0)" class="read-more">Read More <i-feather name="chevron-right"
                                    class="fea icon-sm"></i-feather></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card features feature-primary explore-feature border-0 rounded text-center">
                    <div class="card-body">
                        <span class="icons rounded-circle shadow-lg d-inline-block h4">
                            <i class="uil uil-cloud-heart"></i>
                        </span>
                        <div class="content mt-3">
                            <h5 class="mb-3"><a href="javascript:void(0)" class="title text-dark">Cloud Hosting</a></h5>
                            <p class="text-muted mb-3">We provide cloud based enterprise hosting, server and storage
                                solutions of unmatched quality.</p>
                            <a href="javascript:void(0)" class="read-more">Read More <i-feather name="chevron-right"
                                    class="fea icon-sm"></i-feather></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card features feature-primary explore-feature border-0 rounded text-center">
                    <div class="card-body">
                        <span class="icons rounded-circle shadow-lg d-inline-block h4">
                            <i class="uil uil-cloud-upload"></i>
                        </span>
                        <div class="content mt-3">
                            <h5 class="mb-3"><a href="javascript:void(0)" class="title text-dark">Reseller Hosting</a>
                            </h5>
                            <p class="text-muted mb-3">We provide cloud based enterprise hosting, server and storage
                                solutions of unmatched quality.</p>
                            <a href="javascript:void(0)" class="read-more">Read More <i-feather name="chevron-right"
                                    class="fea icon-sm"></i-feather></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card features feature-primary explore-feature border-0 rounded text-center">
                    <div class="card-body">
                        <span class="icons rounded-circle shadow-lg d-inline-block h4">
                            <i class="uil uil-server"></i>
                        </span>
                        <div class="content mt-3">
                            <h5 class="mb-3"><a href="javascript:void(0)" class="title text-dark">Reseller Hosting</a>
                            </h5>
                            <p class="text-muted mb-3">We provide cloud based enterprise hosting, server and storage
                                solutions of unmatched quality.</p>
                            <a href="javascript:void(0)" class="read-more">Read More <i-feather name="chevron-right"
                                    class="fea icon-sm"></i-feather></a>
                        </div>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->