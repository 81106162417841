<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> Accordions </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Components</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Accordions</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 d-md-block d-none">
                <app-component-sidebar></app-component-sidebar>
            </div>

            <div class="col-lg-9 col-md-8 col-12">
                <div class="row">
                    <!-- Accordions Start -->
                    <div class="col-12">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0">Accordions</h5>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted mb-2 pb-2">Ex.
                                    <pre><code class="text-danger">
&lt;div class="accordion" id="buyingquestion"&gt;
&lt;div class="accordion-item rounded"&gt;
    &lt;h2 class="accordion-header" id="headingOne"&gt;
        &lt;button class="accordion-button border-0 bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
            aria-expanded="true" aria-controls="collapseOne"&gt;
            How does it work ?
        &lt;/button&gt;
    &lt;/h2&gt;
    &lt;div id="collapseOne" class="accordion-collapse border-0 collapse show" aria-labelledby="headingOne"
        data-bs-parent="#buyingquestion"&gt;
        &lt;div class="accordion-body text-muted"&gt;
            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.
        &lt;/div&gt;
    &lt;/div&gt;
&lt;/div&gt;

&lt;div class="accordion-item rounded mt-2"&gt;
    &lt;h2 class="accordion-header" id="headingTwo"&gt;
        &lt;button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
            aria-expanded="false" aria-controls="collapseTwo"&gt;
            Do I need a designer to use Landrick ?
        &lt;/button&gt;
    &lt;/h2&gt;
    &lt;div id="collapseTwo" class="accordion-collapse border-0 collapse" aria-labelledby="headingTwo"
        data-bs-parent="#buyingquestion"&gt;
        &lt;div class="accordion-body text-muted"&gt;
            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.
        &lt;/div&gt;
    &lt;/div&gt;
&lt;/div&gt;
&lt;/div&gt;</code></pre>
                                </h6>

                                <!-- <div class="accordion" id="buyingquestion">
                                    <div class="accordion-item rounded">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button class="accordion-button border-0 bg-light" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                aria-expanded="true" aria-controls="collapseOne">
                                                How does it work ?
                                            </button>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse border-0 collapse show"
                                            aria-labelledby="headingOne" data-bs-parent="#buyingquestion">
                                            <div class="accordion-body text-muted">
                                                There are many variations of passages of Lorem Ipsum available, but
                                                the majority have suffered alteration in some form.
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item rounded mt-2">
                                        <h2 class="accordion-header" id="headingTwo">
                                            <button class="accordion-button border-0 bg-light collapsed"
                                                type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseTwo" aria-expanded="false"
                                                aria-controls="collapseTwo">
                                                Do I need a designer to use Landrick ?
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse border-0 collapse"
                                            aria-labelledby="headingTwo" data-bs-parent="#buyingquestion">
                                            <div class="accordion-body text-muted">
                                                There are many variations of passages of Lorem Ipsum available, but
                                                the majority have suffered alteration in some form.
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item rounded mt-2">
                                        <h2 class="accordion-header" id="headingThree">
                                            <button class="accordion-button border-0 bg-light collapsed"
                                                type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseThree" aria-expanded="false"
                                                aria-controls="collapseThree">
                                                What do I need to do to start selling ?
                                            </button>
                                        </h2>
                                        <div id="collapseThree" class="accordion-collapse border-0 collapse"
                                            aria-labelledby="headingThree" data-bs-parent="#buyingquestion">
                                            <div class="accordion-body text-muted">
                                                There are many variations of passages of Lorem Ipsum available, but
                                                the majority have suffered alteration in some form.
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item rounded mt-2">
                                        <h2 class="accordion-header" id="headingFour">
                                            <button class="accordion-button border-0 bg-light collapsed"
                                                type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseFour" aria-expanded="false"
                                                aria-controls="collapseFour">
                                                What happens when I receive an order ?
                                            </button>
                                        </h2>
                                        <div id="collapseFour" class="accordion-collapse border-0 collapse"
                                            aria-labelledby="headingFour" data-bs-parent="#buyingquestion">
                                            <div class="accordion-body text-muted">
                                                There are many variations of passages of Lorem Ipsum available, but
                                                the majority have suffered alteration in some form.
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
                                    <ngb-panel id="ngb-panel-0" cardClass="border-0 rounded shadow mb-2">
                                      <ng-template ngbPanelTitle>
                                        <div class="border-0 bg-light" id="headingone">
                                          <h6 class="title mb-0">  How does it work ? </h6>
                                        </div>
                                      </ng-template>
                                      <ng-template ngbPanelContent>
                                        <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but
                                            the majority have suffered alteration in some form.</p>
                                      </ng-template>
                                    </ngb-panel>
                                    <ngb-panel cardClass="border-0 rounded shadow mb-2">
                                      <ng-template ngbPanelTitle>
                                        <div class="border-0 bg-light" id="headingtwo">
                                          <h6 class="title mb-0"> Do I need a designer to use Landrick ? </h6>
                                        </div>
                                      </ng-template>
                                      <ng-template ngbPanelContent>
                                        <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but
                                            the majority have suffered alteration in some form.</p>
                                      </ng-template>
                                    </ngb-panel>
                                    <ngb-panel cardClass="border-0 rounded shadow mb-2">
                                      <ng-template ngbPanelTitle>
                                        <div class="border-0 bg-light" id="headingthree">
                                          <h6 class="title mb-0"> What do I need to do to start selling ? </h6>
                                        </div>
                                      </ng-template>
                                      <ng-template ngbPanelContent>
                                        <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but
                                            the majority have suffered alteration in some form.</p>
                                      </ng-template>
                                    </ngb-panel>
                                    <ngb-panel cardClass="border-0 rounded shadow mb-2">
                                        <ng-template ngbPanelTitle>
                                          <div class="border-0 bg-light" id="headingthree">
                                            <h6 class="title mb-0"> What happens when I receive an order ? </h6>
                                          </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                          <p class="text-muted mb-0 faq-ans"> There are many variations of passages of Lorem Ipsum available, but
                                            the majority have suffered alteration in some form.</p>
                                        </ng-template>
                                      </ngb-panel>
                                  </ngb-accordion>
                         </div>
                        </div>
                    </div>
                    <!--end col-->
                    <!-- Accordions End -->
                </div>
                <!--end row-->
            </div>
            <!--end col-->
        </div>
    </div>
</section>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->

