        <!-- Hero Start -->
        <section class="bg-half-170 bg-light d-table w-100" id="home">
            <div class="container">
                <div class="row mt-5 justify-content-center">
                    <div class="col-lg-12 text-center">
                        <div class="pages-heading">
                            <h4 class="title mb-0"> About Us </h4>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
                
                <div class="position-breadcrumb">
                    <nav aria-label="breadcrumb" class="d-inline-block">
                        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                            <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                            <li class="breadcrumb-item"><a routerLink="/index-shop">Shop</a></li>
                            <li class="breadcrumb-item active" aria-current="page">About</li>
                        </ul>
                    </nav>
                </div>
            </div> <!--end container-->
        </section><!--end section-->
        <div class="position-relative">
            <div class="shape overflow-hidden text-color-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <!-- Hero End -->

        <!-- Start -->
        <section class="section">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-9">
                        <div class="section-title text-center">
                            <h4 class="title mb-4">Aliquam viverra tellus a urna facilisis bibendum</h4>
                            <p class="text-muted mb-0">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->

            <div class="container mt-100 mt-60">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <div class="section-title text-center mb-4 pb-2">
                            <h4 class="title mb-4">Featured Services</h4>
                            <p class="text-muted para-desc mx-auto mb-0">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->

                <div class="row">
                    <div class="col-md-4 mt-4 pt-2">
                        <div class="card features feature-primary feature-clean bg-transparent process-arrow border-0 text-center">
                            <div class="icons text-center mx-auto">
                                <i class="uil uil-rocket d-block rounded h3 mb-0"></i>
                            </div>

                            <div class="card-body">
                                <h5 class="text-dark">Free Shipping</h5>
                                <p class="text-muted mb-0">The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated</p>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-md-4 mt-4 pt-2">
                        <div class="card features feature-primary feature-clean bg-transparent process-arrow border-0 text-center">
                            <div class="icons text-center mx-auto">
                                <i class="uil uil-user-arrows d-block rounded h3 mb-0"></i>
                            </div>

                            <div class="card-body">
                                <h5 class="text-dark">24/7 Support</h5>
                                <p class="text-muted mb-0">The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated</p>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-md-4 mt-4 pt-2">
                        <div class="card features feature-primary feature-clean bg-transparent process-arrow border-0 text-center">
                            <div class="icons text-center mx-auto">
                                <i class="uil uil-transaction d-block rounded h3 mb-0"></i>
                            </div>

                            <div class="card-body">
                                <h5 class="text-dark">Payment Process</h5>
                                <p class="text-muted mb-0">The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated</p>
                            </div>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->

            <div class="container mt-100 mt-60">
                <div class="row">
                    <div class="col-md-6">
                        <div class="card shop-features border-0 rounded overflow-hidden">
                            <img src="assets/images/shop/fea1.jpg" class="img-fluid" alt="">
                            <div class="category-title ms-md-4 ms-2">
                                <h4>Street style has its own <br> rules</h4>
                                <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary mt-2">Shop Now</a>
                            </div>
                        </div>
                    </div><!--end col-->
    
                    <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div class="card shop-features border-0 rounded overflow-hidden">
                            <img src="assets/images/shop/fea2.jpg" class="img-fluid" alt="">
                            <div class="category-title ms-md-4 ms-2">
                                <h4>Old style in a new <br> edition</h4>
                                <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary mt-2">Shop Now</a>
                            </div>
                        </div>
                    </div><!--end col-->
    
                    <div class="col-md-12 mt-4 pt-2">
                        <div class="card shop-features border-0 rounded overflow-hidden">
                            <img src="assets/images/shop/fea4.jpg" class="img-fluid" alt="">
                            <div class="category-title ms-md-4 ms-2">
                                <h4>Summer Collection</h4>
                                <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary mt-2">Shop Now</a>
                            </div>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- End -->

        <!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->
