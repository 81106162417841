        <!-- Hero Start -->
        <section class="bg-half-170 bg-light d-table w-100" id="home">
            <div class="container">
                <div class="row mt-5 justify-content-center">
                    <div class="col-lg-12 text-center">
                        <div class="pages-heading">
                            <h4 class="title mb-0"> Blogs & News </h4>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
                
                <div class="position-breadcrumb">
                    <nav aria-label="breadcrumb" class="d-inline-block">
                        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                            <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                            <li class="breadcrumb-item"><a routerLink="/index-shop">Shop</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Blogs</li>
                        </ul>
                    </nav>
                </div>
            </div> <!--end container-->
        </section><!--end section-->
        <div class="position-relative">
            <div class="shape overflow-hidden text-color-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <!-- Hero End -->

        <!-- Start -->
        <section class="section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6 mb-4 pb-2">
                        <div class="card blog blog-primary border-0 shadow rounded overflow-hidden">
                            <div class="position-relative overflow-hidden">
                                <img src="assets/images/blog/01.jpg" class="img-fluid" alt="">
                                <div class="position-absolute top-0 start-0 mt-2 ms-2">
                                    <a href="javascript:void(0)" class="badge badge-link bg-primary">Men</a>
                                </div>
                            </div>
                            <div class="card-body content">
                                <ul class="list-unstyled mb-2">
                                    <li class="list-inline-item text-muted small me-3"><i class="uil uil-calendar-alt text-dark h6 me-1"></i>20th September, 2021</li>
                                    <li class="list-inline-item text-muted small"><i class="uil uil-clock text-dark h6 me-1"></i>5 min read</li>
                                </ul>
                                <a routerLink="/shop-blog-detail" class="text-dark title h5">A team of designers that make dreams come true</a>
                                
                                <div class="mt-2">
                                    <a routerLink="/shop-blog-detail" class="text-primary">Read more <i-feather name="arrow-right" class="fea icon-sm"></i-feather></a>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-4 col-md-6 mb-4 pb-2">
                        <div class="card blog blog-primary border-0 shadow rounded overflow-hidden">
                            <div class="position-relative overflow-hidden">
                                <img src="assets/images/blog/02.jpg" class="img-fluid" alt="">
                                <div class="position-absolute top-0 start-0 mt-2 ms-2">
                                    <a href="javascript:void(0)" class="badge badge-link bg-primary">Woman</a>
                                </div>
                            </div>
                            <div class="card-body content">
                                <ul class="list-unstyled mb-2">
                                    <li class="list-inline-item text-muted small me-3"><i class="uil uil-calendar-alt text-dark h6 me-1"></i>20th September, 2021</li>
                                    <li class="list-inline-item text-muted small"><i class="uil uil-clock text-dark h6 me-1"></i>5 min read</li>
                                </ul>
                                <a routerLink="/shop-blog-detail" class="text-dark title h5">The best ways to change your summer</a>
                                
                                <div class="mt-2">
                                    <a routerLink="/shop-blog-detail" class="text-primary">Read more <i-feather name="arrow-right" class="fea icon-sm"></i-feather></a>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-4 col-md-6 mb-4 pb-2">
                        <div class="card blog blog-primary border-0 shadow rounded overflow-hidden">
                            <div class="position-relative overflow-hidden">
                                <img src="assets/images/blog/03.jpg" class="img-fluid" alt="">
                                <div class="position-absolute top-0 start-0 mt-2 ms-2">
                                    <a href="javascript:void(0)" class="badge badge-link bg-primary">Kids</a>
                                </div>
                            </div>
                            <div class="card-body content">
                                <ul class="list-unstyled mb-2">
                                    <li class="list-inline-item text-muted small me-3"><i class="uil uil-calendar-alt text-dark h6 me-1"></i>20th September, 2021</li>
                                    <li class="list-inline-item text-muted small"><i class="uil uil-clock text-dark h6 me-1"></i>5 min read</li>
                                </ul>
                                <a routerLink="/shop-blog-detail" class="text-dark title h5">Men’s fashion & shopping in leather</a>
                                
                                <div class="mt-2">
                                    <a routerLink="/shop-blog-detail" class="text-primary">Read more <i-feather name="arrow-right" class="fea icon-sm"></i-feather></a>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-4 col-md-6 mb-4 pb-2">
                        <div class="card blog blog-primary border-0 shadow rounded overflow-hidden">
                            <div class="position-relative overflow-hidden">
                                <img src="assets/images/blog/04.jpg" class="img-fluid" alt="">
                                <div class="position-absolute top-0 start-0 mt-2 ms-2">
                                    <a href="javascript:void(0)" class="badge badge-link bg-primary">Tech</a>
                                </div>
                            </div>
                            <div class="card-body content">
                                <ul class="list-unstyled mb-2">
                                    <li class="list-inline-item text-muted small me-3"><i class="uil uil-calendar-alt text-dark h6 me-1"></i>20th September, 2021</li>
                                    <li class="list-inline-item text-muted small"><i class="uil uil-clock text-dark h6 me-1"></i>5 min read</li>
                                </ul>
                                <a routerLink="/shop-blog-detail" class="text-dark title h5">A team of designers that make dreams come true</a>
                                
                                <div class="mt-2">
                                    <a routerLink="/shop-blog-detail" class="text-primary">Read more <i-feather name="arrow-right" class="fea icon-sm"></i-feather></a>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-4 col-md-6 mb-4 pb-2">
                        <div class="card blog blog-primary border-0 shadow rounded overflow-hidden">
                            <div class="position-relative overflow-hidden">
                                <img src="assets/images/blog/05.jpg" class="img-fluid" alt="">
                                <div class="position-absolute top-0 start-0 mt-2 ms-2">
                                    <a href="javascript:void(0)" class="badge badge-link bg-primary">Mobile</a>
                                </div>
                            </div>
                            <div class="card-body content">
                                <ul class="list-unstyled mb-2">
                                    <li class="list-inline-item text-muted small me-3"><i class="uil uil-calendar-alt text-dark h6 me-1"></i>20th September, 2021</li>
                                    <li class="list-inline-item text-muted small"><i class="uil uil-clock text-dark h6 me-1"></i>5 min read</li>
                                </ul>
                                <a routerLink="/shop-blog-detail" class="text-dark title h5">The best ways to change your summer</a>
                                
                                <div class="mt-2">
                                    <a routerLink="/shop-blog-detail" class="text-primary">Read more <i-feather name="arrow-right" class="fea icon-sm"></i-feather></a>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-4 col-md-6 mb-4 pb-2">
                        <div class="card blog blog-primary border-0 shadow rounded overflow-hidden">
                            <div class="position-relative overflow-hidden">
                                <img src="assets/images/blog/06.jpg" class="img-fluid" alt="">
                                <div class="position-absolute top-0 start-0 mt-2 ms-2">
                                    <a href="javascript:void(0)" class="badge badge-link bg-primary">Laptop</a>
                                </div>
                            </div>
                            <div class="card-body content">
                                <ul class="list-unstyled mb-2">
                                    <li class="list-inline-item text-muted small me-3"><i class="uil uil-calendar-alt text-dark h6 me-1"></i>20th September, 2021</li>
                                    <li class="list-inline-item text-muted small"><i class="uil uil-clock text-dark h6 me-1"></i>5 min read</li>
                                </ul>
                                <a routerLink="/shop-blog-detail" class="text-dark title h5">A team of designers that make dreams come true</a>
                                
                                <div class="mt-2">
                                    <a routerLink="/shop-blog-detail" class="text-primary">Read more <i-feather name="arrow-right" class="fea icon-sm"></i-feather></a>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-4 col-md-6 mb-4 pb-2">
                        <div class="card blog blog-primary border-0 shadow rounded overflow-hidden">
                            <div class="position-relative overflow-hidden">
                                <img src="assets/images/blog/07.jpg" class="img-fluid" alt="">
                                <div class="position-absolute top-0 start-0 mt-2 ms-2">
                                    <a href="javascript:void(0)" class="badge badge-link bg-primary">Accessories</a>
                                </div>
                            </div>
                            <div class="card-body content">
                                <ul class="list-unstyled mb-2">
                                    <li class="list-inline-item text-muted small me-3"><i class="uil uil-calendar-alt text-dark h6 me-1"></i>20th September, 2021</li>
                                    <li class="list-inline-item text-muted small"><i class="uil uil-clock text-dark h6 me-1"></i>5 min read</li>
                                </ul>
                                <a routerLink="/shop-blog-detail" class="text-dark title h5">A team of designers that make dreams come true</a>
                                
                                <div class="mt-2">
                                    <a routerLink="/shop-blog-detail" class="text-primary">Read more <i-feather name="arrow-right" class="fea icon-sm"></i-feather></a>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                    
                    <div class="col-lg-4 col-md-6 mb-4 pb-2">
                        <div class="card blog blog-primary border-0 shadow rounded overflow-hidden">
                            <div class="position-relative overflow-hidden">
                                <img src="assets/images/blog/08.jpg" class="img-fluid" alt="">
                                <div class="position-absolute top-0 start-0 mt-2 ms-2">
                                    <a href="javascript:void(0)" class="badge badge-link bg-primary">Sports</a>
                                </div>
                            </div>
                            <div class="card-body content">
                                <ul class="list-unstyled mb-2">
                                    <li class="list-inline-item text-muted small me-3"><i class="uil uil-calendar-alt text-dark h6 me-1"></i>20th September, 2021</li>
                                    <li class="list-inline-item text-muted small"><i class="uil uil-clock text-dark h6 me-1"></i>5 min read</li>
                                </ul>
                                <a routerLink="/shop-blog-detail" class="text-dark title h5">The best ways to change your summer</a>
                                
                                <div class="mt-2">
                                    <a routerLink="/shop-blog-detail" class="text-primary">Read more <i-feather name="arrow-right" class="fea icon-sm"></i-feather></a>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->

                    <!-- PAGINATION START -->
                    <div class="col-12">
                        <ul class="pagination justify-content-center mb-0">
                            <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Previous">Prev</a></li>
                            <li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Next">Next</a></li>
                        </ul>
                    </div><!--end col-->
                    <!-- PAGINATION END -->
                </div><!--end row-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- End -->

        <!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->

       