import { Component } from '@angular/core';

@Component({
  selector: 'app-crypto-whitepaper',
  templateUrl: './crypto-whitepaper.component.html',
  styleUrls: ['./crypto-whitepaper.component.css']
})
export class CryptoWhitepaperComponent {
// Set Topbar
Menuoption = 'cryptocurrency'

  footerVariant = 'footer-three';
}
