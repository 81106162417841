<!-- Hero Start -->
<section class="bg-half-170 bg-primary d-table w-100" style="background-image: url('assets/images/job/bg.png');" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h2 class="title text-white title-dark mb-0"> Job Apply Form </h2>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a routerLink="/index-job">Careers</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Job Apply</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<!-- Job apply form Start -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-7">
                <div class="card custom-form border-0">
                    <div class="card-body">
                        <form class="rounded shadow p-4">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Your Name :<span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                            <input name="name" id="name2" type="text" class="form-control ps-5"
                                                placeholder="First Name :">
                                        </div>
                                    </div>
                                </div><!--end col-->
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Your Email :<span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                            <input name="email" id="email2" type="email" class="form-control ps-5"
                                                placeholder="Your email :">
                                        </div>
                                    </div>
                                </div><!--end col-->
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Your Phone no. :<span
                                                class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="phone" class="fea icon-sm icons"></i-feather>
                                            <input name="number" id="number2" type="number" class="form-control ps-5"
                                                placeholder="Your phone no. :">
                                        </div>
                                    </div>
                                </div><!--end col-->
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Job Title :</label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="book" class="fea icon-sm icons"></i-feather>
                                            <input name="subject" id="subject2" class="form-control ps-5"
                                                placeholder="Title :">
                                        </div>
                                    </div>
                                </div><!--end col-->
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Types of jobs :</label>
                                        <select class="form-control form-select" id="Sortbylist-Shop">
                                            <option>All Jobs</option>
                                            <option>Full Time</option>
                                            <option>Half Time</option>
                                            <option>Remote</option>
                                            <option>In Office</option>
                                        </select>
                                    </div>
                                </div><!--end col-->
                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <label class="form-label">Description :</label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="message-circle" class="fea icon-sm icons"></i-feather>
                                            <textarea name="comments" id="comments2" rows="4" class="form-control ps-5"
                                                placeholder="Describe the job :"></textarea>
                                        </div>
                                    </div>
                                </div><!--end col-->
                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <label for="formFile" class="form-label">Upload Your Cv / Resume :</label>
                                        <input class="form-control" type="file" id="formFile">
                                    </div>
                                </div><!--end col-->
                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value=""
                                                id="flexCheckDefault">
                                            <label class="form-check-label" for="flexCheckDefault">I Accept <a href="javascript:void(0)"
                                                    class="text-primary">Terms And Condition</a></label>
                                        </div>
                                    </div>
                                </div><!--end col-->
                            </div><!--end row-->
                            <div class="row">
                                <div class="col-sm-12">
                                    <input type="submit" id="submit2" name="send" class="submitBnt btn btn-primary"
                                        value="Apply Now">
                                </div><!--end col-->
                            </div><!--end row-->
                        </form><!--end form-->
                    </div>
                </div><!--end custom-form-->
            </div>
        </div>
    </div><!--end container-->
</section><!--end section-->
<!-- Job apply form End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->