<!-- Start -->
<section class="bg-half-170 d-table bg-primary w-100" id="home"
    style="background-image: url('assets/images/hosting/domain.png');">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col">
                <div class="pages-heading title-heading text-center">
                    <h4 class="display-6 text-white title-dark mb-4 fw-bold">Find a Great Domain</h4>
                    <p class="text-white-50 para-desc mx-auto mb-0">Launch your campaign and benefit from our expertise
                        on designing and managing conversion centered bootstrap v5 html page.</p>

                    <div class="text-center subcribe-form mt-4 pt-2">
                        <form>
                            <input type="text" id="text" name="text" class="rounded-pill bg-white-50"
                                placeholder="Search your domain name...">
                            <button type="submit" class="btn btn-pills btn-primary">Search <i
                                    class="uil uil-arrow-right"></i></button>
                        </form><!--end form-->
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->
<!-- End -->

<!-- Start -->
<section class="section pt-4">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10">
                <div class="row">
                    <div class="col-lg-2 col-md-4 col-6">
                        <a href="javascript:void(0)" class="text-dark">
                            <div class="rounded shadow p-1 text-center">
                                <h6 class="mb-0">.in <br><span class="text-primary">$4.99</span>/year</h6>
                            </div>
                        </a>
                    </div><!--end col-->

                    <div class="col-lg-2 col-md-4 col-6">
                        <a href="javascript:void(0)" class="text-dark">
                            <div class="rounded shadow p-1 text-center">
                                <h6 class="mb-0">.com <br><span class="text-primary">$5.99</span>/year</h6>
                            </div>
                        </a>
                    </div><!--end col-->

                    <div class="col-lg-2 col-md-4 col-6 mt-4 mt-sm-0">
                        <a href="javascript:void(0)" class="text-dark">
                            <div class="rounded shadow p-1 text-center">
                                <h6 class="mb-0">.org <br><span class="text-primary">$6.99</span>/year</h6>
                            </div>
                        </a>
                    </div><!--end col-->

                    <div class="col-lg-2 col-md-4 col-6 mt-4 mt-lg-0">
                        <a href="javascript:void(0)" class="text-dark">
                            <div class="rounded shadow p-1 text-center">
                                <h6 class="mb-0">.net <br><span class="text-primary">$7.99</span>/year</h6>
                            </div>
                        </a>
                    </div><!--end col-->

                    <div class="col-lg-2 col-md-4 col-6 mt-4 mt-lg-0">
                        <a href="javascript:void(0)" class="text-dark">
                            <div class="rounded shadow p-1 text-center">
                                <h6 class="mb-0">.info <br><span class="text-primary">$3.99</span>/year</h6>
                            </div>
                        </a>
                    </div><!--end col-->

                    <div class="col-lg-2 col-md-4 col-6 mt-4 mt-lg-0">
                        <a href="javascript:void(0)" class="text-dark">
                            <div class="rounded shadow p-1 text-center">
                                <h6 class="mb-0">.me <br><span class="text-primary">$2.99</span>/year</h6>
                            </div>
                        </a>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h4 class="title mb-3">Why choose Landrick ?</h4>
                    <p class="text-muted para-desc mx-auto mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row">
            <div class="col-md-4 col-12 mt-4 pt-2">
                <div
                    class="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
                    <span class="h1 icon-color">
                        <i class="uil uil-shield-check"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Safe & Secured</h5>
                        <p class="para text-muted mb-0">If the distribution of letters and 'words' is random, the reader
                            from making a neutral judgement</p>
                    </div>
                    <div class="position-absolute top-0 end-0">
                        <i class="uil uil-shield-check display-1 opacity-05"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-md-4 col-12 mt-4 pt-2">
                <div
                    class="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
                    <span class="h1 icon-color">
                        <i class="uil uil-invoice"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Money Back Guarentee</h5>
                        <p class="para text-muted mb-0">If the distribution of letters and 'words' is random, the reader
                            from making a neutral judgement</p>
                    </div>
                    <div class="position-absolute top-0 end-0">
                        <i class="uil uil-invoice display-1 opacity-05"></i>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-md-4 col-12 mt-4 pt-2">
                <div
                    class="card features feature-primary feature-full-bg rounded p-4 bg-light position-relative overflow-hidden border-0">
                    <span class="h1 icon-color">
                        <i class="uil uil-user-check"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Dedicated Supports</h5>
                        <p class="para text-muted mb-0">If the distribution of letters and 'words' is random, the reader
                            from making a neutral judgement</p>
                    </div>
                    <div class="position-absolute top-0 end-0">
                        <i class="uil uil-user-check display-1 opacity-05"></i>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->
<!-- End -->

<!-- Pricing Start -->
<section class="section bg-primary">
    <div class="container">
        <div class="row mb-md-4 pb-md-2 justify-content-center">
            <div class="col-12">
                <div class="section-title text-center pb-md-5">
                    <h4 class="title text-white title-dark mb-3">Pricing Rates or Tables</h4>
                    <p class="text-white-50 para-desc mx-auto mb-0">Start working with Landrick that can provide
                        everything you need to generate awareness, drive traffic, connect.</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end price-->

<section class="section" id="price">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 mt-md-5 pt-md-3 mt-lg-0 pt-lg-0">
                <div class="table-responsive bg-white shadow rounded features-absolute">
                    <table class="table mb-0 table-center">
                        <thead>
                            <tr class="bg-light">
                                <th scope="col" class="border-bottom py-4 px-3" style="min-width: 250px;">Domain Name
                                </th>
                                <th scope="col" class="border-bottom py-4 px-3 text-center" style="min-width: 80px;">
                                    Registration</th>
                                <th scope="col" class="border-bottom py-4 px-3 text-center" style="min-width: 80px;">2
                                    Year</th>
                                <th scope="col" class="border-bottom py-4 px-3 text-center" style="min-width: 80px;">
                                    Renew</th>
                                <th scope="col" class="border-bottom py-4 px-3 text-end" style="min-width: 100px;">Click
                                    Here</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th class="p-3">.com</th>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-end"><a href="javascript:void(0)"
                                        class="btn btn-sm btn-soft-primary">Buy Now</a></td>
                            </tr>

                            <tr>
                                <th class="p-3">.in</th>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-end"><a href="javascript:void(0)"
                                        class="btn btn-sm btn-soft-primary">Buy Now</a></td>
                            </tr>

                            <tr>
                                <th class="p-3">.online</th>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-end"><a href="javascript:void(0)"
                                        class="btn btn-sm btn-soft-primary">Buy Now</a></td>
                            </tr>

                            <tr>
                                <th class="p-3">.net</th>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-end"><a href="javascript:void(0)"
                                        class="btn btn-sm btn-soft-primary">Buy Now</a></td>
                            </tr>

                            <tr>
                                <th class="p-3">.co</th>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-end"><a href="javascript:void(0)"
                                        class="btn btn-sm btn-soft-primary">Buy Now</a></td>
                            </tr>

                            <tr>
                                <th class="p-3">.live</th>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-end"><a href="javascript:void(0)"
                                        class="btn btn-sm btn-soft-primary">Buy Now</a></td>
                            </tr>

                            <tr>
                                <th class="p-3">.xyz</th>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-end"><a href="javascript:void(0)"
                                        class="btn btn-sm btn-soft-primary">Buy Now</a></td>
                            </tr>

                            <tr>
                                <th class="p-3">.org</th>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-end"><a href="javascript:void(0)"
                                        class="btn btn-sm btn-soft-primary">Buy Now</a></td>
                            </tr>

                            <tr>
                                <th class="p-3">.club</th>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-end"><a href="javascript:void(0)"
                                        class="btn btn-sm btn-soft-primary">Buy Now</a></td>
                            </tr>

                            <tr>
                                <th class="p-3">.me</th>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-end"><a href="javascript:void(0)"
                                        class="btn btn-sm btn-soft-primary">Buy Now</a></td>
                            </tr>

                            <tr>
                                <th class="p-3">.today</th>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-end"><a href="javascript:void(0)"
                                        class="btn btn-sm btn-soft-primary">Buy Now</a></td>
                            </tr>

                            <tr>
                                <th class="p-3">.life</th>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-end"><a href="javascript:void(0)"
                                        class="btn btn-sm btn-soft-primary">Buy Now</a></td>
                            </tr>

                            <tr>
                                <th class="p-3">.shop</th>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-end"><a href="javascript:void(0)"
                                        class="btn btn-sm btn-soft-primary">Buy Now</a></td>
                            </tr>

                            <tr>
                                <th class="p-3">.store</th>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-end"><a href="javascript:void(0)"
                                        class="btn btn-sm btn-soft-primary">Buy Now</a></td>
                            </tr>

                            <tr>
                                <th class="p-3">.solution</th>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-center">$ 3.99</td>
                                <td class="p-3 text-end"><a href="javascript:void(0)"
                                        class="btn btn-sm btn-soft-primary">Buy Now</a></td>
                            </tr>
                        </tbody>
                    </table><!--end table-->
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h6>We believe in building each other and hence</h6>
                    <h4 class="title mb-4">Work with some amazing partners</h4>
                    <p class="text-muted para-desc mx-auto mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row">
            <div class="col-12 mt-4">
                <owl-carousel-o id="customer-testi" [options]="customOptions">
                    <ng-template carouselSlide>
                        <div class="tiny-slide text-center">
                            <div class="client-testi rounded shadow m-2 p-4">
                                <img src="assets/images/client/amazon.svg" class="img-fluid avatar avatar-ex-sm mx-auto"
                                    alt="">
                                <p class="text-muted mt-4">" It seems that only fragments of the original text remain in
                                    the
                                    Lorem Ipsum texts used today. "</p>
                                <h6 class="text-primary">- Thomas Israel</h6>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template carouselSlide>
                        <div class="tiny-slide text-center">
                            <div class="client-testi rounded shadow m-2 p-4">
                                <img src="assets/images/client/google.svg" class="img-fluid avatar avatar-ex-sm mx-auto"
                                    alt="">
                                <p class="text-muted mt-4">" The most well-known dummy text is the 'Lorem Ipsum', which
                                    is
                                    said to have originated in the 16th century. "</p>
                                <h6 class="text-primary">- Carl Oliver</h6>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template carouselSlide>
                        <div class="tiny-slide text-center">
                            <div class="client-testi rounded shadow m-2 p-4">
                                <img src="assets/images/client/lenovo.svg" class="img-fluid avatar avatar-ex-sm mx-auto"
                                    alt="">
                                <p class="text-muted mt-4">" One disadvantage of Lorum Ipsum is that in Latin certain
                                    letters appear more frequently than others. "</p>
                                <h6 class="text-primary">- Barbara McIntosh</h6>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template carouselSlide>
                        <div class="tiny-slide text-center">
                            <div class="client-testi rounded shadow m-2 p-4">
                                <img src="assets/images/client/paypal.svg" class="img-fluid avatar avatar-ex-sm mx-auto"
                                    alt="">
                                <p class="text-muted mt-4">" Thus, Lorem Ipsum has only limited suitability as a visual
                                    filler for German texts. "</p>
                                <h6 class="text-primary">- Jill Webb</h6>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template carouselSlide>
                        <div class="tiny-slide text-center">
                            <div class="client-testi rounded shadow m-2 p-4">
                                <img src="assets/images/client/shopify.svg"
                                    class="img-fluid avatar avatar-ex-sm mx-auto" alt="">
                                <p class="text-muted mt-4">" There is now an abundance of readable dummy texts. These
                                    are
                                    usually used when a text is required. "</p>
                                <h6 class="text-primary">- Dean Tolle</h6>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template carouselSlide>
                        <div class="tiny-slide text-center">
                            <div class="client-testi rounded shadow m-2 p-4">
                                <img src="assets/images/client/spotify.svg"
                                    class="img-fluid avatar avatar-ex-sm mx-auto" alt="">
                                <p class="text-muted mt-4">" According to most sources, Lorum Ipsum can be traced back
                                    to a
                                    text composed by Cicero. "</p>
                                <h6 class="text-primary">- Christa Smith</h6>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-center mb-4 pb-2">
            <div class="col-md-6">
                <div class="section-title">
                    <h4 class="title mb-md-0 mb-4">Frequently asked <br> questions</h4>
                </div>
            </div>

            <div class="col-md-6">
                <div class="section-title">
                    <p class="text-muted para-desc mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-help-circle fea icon-ex-md text-primary me-2 mt-1">
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                    </svg>
                    <div class="flex-1">
                        <h5 class="mt-0">How our <span class="text-primary">Landrick</span> work ?</h5>
                        <p class="answer text-muted mb-0">Due to its widespread use as filler text for layouts,
                            non-readability is of great importance: human perception is tuned to recognize certain
                            patterns and repetitions in texts.</p>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-help-circle fea icon-ex-md text-primary me-2 mt-1">
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                    </svg>
                    <div class="flex-1">
                        <h5 class="mt-0"> What is the main process open account ?</h5>
                        <p class="answer text-muted mb-0">If the distribution of letters and 'words' is random, the
                            reader will not be distracted from making a neutral judgement on the visual impact</p>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-help-circle fea icon-ex-md text-primary me-2 mt-1">
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                    </svg>
                    <div class="flex-1">
                        <h5 class="mt-0"> How to make unlimited data entry ?</h5>
                        <p class="answer text-muted mb-0">Furthermore, it is advantageous when the dummy text is
                            relatively realistic so that the layout impression of the final publication is not
                            compromised.</p>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-help-circle fea icon-ex-md text-primary me-2 mt-1">
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                    </svg>
                    <div class="flex-1">
                        <h5 class="mt-0"> Is <span class="text-primary">Landrick</span> safer to use with my account ?
                        </h5>
                        <p class="answer text-muted mb-0">The most well-known dummy text is the 'Lorem Ipsum', which is
                            said to have originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin
                            language which more or less corresponds to 'proper' Latin.</p>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->
<!-- Pricing End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->