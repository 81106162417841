import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  public title = 'The Special Moments';
  public language: string = 'en';

  constructor(
    private _router: Router,
    private _translateService: TranslateService
  ) {
    this._translateService.setDefaultLang(this.language);
    this._translateService.use(this.language);
    localStorage.setItem('lang', this.language);

    /**
     * Unicons icon refreshed on route change.
     */
    this._router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        // window['Unicons']();
      }

      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

  public ngOnInit(): void {
   
  }

  public ngOnDestroy(): void {
    
  }
}
