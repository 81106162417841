import { Component } from '@angular/core';

@Component({
  selector: 'app-hosting-services',
  templateUrl: './hosting-services.component.html',
  styleUrls: ['./hosting-services.component.css']
})
export class HostingServicesComponent {
  navClass = 'nav-light';
    // Set Topbar Option
  Menuoption = 'hosting';
  Settingicon = true
  footerVariant = 'footer-ten';
}
