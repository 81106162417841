<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> Breadcrumbs </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Components</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Breadcrumbs</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 d-md-block d-none">
                <app-component-sidebar></app-component-sidebar>
            </div>

            <div class="col-lg-9 col-md-8 col-12">
                <div class="row">
                    <!-- Breadcrumb Start -->
                    <div class="col-12">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> Breadcrumb </h5>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted mb-4 pb-2">Ex. <br> 
                                    <pre><code class="text-danger">
&lt;nav aria-label="breadcrumb" class="d-inline-block"&gt;
&lt;ul class="breadcrumb rounded shadow mb-0 px-4 py-2"&gt;
&lt;li class="breadcrumb-item"&gt;&lt;a routerLink="/index"&gt;Landrick&lt;/a&gt;&lt;/li&gt;
&lt;li class="breadcrumb-item"&gt;&lt;a href="javascript:void(0)"&gt;Docs&lt;/a&gt;&lt;/li&gt;
&lt;li class="breadcrumb-item active" aria-current="page"&gt;Components&lt;/li&gt;
&lt;/ul&gt;
&lt;/nav&gt;
</code></pre>
                                </h6>

                                <nav aria-label="breadcrumb" class="d-inline-block">
                                    <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                                        <li class="breadcrumb-item"><a href="index">Landrick</a></li>
                                        <li class="breadcrumb-item"><a href="javascript:void(0)">Docs</a></li>
                                        <li class="breadcrumb-item active" aria-current="page">Components</li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Breadcrumb End -->
                </div><!--end row-->
            </div><!--end col-->
        </div>
    </div>
</section>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->

