import { Component } from '@angular/core';

@Component({
  selector: 'app-component-sidebar',
  templateUrl: './component-sidebar.component.html',
  styleUrls: ['./component-sidebar.component.css']
})
export class ComponentSidebarComponent {

  ngOnInIt() {
  }
}
