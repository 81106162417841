<!-- Hero Start -->
<section class="home-slider position-relative" id="home">
    <div id="carouselExampleInterval" class="carousel slide carousel-fade" data-bs-ride="carousel">
        <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="0" class="active"
                aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="1"
                aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="2"
                aria-label="Slide 3"></button>
        </div>
        <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="3000">
                <div class="bg-home d-flex align-items-center"
                    style="background-image:url('assets/images/logistics/01.jpg')">
                    <div class="bg-overlay"></div>
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col">
                                <div class="title-heading position-relative mt-4 text-center" style="z-index: 1;">
                                    <h1 class="fw-bold display-4 text-white title-dark mb-3">Logistic Solutions
                                        <br>Around the World</h1>
                                    <p class="para-desc mx-auto text-white-50">Launch your campaign and benefit from our
                                        expertise on designing and managing conversion centered bootstrap v5 html page.
                                    </p>
                                    <div class="mt-4 pt-2">
                                        <a href="#!" class="btn btn-primary">Learn More</a>
                                    </div>
                                </div>
                            </div><!--end col-->
                        </div><!--end row-->
                    </div><!--end container-->
                </div>
            </div>

            <div class="carousel-item" data-bs-interval="3000">
                <div class="bg-home d-flex align-items-center"
                    style="background-image:url('assets/images/logistics/02.jpg')">
                    <div class="bg-overlay"></div>
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col">
                                <div class="title-heading position-relative mt-4 text-center" style="z-index: 1;">
                                    <h1 class="fw-bold display-4 text-white title-dark mb-3">We Make Your <br> Logistic
                                        Simple</h1>
                                    <p class="para-desc mx-auto text-white-50">Launch your campaign and benefit from our
                                        expertise on designing and managing conversion centered bootstrap v5 html page.
                                    </p>
                                    <div class="mt-4 pt-2">
                                        <a href="#!" class="btn btn-primary">Learn More</a>
                                    </div>
                                </div>
                            </div><!--end col-->
                        </div><!--end row-->
                    </div><!--end container-->
                </div>
            </div>

            <div class="carousel-item" data-bs-interval="3000">
                <div class="bg-home d-flex align-items-center"
                    style="background-image:url('assets/images/logistics/03.jpg')">
                    <div class="bg-overlay"></div>
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col">
                                <div class="title-heading position-relative mt-4 text-center" style="z-index: 1;">
                                    <h1 class="fw-bold display-4 text-white title-dark mb-3">Flexible Logistics <br>&
                                        Cargo for Business</h1>
                                    <p class="para-desc mx-auto text-white-50">Launch your campaign and benefit from our
                                        expertise on designing and managing conversion centered bootstrap v5 html page.
                                    </p>
                                    <div class="mt-4 pt-2">
                                        <a href="#!" class="btn btn-primary">Learn More</a>
                                    </div>
                                </div>
                            </div><!--end col-->
                        </div><!--end row-->
                    </div><!--end container-->
                </div>
            </div>
        </div>
    </div>
</section><!--end section-->
<!-- Hero End -->

<!-- FEATURES START -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10">
                <div class="features-absolute">
                    <div class="row g-2">
                        <div class="col-md-6 col-12">
                            <div
                                class="card features feature-primary feature-full-bg rounded shadow p-4 position-relative overflow-hidden border-0">
                                <span class="h2 icon-color">
                                    <i class="uil uil-truck"></i>
                                </span>
                                <div class="card-body p-0 content">
                                    <h5 class="mb-3">Transport Solutions</h5>
                                    <p class="para text-muted mb-3">If the distribution of letters and 'words' is
                                        random, the reader from making a neutral judgement</p>

                                    <a href="javascript:void(0)" class="readmore text-dark">Read More <i
                                            class="uil uil-angle-right align-middle"></i></a>
                                </div>
                                <div class="position-absolute top-0 end-0">
                                    <i class="uil uil-truck display-1 opacity-05"></i>
                                </div>
                            </div>
                        </div><!--end col-->

                        <div class="col-md-6 col-12">
                            <div
                                class="card features feature-primary feature-full-bg rounded shadow p-4 position-relative overflow-hidden border-0">
                                <span class="h2 icon-color">
                                    <i class="uil uil-parcel"></i>
                                </span>
                                <div class="card-body p-0 content">
                                    <h5 class="mb-3">Warehousing Solutions</h5>
                                    <p class="para text-muted mb-3">If the distribution of letters and 'words' is
                                        random, the reader from making a neutral judgement</p>

                                    <a href="javascript:void(0)" class="readmore text-dark">Read More <i
                                            class="uil uil-angle-right align-middle"></i></a>
                                </div>
                                <div class="position-absolute top-0 end-0">
                                    <i class="uil uil-parcel display-1 opacity-05"></i>
                                </div>
                            </div>
                        </div><!--end col-->
                    </div><!--end row-->
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="position-relative">
                    <img src="assets/images/logistics/about.png" class="rounded img-fluid mx-auto d-block" alt="">
                    <div class="play-icon">
                        <a href="#!" data-type="youtube" data-id="yba7hPeTSjk" class="play-btn lightbox border-0">
                            <i class="mdi mdi-play text-primary rounded-circle shadow"></i>
                        </a>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-7 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="section-title ms-lg-4">
                    <h6>About us</h6>
                    <h4 class="title mb-4">Powerful Features of <br> <span class="text-primary">Landrick</span>
                        Logistics</h4>
                    <p class="text-muted">Start working with <span class="text-primary fw-bold">Landrick</span> that can
                        provide everything you need to generate awareness, drive traffic, connect. Dummy text is text
                        that is used in the publishing industry or by web designers to occupy the space which will later
                        be filled with 'real' content. This is required when, for example, the final text is not yet
                        available. Dummy texts have been in use by typesetters since the 16th century.</p>
                    <a href="javascript:void(0)" class="btn btn-primary mt-3">Read More <i
                            class="uil uil-angle-right-b"></i></a>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->

    <!-- Partners start -->
    <div class="container">
        <div class="row justify-content-center">
            <app-clients-logo brand_class="col-lg-2 col-md-2 col-6 text-center py-4"></app-clients-logo>
        </div><!--end row-->
    </div><!--end container-->
    <!-- Partners End -->

    <div class="container-fluid mt-100 mt-60">
        <div class="py-5" style="background: url('assets/images/svg-map.svg');">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <span class="clip-text clip-text-image text-uppercase fw-bold"
                            style="background-image: url('assets/images/logistics/01.jpg');">Landrick.</span>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
        </div>
    </div><!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">Explore Our Services</h4>
                    <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row">
            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div
                    class="card features feature-primary feature-clean explore-feature p-4 border-0 rounded-md shadow text-center">
                    <div class="icons text-center mx-auto">
                        <i class="uil uil-truck d-block rounded h3 mb-0 mx-auto"></i>
                    </div>

                    <div class="card-body p-0 content">
                        <h5 class="mt-4"><a href="javascript:void(0)" class="title text-dark">Land Transport</a></h5>
                        <p class="text-muted">The most well-known which is said to have originated</p>

                        <a href="javascript:void(0)" class="read-more">Read More <i
                                class="uil uil-angle-right-b align-middle"></i></a>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div
                    class="card features feature-primary feature-clean explore-feature p-4 border-0 rounded-md shadow text-center">
                    <div class="icons text-center mx-auto">
                        <i class="uil uil-plane-departure d-block rounded h3 mb-0 mx-auto"></i>
                    </div>

                    <div class="card-body p-0 content">
                        <h5 class="mt-4"><a href="javascript:void(0)" class="title text-dark">Air Freight</a></h5>
                        <p class="text-muted">The most well-known which is said to have originated</p>

                        <a href="javascript:void(0)" class="read-more">Read More <i
                                class="uil uil-angle-right-b align-middle"></i></a>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div
                    class="card features feature-primary feature-clean explore-feature p-4 border-0 rounded-md shadow text-center">
                    <div class="icons text-center mx-auto">
                        <i class="uil uil-ship d-block rounded h3 mb-0 mx-auto"></i>
                    </div>

                    <div class="card-body p-0 content">
                        <h5 class="mt-4"><a href="javascript:void(0)" class="title text-dark">Ocean Freight</a></h5>
                        <p class="text-muted">The most well-known which is said to have originated</p>

                        <a href="javascript:void(0)" class="read-more">Read More <i
                                class="uil uil-angle-right-b align-middle"></i></a>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div
                    class="card features feature-primary feature-clean explore-feature p-4 border-0 rounded-md shadow text-center">
                    <div class="icons text-center mx-auto">
                        <i class="uil uil-money-stack d-block rounded h3 mb-0 mx-auto"></i>
                    </div>

                    <div class="card-body p-0 content">
                        <h5 class="mt-4"><a href="javascript:void(0)" class="title text-dark">Cost Saving</a></h5>
                        <p class="text-muted">The most well-known which is said to have originated</p>

                        <a href="javascript:void(0)" class="read-more">Read More <i
                                class="uil uil-angle-right-b align-middle"></i></a>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div
                    class="card features feature-primary feature-clean explore-feature p-4 border-0 rounded-md shadow text-center">
                    <div class="icons text-center mx-auto">
                        <i class="uil uil-package d-block rounded h3 mb-0 mx-auto"></i>
                    </div>

                    <div class="card-body p-0 content">
                        <h5 class="mt-4"><a href="javascript:void(0)" class="title text-dark">Safe Packing</a></h5>
                        <p class="text-muted">The most well-known which is said to have originated</p>

                        <a href="javascript:void(0)" class="read-more">Read More <i
                                class="uil uil-angle-right-b align-middle"></i></a>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div
                    class="card features feature-primary feature-clean explore-feature p-4 border-0 rounded-md shadow text-center">
                    <div class="icons text-center mx-auto">
                        <i class="uil uil-fast-mail-alt d-block rounded h3 mb-0 mx-auto"></i>
                    </div>

                    <div class="card-body p-0 content">
                        <h5 class="mt-4"><a href="javascript:void(0)" class="title text-dark">Fast Shipping</a></h5>
                        <p class="text-muted">The most well-known which is said to have originated</p>

                        <a href="javascript:void(0)" class="read-more">Read More <i
                                class="uil uil-angle-right-b align-middle"></i></a>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->
<!-- End -->

<!-- Start CTA -->
<section class="section bg-cta" style="background: url('assets/images/logistics/cta.jpg') center;">
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="title-heading">
                    <img src="assets/images/logo-light.png" height="24" alt="">
                    <h4 class="heading title-dark text-white my-4">World’s Leading Contract <br> Logistics Provider</h4>
                    <p class="text-white-50 para-dark para-desc mx-auto">Start working with Landrick that can provide
                        everything you need to generate awareness, drive traffic, connect.</p>
                    <a href="javascript:void(0)" (click)="openModal(contentmodal)" data-type="youtube"
                        data-id="yba7hPeTSjk" class="play-btn mt-4">
                        <i-feather name="play" class="fea icon-ex-md text-white title-dark"></i-feather>
                    </a>
                    <ng-template #contentmodal let-modal>
                        <div class="modal-header">
                          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <iframe src="https://player.vimeo.com/video/287684225" height="450" width="780" frameborder="0"
                            allowfullscreen allow="autoplay; encrypted-media"></iframe>
                        </div>
                      </ng-template>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->
<!-- End CTA -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h4 class="title mb-4">Our Happy Customers</h4>
                    <p class="text-muted mx-auto para-desc">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row justify-content-center">
            <div class="col-lg-12 mt-4">
                <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">Latest News</h4>
                    <p class="text-muted para-desc mx-auto mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row">
            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="card blog blog-primary rounded border-0 shadow">
                    <div class="position-relative">
                        <img src="assets/images/logistics/blog01.jpg" class="card-img-top rounded-top" alt="...">
                        <div class="overlay rounded-top"></div>
                    </div>
                    <div class="card-body content">
                        <h5><a href="javascript:void(0)" class="card-title title text-dark">Helping Companies in Their
                                Green Transition</a></h5>
                        <div class="post-meta d-flex justify-content-between mt-3">
                            <ul class="list-unstyled mb-0">
                                <li class="list-inline-item me-2 mb-0"><a href="javascript:void(0)"
                                        class="text-muted like"><i class="uil uil-heart me-1"></i>33</a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                                            class="uil uil-comment me-1"></i>08</a></li>
                            </ul>
                            <a routerLink="/blog-detail" class="text-muted readmore">Read More <i
                                    class="uil uil-angle-right-b align-middle"></i></a>
                        </div>
                    </div>
                    <div class="author">
                        <small class="user d-block"><i class="uil uil-user"></i> Calvin Carlo</small>
                        <small class="date"><i class="uil uil-calendar-alt"></i> 25th June 2021</small>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="card blog blog-primary rounded border-0 shadow">
                    <div class="position-relative">
                        <img src="assets/images/logistics/blog02.jpg" class="card-img-top rounded-top" alt="...">
                        <div class="overlay rounded-top"></div>
                    </div>
                    <div class="card-body content">
                        <h5><a href="javascript:void(0)" class="card-title title text-dark">Key Logistics Trends in Life
                                Sciences 2021</a></h5>
                        <div class="post-meta d-flex justify-content-between mt-3">
                            <ul class="list-unstyled mb-0">
                                <li class="list-inline-item me-2 mb-0"><a href="javascript:void(0)"
                                        class="text-muted like"><i class="uil uil-heart me-1"></i>33</a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                                            class="uil uil-comment me-1"></i>08</a></li>
                            </ul>
                            <a routerLink="/blog-detail" class="text-muted readmore">Read More <i
                                    class="uil uil-angle-right-b align-middle"></i></a>
                        </div>
                    </div>
                    <div class="author">
                        <small class="user d-block"><i class="uil uil-user"></i> Calvin Carlo</small>
                        <small class="date"><i class="uil uil-calendar-alt"></i> 25th June 2021</small>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="card blog blog-primary rounded border-0 shadow">
                    <div class="position-relative">
                        <img src="assets/images/logistics/blog03.jpg" class="card-img-top rounded-top" alt="...">
                        <div class="overlay rounded-top"></div>
                    </div>
                    <div class="card-body content">
                        <h5><a href="javascript:void(0)" class="card-title title text-dark">5 Benefits of Working with a
                                Freight Broker</a></h5>
                        <div class="post-meta d-flex justify-content-between mt-3">
                            <ul class="list-unstyled mb-0">
                                <li class="list-inline-item me-2 mb-0"><a href="javascript:void(0)"
                                        class="text-muted like"><i class="uil uil-heart me-1"></i>33</a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                                            class="uil uil-comment me-1"></i>08</a></li>
                            </ul>
                            <a routerLink="/blog-detail" class="text-muted readmore">Read More <i
                                    class="uil uil-angle-right-b align-middle"></i></a>
                        </div>
                    </div>
                    <div class="author">
                        <small class="user d-block"><i class="uil uil-user"></i> Calvin Carlo</small>
                        <small class="date"><i class="uil uil-calendar-alt"></i> 25th June 2021</small>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->