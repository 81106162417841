import { Component } from '@angular/core';

@Component({
  selector: 'app-auth-reset-password-bg-video',
  templateUrl: './auth-reset-password-bg-video.component.html',
  styleUrls: ['./auth-reset-password-bg-video.component.css']
})
export class AuthResetPasswordBgVideoComponent {
  year = new Date().getFullYear()
}
