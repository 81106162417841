<!-- Hero Start -->
<section class="bg-half-260 bg-light bg-animation-left crypto-home d-table w-100" id="home">
  <div class="container position-relative" style="z-index: 1;">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-10">
        <div class="title-heading text-center">
          <img src="assets/images/crypto/crypto.svg" height="136" class="mover" alt="">
          <h1 class="heading text-primary text-shadow-title mt-4 mb-3">Buy And Sale Cryptocurrency</h1>
          <p class="para-desc mx-auto text-muted">Launch your campaign and benefit from our expertise on designing and
            managing conversion centered bootstrap v5 html page.</p>
          <div class="text-center subcribe-form mt-4 pt-2">
            <form>
              <input type="email" id="email" name="email" class="shadow rounded-pill" style="opacity: 0.75;"
                placeholder="E-mail :">
              <button type="submit" class="btn btn-pills btn-primary">Submit <i
                  class="uil uil-arrow-right"></i></button>
            </form>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- FEATURES START -->
<section class="section border-top">
  <!-- Table Start -->
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="table-responsive features-absolute shadow-md rounded-md">
          <table class="table mb-0 table-center">
            <thead>
              <tr>
                <th scope="col" class="fw-normal border-bottom text-muted py-4 px-3" style="min-width: 250px;">Name</th>
                <th scope="col" class="fw-normal border-bottom text-muted py-4 px-3" style="width: 150px;">Price</th>
                <th scope="col" class="fw-normal border-bottom text-muted py-4 px-3" style="width: 150px;">Change(%)
                </th>
                <th scope="col" class="fw-normal border-bottom text-muted py-4 px-3" style="width: 150px;">Change($)
                </th>
                <th scope="col" class="fw-normal border-bottom text-muted py-4 px-3" style="width: 150px;">Marketcap
                </th>
                <th scope="col" class="fw-normal border-bottom text-muted py-4 px-3 text-end" style="width: 100px;">
                  Trade</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="p-3">
                  <div class="align-items-center">
                    <img src="assets/images/crypto/bitcoin.png" class="me-3" height="32" alt="">
                    <p class="mb-0 d-inline fw-normal h6">Bitcoin <span class="text-muted">BTC</span> </p>
                  </div>
                </th>
                <td class="p-3">$34587</td>
                <td class="text-danger p-3">-2.5%</td>
                <td class="text-danger p-3">-$745</td>
                <td class="text-muted p-3">$725,354M</td>
                <td class="p-3 text-end"><a href="javascript:void(0)" class="btn btn-sm btn-primary">Trade</a></td>
              </tr>

              <tr>
                <th class="p-3">
                  <div class="align-items-center">
                    <img src="assets/images/crypto/litecoin.png" class="me-3" height="32" alt="">
                    <p class="mb-0 d-inline fw-normal h6">Litecoin <span class="text-muted">LTC</span></p>
                  </div>
                </th>
                <td class="p-3">$216</td>
                <td class="text-success p-3">+.264%</td>
                <td class="text-success p-3">+$.264</td>
                <td class="text-muted p-3">$11,100M</td>
                <td class="p-3 text-end"><a href="javascript:void(0)" class="btn btn-sm btn-primary">Trade</a></td>
              </tr>

              <tr>
                <th class="p-3">
                  <div class="align-items-center">
                    <img src="assets/images/crypto/auroracoin.png" class="me-3" height="32" alt="">
                    <p class="mb-0 d-inline fw-normal h6">Auroracoin <span class="text-muted">ARC</span> </p>
                  </div>
                </th>
                <td class="p-3">$452</td>
                <td class="text-danger p-3">-1.9%</td>
                <td class="text-danger p-3">-$1.9</td>
                <td class="text-muted p-3">$45,785M</td>
                <td class="p-3 text-end"><a href="javascript:void(0)" class="btn btn-sm btn-primary">Trade</a></td>
              </tr>

              <tr>
                <th class="p-3">
                  <div class="align-items-center">
                    <img src="assets/images/crypto/coinye.png" class="me-3" height="32" alt="">
                    <p class="mb-0 d-inline fw-normal h6">Coinye <span class="text-muted">CNY</span> </p>
                  </div>
                </th>
                <td class="p-3">$154</td>
                <td class="text-success p-3">+1.05%</td>
                <td class="text-success p-3">+$1.05</td>
                <td class="text-muted p-3">$85,478M</td>
                <td class="p-3 text-end"><a href="javascript:void(0)" class="btn btn-sm btn-primary">Trade</a></td>
              </tr>

              <tr>
                <th class="p-3">
                  <div class="align-items-center">
                    <img src="assets/images/crypto/ethereum.png" class="me-3" height="32" alt="">
                    <p class="mt-2 d-inline fw-normal h6">Ethereum Coin <span class="text-muted">ETH</span> </p>
                  </div>
                </th>
                <td class="p-3">$854</td>
                <td class="text-success p-3">+1.705%</td>
                <td class="text-success p-3">+$1.705</td>
                <td class="text-muted p-3">$112,452M</td>
                <td class="p-3 text-end"><a href="javascript:void(0)" class="btn btn-sm btn-primary">Trade</a></td>
              </tr>

              <tr>
                <th class="p-3">
                  <div class="align-items-center">
                    <img src="assets/images/crypto/potcoin.png" class="me-3" height="32" alt="">
                    <p class="mb-0 d-inline fw-normal h6">Potcoin <span class="text-muted">PTC</span> </p>
                  </div>
                </th>
                <td class="p-3">$548</td>
                <td class="text-danger p-3">-3.2%</td>
                <td class="text-danger p-3">-$3.2</td>
                <td class="text-muted p-3">$4,712M</td>
                <td class="p-3 text-end"><a href="javascript:void(0)" class="btn btn-sm btn-primary">Trade</a></td>
              </tr>

              <tr>
                <th class="p-3">
                  <div class="align-items-center">
                    <img src="assets/images/crypto/zcash.png" class="me-3" height="32" alt="">
                    <p class="mb-0 d-inline fw-normal h6">Zcash Coin <span class="text-muted">ZCC</span> </p>
                  </div>
                </th>
                <td class="p-3">$965</td>
                <td class="text-success p-3">+1.465%</td>
                <td class="text-success p-3">+$1.465</td>
                <td class="text-muted p-3">$487,552M</td>
                <td class="p-3 text-end"><a href="javascript:void(0)" class="btn btn-sm btn-primary">Trade</a></td>
              </tr>

              <tr>
                <th class="p-3">
                  <div class="align-items-center">
                    <img src="assets/images/crypto/primecoin.png" class="me-3" height="32" alt="">
                    <p class="mb-0 d-inline fw-normal h6">Prime coin <span class="text-muted">XPM</span> </p>
                  </div>
                </th>
                <td class="p-3">$4875</td>
                <td class="text-danger p-3">-1.08%</td>
                <td class="text-danger p-3">-$1.08</td>
                <td class="text-muted p-3">$55,221M</td>
                <td class="p-3 text-end"><a href="javascript:void(0)" class="btn btn-sm btn-primary">Trade</a></td>
              </tr>

              <tr>
                <th class="p-3">
                  <div class="align-items-center">
                    <img src="assets/images/crypto/blocknet.png" class="me-3" height="32" alt="">
                    <p class="mb-0 d-inline fw-normal h6">Blocknet <span class="text-muted">BLOCK</span> </p>
                  </div>
                </th>
                <td class="p-3">$478</td>
                <td class="text-success p-3">+2.8%</td>
                <td class="text-success p-3">+$2.8</td>
                <td class="text-muted p-3">$66,552M</td>
                <td class="p-3 text-end"><a href="javascript:void(0)" class="btn btn-sm btn-primary">Trade</a></td>
              </tr>

              <tr>
                <th class="p-3">
                  <div class="align-items-center">
                    <img src="assets/images/crypto/kucoin.png" class="me-3" height="32" alt="">
                    <p class="mb-0 d-inline fw-normal h6">Kucoin <span class="text-muted">KCS</span> </p>
                  </div>
                </th>
                <td class="p-3">$545</td>
                <td class="text-success p-3">+1.5%</td>
                <td class="text-success p-3">+$1.5</td>
                <td class="text-muted p-3">$98,562M</td>
                <td class="p-3 text-end"><a href="javascript:void(0)" class="btn btn-sm btn-primary">Trade</a></td>
              </tr>
            </tbody>
          </table>
          <!--end table-->
        </div>
        <div class="text-center mt-4">
          <a routerLink="/crypto-market" class="text-muted">View more cryptocurrencies <i
              class="uil uil-arrow-right align-middle"></i></a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!-- Table End -->

  <!-- Process Start -->
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">How it works ?</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-md-3 col-12 mt-4 pt-2">
        <div class="text-center">
          <div class="rounded p-4 shadow">
            <a href="javascript:void(0)"><img src="assets/images/crypto/1.png" height="100" class="mx-auto d-block"
                alt=""></a>
          </div>

          <div class="mt-3">
            <h5><a href="javascript:void(0)" class="text-primary">Create Account</a></h5>
            <p class="text-muted mb-0">Earn upto 10%</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-3 col-12 mt-4 pt-2">
        <div class="text-center">
          <div class="rounded p-4 shadow">
            <a href="javascript:void(0)"><img src="assets/images/crypto/2.png" height="100" class="mx-auto d-block"
                alt=""></a>
          </div>

          <div class="mt-3">
            <h5><a href="javascript:void(0)" class="text-primary">Buy Coin</a></h5>
            <p class="text-muted mb-0">Save upto $50/coin</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-3 col-12 mt-4 pt-2">
        <div class="text-center">
          <div class="rounded p-4 shadow">
            <a href="javascript:void(0)"><img src="assets/images/crypto/3.png" height="100" class="mx-auto d-block"
                alt=""></a>
          </div>

          <div class="mt-3">
            <h5><a href="javascript:void(0)" class="text-primary">Loans</a></h5>
            <p class="text-muted mb-0">Use crypto as collateral</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-3 col-12 mt-4 pt-2">
        <div class="text-center">
          <div class="rounded p-4 shadow">
            <a href="javascript:void(0)"><img src="assets/images/crypto/4.png" height="100" class="mx-auto d-block"
                alt=""></a>
          </div>

          <div class="mt-3">
            <h5><a href="javascript:void(0)" class="text-primary">Credit & Debit Cards</a></h5>
            <p class="text-muted mb-0">Payments with cards</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!-- Process End -->
</section>
<!--end section-->
<!-- Section END -->

<!-- Counter Start -->
<section class="section-two bg-primary">
  <div class="container">
    <div class="row" id="counter">
      <div class="col-md-4">
        <div class="counter-box text-center">
          <h2 class="mb-0 mt-3 title-dark display-4 text-white">$ <span class="counter-value" [CountTo]="9706873"
              [from]="56343" [duration]="7"></span></h2>
          <h5 class="counter-head title-dark text-white-50">Exchanged</h5>
        </div>
        <!--end counter box-->
      </div>

      <div class="col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="counter-box text-center">
          <h2 class="mb-0 mt-3 title-dark display-4 text-white"><span class="counter-value" [CountTo]="106" [from]="23"
              [duration]="7"></span>
          </h2>
          <h5 class="counter-head title-dark text-white-50">Countries</h5>
        </div>
        <!--end counter box-->
      </div>

      <div class="col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="counter-box text-center">
          <h2 class="mb-0 mt-3 title-dark display-4 text-white"><span class="counter-value" [CountTo]="56043"
              [from]="6483" [duration]="7"></span></h2>
          <h5 class="counter-head title-dark text-white-50">Customers</h5>
        </div>
        <!--end counter box-->
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Counter End -->

<!-- Section start -->
<section class="section">
  <!-- Crypto Portfolio end -->
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Create your cryptocurrency portfolio today</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-12 mt-4 pt-2">
        <img src="assets/images/crypto/portfolio.png" class="img-fluid mx-auto d-block" alt="">
      </div>
      <!--end col-->

      <div class="col-md-4 col-12 mt-4">
        <div class="card border-0 text-center features feature-primary feature-clean p-4">
          <div class="icons text-center mx-auto">
            <i class="uil uil-shield-check d-block rounded h3 mb-0"></i>
          </div>

          <div class="card-body p-0 mt-4">
            <h5>Fully Secured</h5>
            <p class="text-muted mb-0">Composed in a pseudo-Latin language which more or less pseudo-Latin language
              corresponds.</p>
            <div class="mt-2">
              <a href="javascript:void(0)" class="read-more">Read More <svg xmlns="http://www.w3.org/2000/svg"
                  width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm">
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg></a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 col-12 mt-4">
        <div class="card border-0 text-center features feature-primary feature-clean p-4">
          <div class="icons text-center mx-auto">
            <i class="uil uil-newspaper d-block rounded h3 mb-0"></i>
          </div>

          <div class="card-body p-0 mt-4">
            <h5>Protected by insurance</h5>
            <p class="text-muted mb-0">Composed in a pseudo-Latin language which more or less pseudo-Latin language
              corresponds.</p>
            <div class="mt-2">
              <a href="javascript:void(0)" class="read-more">Read More <svg xmlns="http://www.w3.org/2000/svg"
                  width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm">
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg></a>
            </div>
          </div>
        </div>
      </div><!--end col-->

      <div class="col-md-4 col-12 mt-4">
        <div class="card border-0 text-center features feature-primary feature-clean p-4">
          <div class="icons text-center mx-auto">
            <i class="uil uil-browser d-block rounded h3 mb-0"></i>
          </div>

          <div class="card-body p-0 mt-4">
            <h5>Industry best practices</h5>
            <p class="text-muted mb-0">Composed in a pseudo-Latin language which more or less pseudo-Latin language
              corresponds.</p>
            <div class="mt-2">
              <a href="javascript:void(0)" class="read-more">Read More <svg xmlns="http://www.w3.org/2000/svg"
                  width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm">
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg></a>
            </div>
          </div>
        </div>
      </div><!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!-- Crypto Portfolio end -->

  <!-- Rate Start -->
  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 col-12">
        <div class="p-4 text-center rounded bg-light shadow">
          <h4 class="mb-0"><span class="text-warning">1 BTC</span> = $ 8654.32</h4>
        </div>

        <div class="mt-4 pt-2 text-center">
          <a href="javascript:void"><img src="assets/images/crypto/bitcoin.png"
              class="img-fluid avatar avatar-small m-2 p-2 rounded-circle shadow" placement="top" ngbTooltip="Bitcoin"
              alt=""></a>
          <a href="javascript:void"><img src="assets/images/crypto/coinye.png"
              class="img-fluid avatar avatar-small m-2 p-2 rounded-circle shadow" placement="top" ngbTooltip="Coinye"
              alt=""></a>
          <a href="javascript:void"><img src="assets/images/crypto/ethereum.png"
              class="img-fluid avatar avatar-small m-2 p-2 rounded-circle shadow" placement="top" ngbTooltip="Ethereum"
              alt=""></a>
          <a href="javascript:void"><img src="assets/images/crypto/litecoin.png"
              class="img-fluid avatar avatar-small m-2 p-2 rounded-circle shadow" placement="top" ngbTooltip="Litecoin"
              alt=""></a>
          <a href="javascript:void"><img src="assets/images/crypto/monero.png"
              class="img-fluid avatar avatar-small m-2 p-2 rounded-circle shadow" placement="top" ngbTooltip="Monero"
              alt=""></a>
          <a href="javascript:void"><img src="assets/images/crypto/auroracoin.png"
              class="img-fluid avatar avatar-small m-2 p-2 rounded-circle shadow" placement="top"
              ngbTooltip="Auroracoin" alt=""></a>
          <a href="javascript:void"><img src="assets/images/crypto/potcoin.png"
              class="img-fluid avatar avatar-small m-2 p-2 rounded-circle shadow" placement="top" ngbTooltip="Potcoin"
              alt=""></a>
          <a href="javascript:void"><img src="assets/images/crypto/zcash.png"
              class="img-fluid avatar avatar-small m-2 p-2 rounded-circle shadow" placement="top" ngbTooltip="Zcash"
              alt=""></a>
        </div>

        <div class="card border-0 shadow rounded p-4 bg-light overflow-hidden mt-4">
          <h5 class="text-center mb-0">Live Cryptocurrency Calculator</h5>

          <div class="calculator-block mt-4">
            <div class="calculator-body">
              <script src="https://www.cryptonator.com/ui/js/widget/calc_widget.js"></script>  
              <div class="cryptonatorwidget"
                style="border-radius:4px;padding:10px;background: transparent; border: 1px solid #CCCCCC;font-family: Arial; font-size: 100%; color: #000000;">
              <table width="100%" cellpadding="6">
                <tbody>
                  <tr>
                    <td style="width:45%;">
                      <input id="amount-1" type="text" placeholder="enter amount" style="width:100%;font-family: Arial; font-size: 100%; height: auto;">
                    </td>
                    <td style="width:55%;">
                      <select id="cur-1"style="width:100%;font-family: Arial; font-size: 100%; height: auto;">
                        <option value="1337">1337 (1337)</option>
                          <option value="1INCH">1INCH (1INCH)</option>
                          <option value="2015">2015 (2015)</option>
                          <option value="256">256 (256)</option>
                          <option value="2GIVE">2GIVE (2GIVE)</option>
                          <option value="404">404 (404)</option>
                          <option value="420G">420G (420G)</option>
                          <option value="700">700 (700)</option>
                          <option value="777">777 (777)</option>
                          <option value="8BIT">8Bit (8BIT)</option>
                          <option value="98">98 (98)</option>
                          <option value="AAA">AAA (AAA)</option>
                          <option value="AAVE">AAVE (AAVE)</option>
                          <option value="ABYSS">ABYSS (ABYSS)</option>
                          <option value="ACA">ACA (ACA)</option>
                          <option value="ACES">ACES (ACES)</option>
                          <option value="ACH">ACH (ACH)</option>
                          <option value="ACM">ACM (ACM)</option>
                          <option value="ACOIN">ACOIN (ACOIN)</option>
                          <option value="AD">AD (AD)</option>
                          <option value="ADAF0">ADAF0 (ADAF0)</option>
                          <option value="ADAM">ADAM (ADAM)</option>
                          <option value="ADAUP">ADAUP (ADAUP)</option>
                          <option value="ADD">ADD (ADD)</option>
                          <option value="ADG">ADG (ADG)</option>
                          <option value="ADT">ADT (ADT)</option>
                          <option value="ADX">ADX (ADX)</option>
                          <option value="AE">AE (AE)</option>
                          <option value="AECC">AECC (AECC)</option>
                          <option value="AERGO">AERGO (AERGO)</option>
                          <option value="AFC">AFC (AFC)</option>
                          <option value="AGIX">AGIX (AGIX)</option>
                          <option value="AGLD">AGLD (AGLD)</option>
                          <option value="AGR">AGR (AGR)</option>
                          <option value="AGRI">AGRI (AGRI)</option>
                          <option value="AI">AI (AI)</option>
                          <option value="AION">AION (AION)</option>
                          <option value="AIR">AIR (AIR)</option>
                          <option value="AIX">AIX (AIX)</option>
                          <option value="AKITA">AKITA (AKITA)</option>
                          <option value="AKRO">AKRO (AKRO)</option>
                          <option value="AL">AL (AL)</option>
                          <option value="ALA">ALA (ALA)</option>
                          <option value="ALBT">ALBT (ALBT)</option>
                          <option value="ALC">ALC (ALC)</option>
                          <option value="ALCX">ALCX (ALCX)</option>
                          <option value="ALG">ALG (ALG)</option>
                          <option value="ALGF0">ALGF0 (ALGF0)</option>
                          <option value="ALGO">ALGO (ALGO)</option>
                          <option value="ALICE">ALICE (ALICE)</option>
                          <option value="ALIEN">ALIEN (ALIEN)</option>
                          <option value="ALISA">ALISA (ALISA)</option>
                          <option value="ALL">ALL (ALL)</option>
                          <option value="ALPHA">ALPHA (ALPHA)</option>
                          <option value="ALTC">ALTC (ALTC)</option>
                          <option value="AM">AM (AM)</option>
                          <option value="AMB">AMB (AMB)</option>
                          <option value="AMM">AMM (AMM)</option>
                          <option value="AMO">AMO (AMO)</option>
                          <option value="AMPF0">AMPF0 (AMPF0)</option>
                          <option value="ANA">ANA (ANA)</option>
                          <option value="AND">AND (AND)</option>
                          <option value="ANKR">ANKR (ANKR)</option>
                          <option value="ANO">ANO (ANO)</option>
                          <option value="APE">APE (APE)</option>
                          <option value="APEF0">APEF0 (APEF0)</option>
                          <option value="API3">API3 (API3)</option>
                          <option value="APT">APT (APT)</option>
                          <option value="APTF0">APTF0 (APTF0)</option>
                          <option value="APX">APX (APX)</option>
                          <option value="AR">AR (AR)</option>
                          <option value="ARA">ARA (ARA)</option>
                          <option value="ARCO">ARCO (ARCO)</option>
                          <option value="ARCX">ARCX (ARCX)</option>
                          <option value="ARDR">ARDR (ARDR)</option>
                          <option value="ARE">ARE (ARE)</option>
                          <option value="ARK">ARK (ARK)</option>
                          <option value="ARM">ARM (ARM)</option>
                          <option value="ARPA">ARPA (ARPA)</option>
                          <option value="ARTA">ARTA (ARTA)</option>
                          <option value="ASAFE">ASAFE (ASAFE)</option>
                          <option value="ASK">ASK (ASK)</option>
                          <option value="ASM">ASM (ASM)</option>
                          <option value="ASR">ASR (ASR)</option>
                          <option value="AST">AST (AST)</option>
                          <option value="ASTR">ASTR (ASTR)</option>
                          <option value="AT">AT (AT)</option>
                          <option value="ATA">ATA (ATA)</option>
                          <option value="ATB">ATB (ATB)</option>
                          <option value="ATI">ATI (ATI)</option>
                          <option value="ATLAS">ATLAS (ATLAS)</option>
                          <option value="ATM">ATM (ATM)</option>
                          <option value="ATO">ATO (ATO)</option>
                          <option value="ATOF0">ATOF0 (ATOF0)</option>
                          <option value="ATOM">ATOM (ATOM)</option>
                          <option value="AUDIO">AUDIO (AUDIO)</option>
                          <option value="AUTO">AUTO (AUTO)</option>
                          <option value="AV">AV (AV)</option>
                          <option value="AVA">AVA (AVA)</option>
                          <option value="AVAX">AVAX (AVAX)</option>
                          <option value="AVE">AVE (AVE)</option>
                          <option value="AXG">AXG (AXG)</option>
                          <option value="AXL">AXL (AXL)</option>
                          <option value="AXS">AXS (AXS)</option>
                          <option value="AXSF0">AXSF0 (AXSF0)</option>
                          <option value="AY">AY (AY)</option>
                          <option value="AZ">AZ (AZ)</option>
                          <option value="ANC">Anoncoin (ANC)</option>
                          <option value="ANTI">AntiBitcoin (ANTI)</option>
                          <option value="ANT">Aragon (ANT)</option>
                          <option value="ARG">Argentum (ARG)</option>
                          <option value="ARI">Aricoin (ARI)</option>
                          <option value="REP">Augur (REP)</option>
                          <option value="AUD">Australian Dollar (AUD)</option>
                          <option value="B2">B2 (B2)</option>
                          <option value="B20">B20 (B20)</option>
                          <option value="B2B">B2B (B2B)</option>
                          <option value="B2M">B2M (B2M)</option>
                          <option value="B2X">B2X (B2X)</option>
                          <option value="BA">BA (BA)</option>
                          <option value="BAB">BAB (BAB)</option>
                          <option value="BAKE">BAKE (BAKE)</option>
                          <option value="BAL">BAL (BAL)</option>
                          <option value="BAND">BAND (BAND)</option>
                          <option value="BAR">BAR (BAR)</option>
                          <option value="BASH">BASH (BASH)</option>
                          <option value="BAT">BAT (BAT)</option>
                          <option value="BBB">BBB (BBB)</option>
                          <option value="BBT">BBT (BBT)</option>
                          <option value="BCA">BCA (BCA)</option>
                          <option value="BCHN">BCHN (BCHN)</option>
                          <option value="BCHSV">BCHSV (BCHSV)</option>
                          <option value="BCM">BCM (BCM)</option>
                          <option value="BCP">BCP (BCP)</option>
                          <option value="BDC">BDC (BDC)</option>
                          <option value="BDOT">BDOT (BDOT)</option>
                          <option value="BEAM">BEAM (BEAM)</option>
                          <option value="BEATX">BEATX (BEATX)</option>
                          <option value="BEEP">BEEP (BEEP)</option>
                          <option value="BEL">BEL (BEL)</option>
                          <option value="BENJI">BENJI (BENJI)</option>
                          <option value="BERN">BERNcash (BERN)</option>
                          <option value="BEST">BEST (BEST)</option>
                          <option value="BETA">BETA (BETA)</option>
                          <option value="BETH">BETH (BETH)</option>
                          <option value="BFT">BFT (BFT)</option>
                          <option value="BGF">BGF (BGF)</option>
                          <option value="BHC">BHC (BHC)</option>
                          <option value="BICO">BICO (BICO)</option>
                          <option value="BID">BID (BID)</option>
                          <option value="BIDR">BIDR (BIDR)</option>
                          <option value="BIFI">BIFI (BIFI)</option>
                          <option value="BILL">BILL (BILL)</option>
                          <option value="BIO">BIO (BIO)</option>
                          <option value="BIT">BIT (BIT)</option>
                          <option value="BIT16">BIT16 (BIT16)</option>
                          <option value="BITCI">BITCI (BITCI)</option>
                          <option value="BITS">BITS (BITS)</option>
                          <option value="BLU">BLU (BLU)</option>
                          <option value="BLUE">BLUE (BLUE)</option>
                          <option value="BLY">BLY (BLY)</option>
                          <option value="BLZ">BLZ (BLZ)</option>
                          <option value="BMN">BMN (BMN)</option>
                          <option value="BNB">BNB (BNB)</option>
                          <option value="BNBUP">BNBUP (BNBUP)</option>
                          <option value="BNBX">BNBX (BNBX)</option>
                          <option value="BNT">BNT (BNT)</option>
                          <option value="BNX">BNX (BNX)</option>
                          <option value="BOBA">BOBA (BOBA)</option>
                          <option value="BOND">BOND (BOND)</option>
                          <option value="BONK">BONK (BONK)</option>
                          <option value="BOO">BOO (BOO)</option>
                          <option value="BOSON">BOSON (BOSON)</option>
                          <option value="BOSS">BOSS (BOSS)</option>
                          <option value="BOT">BOT (BOT)</option>
                          <option value="BR">BR (BR)</option>
                          <option value="BSC">BSC (BSC)</option>
                          <option value="BST">BST (BST)</option>
                          <option value="BSTK">BSTK (BSTK)</option>
                          <option value="BSW">BSW (BSW)</option>
                          <option value="BTCA">BTCA (BTCA)</option>
                          <option value="BTCF0">BTCF0 (BTCF0)</option>
                          <option value="BTCM">BTCM (BTCM)</option>
                          <option value="BTCS">BTCS (BTCS)</option>
                          <option value="BTCST">BTCST (BTCST)</option>
                          <option value="BTCU">BTCU (BTCU)</option>
                          <option value="BTCUP">BTCUP (BTCUP)</option>
                          <option value="BTSE">BTSE (BTSE)</option>
                          <option value="BTT">BTT (BTT)</option>
                          <option value="BTTC">BTTC (BTTC)</option>
                          <option value="BUM">BUM (BUM)</option>
                          <option value="BUSD">BUSD (BUSD)</option>
                          <option value="BIGUP">BigUp (BIGUP)</option>
                          <option value="BTS">BitShares (BTS)</option>
                          <option value="BTC" selected="">Bitcoin (BTC)</option>
                          <option value="BCH">Bitcoin Cash (BCH)</option>
                          <option value="BTG">Bitcoin Gold (BTG)</option>
                          <option value="BTM">Bitmark (BTM)</option>
                          <option value="JACK">BlackJack (JACK)</option>
                          <option value="BLK">Blackcoin (BLK)</option>
                          <option value="BRL">Brazilian Real (BRL)</option>
                          <option value="GBP">British Pound Sterling (GBP)</option>
                          <option value="C20">C20 (C20)</option>
                          <option value="C98">C98 (C98)</option>
                          <option value="CA">CA (CA)</option>
                          <option value="CAG">CAG (CAG)</option>
                          <option value="CAID">CAID (CAID)</option>
                          <option value="CAKE">CAKE (CAKE)</option>
                          <option value="CAT">CAT (CAT)</option>
                          <option value="CAW">CAW (CAW)</option>
                          <option value="CB">CB (CB)</option>
                          <option value="CBC">CBC (CBC)</option>
                          <option value="CCB">CCB (CCB)</option>
                          <option value="CCC">CCC (CCC)</option>
                          <option value="CCD">CCD (CCD)</option>
                          <option value="CCL">CCL (CCL)</option>
                          <option value="CCUP">CCUP (CCUP)</option>
                          <option value="CDT">CDT (CDT)</option>
                          <option value="CEA">CEA (CEA)</option>
                          <option value="CEEK">CEEK (CEEK)</option>
                          <option value="CEL">CEL (CEL)</option>
                          <option value="CELO">CELO (CELO)</option>
                          <option value="CELR">CELR (CELR)</option>
                          <option value="CF">CF (CF)</option>
                          <option value="CFC">CFC (CFC)</option>
                          <option value="CFX">CFX (CFX)</option>
                          <option value="CH">CH (CH)</option>
                          <option value="CHEMX">CHEMX (CHEMX)</option>
                          <option value="CHESS">CHESS (CHESS)</option>
                          <option value="CHEX">CHEX (CHEX)</option>
                          <option value="CHR">CHR (CHR)</option>
                          <option value="CHRG">CHRG (CHRG)</option>
                          <option value="CHSB">CHSB (CHSB)</option>
                          <option value="CHZ">CHZ (CHZ)</option>
                          <option value="CIN">CIN (CIN)</option>
                          <option value="CITY">CITY (CITY)</option>
                          <option value="CJC">CJC (CJC)</option>
                          <option value="CKB">CKB (CKB)</option>
                          <option value="CKS">CKS (CKS)</option>
                          <option value="CLO">CLO (CLO)</option>
                          <option value="CLUD">CLUD (CLUD)</option>
                          <option value="CLV">CLV (CLV)</option>
                          <option value="CMC">CMC (CMC)</option>
                          <option value="CME">CME (CME)</option>
                          <option value="CN">CN (CN)</option>
                          <option value="CNB">CNB (CNB)</option>
                          <option value="CNG">CNG (CNG)</option>
                          <option value="CNH">CNH (CNH)</option>
                          <option value="CNHT">CNHT (CNHT)</option>
                          <option value="CNN">CNN (CNN)</option>
                          <option value="COCOS">COCOS (COCOS)</option>
                          <option value="COMP">COMP (COMP)</option>
                          <option value="CONV">CONV (CONV)</option>
                          <option value="CONX">CONX (CONX)</option>
                          <option value="CORAL">CORAL (CORAL)</option>
                          <option value="CORG">CORG (CORG)</option>
                          <option value="COS">COS (COS)</option>
                          <option value="COTI">COTI (COTI)</option>
                          <option value="CP">CP (CP)</option>
                          <option value="CRAVE">CRAVE (CRAVE)</option>
                          <option value="CRC">CRC (CRC)</option>
                          <option value="CREAM">CREAM (CREAM)</option>
                          <option value="CRED">CRED (CRED)</option>
                          <option value="CRM">CRM (CRM)</option>
                          <option value="CROC">CROC (CROC)</option>
                          <option value="CRON">CRON (CRON)</option>
                          <option value="CRPS">CRPS (CRPS)</option>
                          <option value="CRPT">CRPT (CRPT)</option>
                          <option value="CRT">CRT (CRT)</option>
                          <option value="CRV">CRV (CRV)</option>
                          <option value="CRVF0">CRVF0 (CRVF0)</option>
                          <option value="CRW">CRW (CRW)</option>
                          <option value="CRX">CRX (CRX)</option>
                          <option value="CTIC">CTIC (CTIC)</option>
                          <option value="CTK">CTK (CTK)</option>
                          <option value="CTSI">CTSI (CTSI)</option>
                          <option value="CTXC">CTXC (CTXC)</option>
                          <option value="CUDOS">CUDOS (CUDOS)</option>
                          <option value="CULT">CULT (CULT)</option>
                          <option value="CVC">CVC (CVC)</option>
                          <option value="CVP">CVP (CVP)</option>
                          <option value="CVX">CVX (CVX)</option>
                          <option value="CX">CX (CX)</option>
                          <option value="CXT">CXT (CXT)</option>
                          <option value="CYT">CYT (CYT)</option>
                          <option value="CAD">Canadian Dollar (CAD)</option>
                          <option value="CPC">Capricoin (CPC)</option>
                          <option value="ADA">Cardano (ADA)</option>
                          <option value="CLAM">Clams (CLAM)</option>
                          <option value="XCH">ClearingHouse (XCH)</option>
                          <option value="CLOAK">Cloakcoin (CLOAK)</option>
                          <option value="C2">Coin2.1 (C2)</option>
                          <option value="CV2">Colossuscoin2.0 (CV2)</option>
                          <option value="XCN">Cryptonite (XCN)</option>
                          <option value="DA">DA (DA)</option>
                          <option value="DAI">DAI (DAI)</option>
                          <option value="DALC">DALC (DALC)</option>
                          <option value="DANK">DANK (DANK)</option>
                          <option value="DAR">DAR (DAR)</option>
                          <option value="DASHS">DASHS (DASHS)</option>
                          <option value="DATA">DATA (DATA)</option>
                          <option value="DB">DB (DB)</option>
                          <option value="DBET">DBET (DBET)</option>
                          <option value="DBTC">DBTC (DBTC)</option>
                          <option value="DC">DC (DC)</option>
                          <option value="DCYP">DCYP (DCYP)</option>
                          <option value="DEA">DEA (DEA)</option>
                          <option value="DEBT">DEBT (DEBT)</option>
                          <option value="DEC">DEC (DEC)</option>
                          <option value="DECR">DECR (DECR)</option>
                          <option value="DEGO">DEGO (DEGO)</option>
                          <option value="DENT">DENT (DENT)</option>
                          <option value="DEX">DEX (DEX)</option>
                          <option value="DEXE">DEXE (DEXE)</option>
                          <option value="DF">DF (DF)</option>
                          <option value="DGCS">DGCS (DGCS)</option>
                          <option value="DIA">DIA (DIA)</option>
                          <option value="DICE">DICE (DICE)</option>
                          <option value="DLC">DLC (DLC)</option>
                          <option value="DLRS">DLRS (DLRS)</option>
                          <option value="DMT">DMT (DMT)</option>
                          <option value="DN">DN (DN)</option>
                          <option value="DO">DO (DO)</option>
                          <option value="DOCK">DOCK (DOCK)</option>
                          <option value="DODO">DODO (DODO)</option>
                          <option value="DORA">DORA (DORA)</option>
                          <option value="DOT">DOT (DOT)</option>
                          <option value="DOTA">DOTA (DOTA)</option>
                          <option value="DOTF0">DOTF0 (DOTF0)</option>
                          <option value="DRACO">DRACO (DRACO)</option>
                          <option value="DREP">DREP (DREP)</option>
                          <option value="DRKT">DRKT (DRKT)</option>
                          <option value="DROP">DROP (DROP)</option>
                          <option value="DRT">DRT (DRT)</option>
                          <option value="DTT">DTT (DTT)</option>
                          <option value="DUO">DUO (DUO)</option>
                          <option value="DUR">DUR (DUR)</option>
                          <option value="DUSK">DUSK (DUSK)</option>
                          <option value="DUST">DUST (DUST)</option>
                          <option value="DVF">DVF (DVF)</option>
                          <option value="DX">DX (DX)</option>
                          <option value="DXC">DXC (DXC)</option>
                          <option value="DXO">DXO (DXO)</option>
                          <option value="DYDX">DYDX (DYDX)</option>
                          <option value="DYP">DYP (DYP)</option>
                          <option value="DASH">Dash (DASH)</option>
                          <option value="DCR">Decred (DCR)</option>
                          <option value="DGB">Digibyte (DGB)</option>
                          <option value="DIME">Dimecoin (DIME)</option>
                          <option value="DOGE">Dogecoin (DOGE)</option>
                          <option value="EAGS">EAGS (EAGS)</option>
                          <option value="EAR">EAR (EAR)</option>
                          <option value="ECASH">ECASH (ECASH)</option>
                          <option value="ECLI">ECLI (ECLI)</option>
                          <option value="ECN">ECN (ECN)</option>
                          <option value="ECS">ECS (ECS)</option>
                          <option value="ED">ED (ED)</option>
                          <option value="EDC">EDC (EDC)</option>
                          <option value="EDIT">EDIT (EDIT)</option>
                          <option value="EDO">EDO (EDO)</option>
                          <option value="EEP">EEP (EEP)</option>
                          <option value="EER">EER (EER)</option>
                          <option value="EET">EET (EET)</option>
                          <option value="EFFM">EFFM (EFFM)</option>
                          <option value="EFI">EFI (EFI)</option>
                          <option value="EGAME">EGAME (EGAME)</option>
                          <option value="EGG">EGG (EGG)</option>
                          <option value="EGLD">EGLD (EGLD)</option>
                          <option value="EGO">EGO (EGO)</option>
                          <option value="EGOLD">EGOLD (EGOLD)</option>
                          <option value="ELC">ELC (ELC)</option>
                          <option value="ELE">ELE (ELE)</option>
                          <option value="ELF">ELF (ELF)</option>
                          <option value="ELON">ELON (ELON)</option>
                          <option value="ELR">ELR (ELR)</option>
                          <option value="EMB">EMB (EMB)</option>
                          <option value="EMT">EMT (EMT)</option>
                          <option value="EN">EN (EN)</option>
                          <option value="ENE">ENE (ENE)</option>
                          <option value="ENJ">ENJ (ENJ)</option>
                          <option value="ENS">ENS (ENS)</option>
                          <option value="ENT">ENT (ENT)</option>
                          <option value="ENTER">ENTER (ENTER)</option>
                          <option value="EOC">EOC (EOC)</option>
                          <option value="EOS">EOS (EOS)</option>
                          <option value="EOSF0">EOSF0 (EOSF0)</option>
                          <option value="EPC">EPC (EPC)</option>
                          <option value="EPV">EPV (EPV)</option>
                          <option value="EPX">EPX (EPX)</option>
                          <option value="EPY">EPY (EPY)</option>
                          <option value="EQ">EQ (EQ)</option>
                          <option value="EQUAL">EQUAL (EQUAL)</option>
                          <option value="ERN">ERN (ERN)</option>
                          <option value="ERP">ERP (ERP)</option>
                          <option value="ERR">ERR (ERR)</option>
                          <option value="ERSDL">ERSDL (ERSDL)</option>
                          <option value="ESC">ESC (ESC)</option>
                          <option value="ESP">ESP (ESP)</option>
                          <option value="ET">ET (ET)</option>
                          <option value="ETCF0">ETCF0 (ETCF0)</option>
                          <option value="ETCV">ETCV (ETCV)</option>
                          <option value="ETH2X">ETH2X (ETH2X)</option>
                          <option value="ETHF">ETHF (ETHF)</option>
                          <option value="ETHF0">ETHF0 (ETHF0)</option>
                          <option value="ETHS">ETHS (ETHS)</option>
                          <option value="ETHUP">ETHUP (ETHUP)</option>
                          <option value="ETHW">ETHW (ETHW)</option>
                          <option value="ETP">ETP (ETP)</option>
                          <option value="EUC">EUC (EUC)</option>
                          <option value="EURC">EURC (EURC)</option>
                          <option value="EURF0">EURF0 (EURF0)</option>
                          <option value="EUS">EUS (EUS)</option>
                          <option value="EUT">EUT (EUT)</option>
                          <option value="EUTF0">EUTF0 (EUTF0)</option>
                          <option value="EVIL">EVIL (EVIL)</option>
                          <option value="EVN">EVN (EVN)</option>
                          <option value="EXFI">EXFI (EXFI)</option>
                          <option value="EXIT">EXIT (EXIT)</option>
                          <option value="EXM">EXM (EXM)</option>
                          <option value="EXMR">EXMR (EXMR)</option>
                          <option value="EXO">EXO (EXO)</option>
                          <option value="EY">EY (EY)</option>
                          <option value="ETH">Ethereum (ETH)</option>
                          <option value="ETC">Ethereum Classic (ETC)</option>
                          <option value="EUR">Euro (EUR)</option>
                          <option value="EGC">EverGreenCoin (EGC)</option>
                          <option value="EXE">Execoin (EXE)</option>
                          <option value="EXP">Expanse (EXP)</option>
                          <option value="F16">F16 (F16)</option>
                          <option value="FARM">FARM (FARM)</option>
                          <option value="FAZZ">FAZZ (FAZZ)</option>
                          <option value="FBT">FBT (FBT)</option>
                          <option value="FC">FC (FC)</option>
                          <option value="FCL">FCL (FCL)</option>
                          <option value="FCT2">FCT2 (FCT2)</option>
                          <option value="FET">FET (FET)</option>
                          <option value="FFC">FFC (FFC)</option>
                          <option value="FG">FG (FG)</option>
                          <option value="FGZ">FGZ (FGZ)</option>
                          <option value="FI">FI (FI)</option>
                          <option value="FIDA">FIDA (FIDA)</option>
                          <option value="FIDGT">FIDGT (FIDGT)</option>
                          <option value="FIL">FIL (FIL)</option>
                          <option value="FILF0">FILF0 (FILF0)</option>
                          <option value="FIN">FIN (FIN)</option>
                          <option value="FIO">FIO (FIO)</option>
                          <option value="FIRE">FIRE (FIRE)</option>
                          <option value="FIRO">FIRO (FIRO)</option>
                          <option value="FIS">FIS (FIS)</option>
                          <option value="FIST">FIST (FIST)</option>
                          <option value="FIT">FIT (FIT)</option>
                          <option value="FITFI">FITFI (FITFI)</option>
                          <option value="FJC">FJC (FJC)</option>
                          <option value="FLM">FLM (FLM)</option>
                          <option value="FLOKI">FLOKI (FLOKI)</option>
                          <option value="FLOW">FLOW (FLOW)</option>
                          <option value="FLR">FLR (FLR)</option>
                          <option value="FLUX">FLUX (FLUX)</option>
                          <option value="FNTB">FNTB (FNTB)</option>
                          <option value="FOAM">FOAM (FOAM)</option>
                          <option value="FOR">FOR (FOR)</option>
                          <option value="FORTH">FORTH (FORTH)</option>
                          <option value="FPFT">FPFT (FPFT)</option>
                          <option value="FRAX">FRAX (FRAX)</option>
                          <option value="FRE">FRE (FRE)</option>
                          <option value="FREE">FREE (FREE)</option>
                          <option value="FROG">FROG (FROG)</option>
                          <option value="FRONT">FRONT (FRONT)</option>
                          <option value="FRST">FRST (FRST)</option>
                          <option value="FSW">FSW (FSW)</option>
                          <option value="FT">FT (FT)</option>
                          <option value="FTM">FTM (FTM)</option>
                          <option value="FTMF0">FTMF0 (FTMF0)</option>
                          <option value="FTO">FTO (FTO)</option>
                          <option value="FTT">FTT (FTT)</option>
                          <option value="FUN">FUN (FUN)</option>
                          <option value="FUND">FUND (FUND)</option>
                          <option value="FUNK">FUNK (FUNK)</option>
                          <option value="FX">FX (FX)</option>
                          <option value="FXS">FXS (FXS)</option>
                          <option value="GAL">GAL (GAL)</option>
                          <option value="GALA">GALA (GALA)</option>
                          <option value="GALAX">GALAX (GALAX)</option>
                          <option value="GAS">GAS (GAS)</option>
                          <option value="GB">GB (GB)</option>
                          <option value="GBPF0">GBPF0 (GBPF0)</option>
                          <option value="GCC">GCC (GCC)</option>
                          <option value="GEAR">GEAR (GEAR)</option>
                          <option value="GEEQ">GEEQ (GEEQ)</option>
                          <option value="GEIST">GEIST (GEIST)</option>
                          <option value="GELD">GELD (GELD)</option>
                          <option value="GER">GER (GER)</option>
                          <option value="GG">GG (GG)</option>
                          <option value="GHST">GHST (GHST)</option>
                          <option value="GLM">GLM (GLM)</option>
                          <option value="GLMR">GLMR (GLMR)</option>
                          <option value="GLO">GLO (GLO)</option>
                          <option value="GMCX">GMCX (GMCX)</option>
                          <option value="GML">GML (GML)</option>
                          <option value="GMT">GMT (GMT)</option>
                          <option value="GMX">GMX (GMX)</option>
                          <option value="GN">GN (GN)</option>
                          <option value="GNO">GNO (GNO)</option>
                          <option value="GNY">GNY (GNY)</option>
                          <option value="GOD">GOD (GOD)</option>
                          <option value="GODS">GODS (GODS)</option>
                          <option value="GOTX">GOTX (GOTX)</option>
                          <option value="GRE">GRE (GRE)</option>
                          <option value="GREED">GREED (GREED)</option>
                          <option value="GSM">GSM (GSM)</option>
                          <option value="GSR">GSR (GSR)</option>
                          <option value="GST">GST (GST)</option>
                          <option value="GSX">GSX (GSX)</option>
                          <option value="GTC">GTC (GTC)</option>
                          <option value="GTFO">GTFO (GTFO)</option>
                          <option value="GTO">GTO (GTO)</option>
                          <option value="GTX">GTX (GTX)</option>
                          <option value="GUESS">GUESS (GUESS)</option>
                          <option value="GUP">GUP (GUP)</option>
                          <option value="GUSD">GUSD (GUSD)</option>
                          <option value="GXT">GXT (GXT)</option>
                          <option value="GAME">GameCredits (GAME)</option>
                          <option value="GEN">Genstake (GEN)</option>
                          <option value="BSTY">GlobalBoost-Y (BSTY)</option>
                          <option value="GLD">GoldCoin (GLD)</option>
                          <option value="GNT">Golem (GNT)</option>
                          <option value="GRT">Grantcoin (GRT)</option>
                          <option value="HA">HA (HA)</option>
                          <option value="HAI">HAI (HAI)</option>
                          <option value="HAMS">HAMS (HAMS)</option>
                          <option value="HAND">HAND (HAND)</option>
                          <option value="HARD">HARD (HARD)</option>
                          <option value="HBAR">HBAR (HBAR)</option>
                          <option value="HCC">HCC (HCC)</option>
                          <option value="HDG">HDG (HDG)</option>
                          <option value="HEC">HEC (HEC)</option>
                          <option value="HEDG">HEDG (HEDG)</option>
                          <option value="HEX">HEX (HEX)</option>
                          <option value="HF">HF (HF)</option>
                          <option value="HFT">HFT (HFT)</option>
                          <option value="HGET">HGET (HGET)</option>
                          <option value="HIB">HIB (HIB)</option>
                          <option value="HIFI">HIFI (HIFI)</option>
                          <option value="HIFUN">HIFUN (HIFUN)</option>
                          <option value="HIGH">HIGH (HIGH)</option>
                          <option value="HIVE">HIVE (HIVE)</option>
                          <option value="HIX">HIX (HIX)</option>
                          <option value="HMQ">HMQ (HMQ)</option>
                          <option value="HMT">HMT (HMT)</option>
                          <option value="HNT">HNT (HNT)</option>
                          <option value="HON">HON (HON)</option>
                          <option value="HOOK">HOOK (HOOK)</option>
                          <option value="HOT">HOT (HOT)</option>
                          <option value="HPC">HPC (HPC)</option>
                          <option value="HR">HR (HR)</option>
                          <option value="HST">HST (HST)</option>
                          <option value="HT">HT (HT)</option>
                          <option value="HTC">HTC (HTC)</option>
                          <option value="HTX">HTX (HTX)</option>
                          <option value="HUNT">HUNT (HUNT)</option>
                          <option value="HMP">HempCoin (HMP)</option>
                          <option value="HZ">Horizon (HZ)</option>
                          <option value="IBANK">IBANK (IBANK)</option>
                          <option value="ICE">ICE (ICE)</option>
                          <option value="ICO">ICO (ICO)</option>
                          <option value="ICP">ICP (ICP)</option>
                          <option value="ICPF0">ICPF0 (ICPF0)</option>
                          <option value="ICX">ICX (ICX)</option>
                          <option value="IDA">IDA (IDA)</option>
                          <option value="IDEX">IDEX (IDEX)</option>
                          <option value="IDIA">IDIA (IDIA)</option>
                          <option value="IDRT">IDRT (IDRT)</option>
                          <option value="IDX">IDX (IDX)</option>
                          <option value="IETH">IETH (IETH)</option>
                          <option value="IL">IL (IL)</option>
                          <option value="ILT">ILT (ILT)</option>
                          <option value="ILV">ILV (ILV)</option>
                          <option value="IMX">IMX (IMX)</option>
                          <option value="IN">IN (IN)</option>
                          <option value="INA">INA (INA)</option>
                          <option value="INC">INC (INC)</option>
                          <option value="INGT">INGT (INGT)</option>
                          <option value="INJ">INJ (INJ)</option>
                          <option value="INK">INK (INK)</option>
                          <option value="INPAY">INPAY (INPAY)</option>
                          <option value="INT">INT (INT)</option>
                          <option value="IOC">IO Coin (IOC)</option>
                          <option value="IONX">IONX (IONX)</option>
                          <option value="IOST">IOST (IOST)</option>
                          <option value="IOT">IOT (IOT)</option>
                          <option value="IOTA">IOTA (IOTA)</option>
                          <option value="IOTF0">IOTF0 (IOTF0)</option>
                          <option value="IOTX">IOTX (IOTX)</option>
                          <option value="IQ">IQ (IQ)</option>
                          <option value="IQN">IQN (IQN)</option>
                          <option value="IQX">IQX (IQX)</option>
                          <option value="IR">IR (IR)</option>
                          <option value="IRIS">IRIS (IRIS)</option>
                          <option value="IS">IS (IS)</option>
                          <option value="IT">IT (IT)</option>
                          <option value="IW">IW (IW)</option>
                          <option value="IXI">IXI (IXI)</option>
                          <option value="IDR">Indonesian Rupiah (IDR)</option>
                          <option value="JASMY">JASMY (JASMY)</option>
                          <option value="JNT">JNT (JNT)</option>
                          <option value="JOE">JOE (JOE)</option>
                          <option value="JPEG">JPEG (JPEG)</option>
                          <option value="JPYF0">JPYF0 (JPYF0)</option>
                          <option value="JST">JST (JST)</option>
                          <option value="JUV">JUV (JUV)</option>
                          <option value="JPY">Japanese Yen (JPY)</option>
                          <option value="KAI">KAI (KAI)</option>
                          <option value="KAN">KAN (KAN)</option>
                          <option value="KAVA">KAVA (KAVA)</option>
                          <option value="KCASH">KCASH (KCASH)</option>
                          <option value="KCS">KCS (KCS)</option>
                          <option value="KDA">KDA (KDA)</option>
                          <option value="KDOE">KDOE (KDOE)</option>
                          <option value="KEY">KEY (KEY)</option>
                          <option value="KGB">KGB (KGB)</option>
                          <option value="KGC">KGC (KGC)</option>
                          <option value="KICK">KICK (KICK)</option>
                          <option value="KIN">KIN (KIN)</option>
                          <option value="KISHU">KISHU (KISHU)</option>
                          <option value="KISS">KISS (KISS)</option>
                          <option value="KLAY">KLAY (KLAY)</option>
                          <option value="KLV">KLV (KLV)</option>
                          <option value="KMD">KMD (KMD)</option>
                          <option value="KMX">KMX (KMX)</option>
                          <option value="KNCF0">KNCF0 (KNCF0)</option>
                          <option value="KOBO">KOBO (KOBO)</option>
                          <option value="KP3R">KP3R (KP3R)</option>
                          <option value="KRL">KRL (KRL)</option>
                          <option value="KSM">KSM (KSM)</option>
                          <option value="KT">KT (KT)</option>
                          <option value="KTK">KTK (KTK)</option>
                          <option value="KUB">KUB (KUB)</option>
                          <option value="KZT">KZT (KZT)</option>
                          <option value="KNC">KhanCoin (KNC)</option>
                          <option value="KR">Krypton (KR)</option>
                          <option value="L7S">L7S (L7S)</option>
                          <option value="LA">LA (LA)</option>
                          <option value="LAMBO">LAMBO (LAMBO)</option>
                          <option value="LANA">LANA (LANA)</option>
                          <option value="LATX">LATX (LATX)</option>
                          <option value="LAZIO">LAZIO (LAZIO)</option>
                          <option value="LBR">LBR (LBR)</option>
                          <option value="LBTCX">LBTCX (LBTCX)</option>
                          <option value="LC">LC (LC)</option>
                          <option value="LCC">LCC (LCC)</option>
                          <option value="LCWP">LCWP (LCWP)</option>
                          <option value="LCX">LCX (LCX)</option>
                          <option value="LDO">LDO (LDO)</option>
                          <option value="LEAF">LEAF (LEAF)</option>
                          <option value="LEO">LEO (LEO)</option>
                          <option value="LEPEN">LEPEN (LEPEN)</option>
                          <option value="LEVER">LEVER (LEVER)</option>
                          <option value="LGO">LGO (LGO)</option>
                          <option value="LIC">LIC (LIC)</option>
                          <option value="LIF">LIF (LIF)</option>
                          <option value="LIMBO">LIMBO (LIMBO)</option>
                          <option value="LIMX">LIMX (LIMX)</option>
                          <option value="LINA">LINA (LINA)</option>
                          <option value="LINK">LINK (LINK)</option>
                          <option value="LIT">LIT (LIT)</option>
                          <option value="LITE">LITE (LITE)</option>
                          <option value="LIV">LIV (LIV)</option>
                          <option value="LIZA">LIZA (LIZA)</option>
                          <option value="LIZA2">LIZA2 (LIZA2)</option>
                          <option value="LIZI">LIZI (LIZI)</option>
                          <option value="LIZUN">LIZUN (LIZUN)</option>
                          <option value="LM">LM (LM)</option>
                          <option value="LMR">LMR (LMR)</option>
                          <option value="LOCX">LOCX (LOCX)</option>
                          <option value="LOKA">LOKA (LOKA)</option>
                          <option value="LON">LON (LON)</option>
                          <option value="LOOK">LOOK (LOOK)</option>
                          <option value="LOOKS">LOOKS (LOOKS)</option>
                          <option value="LOOM">LOOM (LOOM)</option>
                          <option value="LOW">LOW (LOW)</option>
                          <option value="LPH">LPH (LPH)</option>
                          <option value="LPT">LPT (LPT)</option>
                          <option value="LQDR">LQDR (LQDR)</option>
                          <option value="LR">LR (LR)</option>
                          <option value="LRC">LRC (LRC)</option>
                          <option value="LTCF0">LTCF0 (LTCF0)</option>
                          <option value="LTCU">LTCU (LTCU)</option>
                          <option value="LTO">LTO (LTO)</option>
                          <option value="LUMI">LUMI (LUMI)</option>
                          <option value="LUNA">LUNA (LUNA)</option>
                          <option value="LUNA2">LUNA2 (LUNA2)</option>
                          <option value="LUNAF">LUNAF (LUNAF)</option>
                          <option value="LUNC">LUNC (LUNC)</option>
                          <option value="LUXO">LUXO (LUXO)</option>
                          <option value="LVG">LVG (LVG)</option>
                          <option value="LYM">LYM (LYM)</option>
                          <option value="LYO">LYO (LYO)</option>
                          <option value="LZ">LZ (LZ)</option>
                          <option value="LEA">LeaCoin (LEA)</option>
                          <option value="LSK">Lisk (LSK)</option>
                          <option value="LTC">Litecoin (LTC)</option>
                          <option value="LTCR">Litecred (LTCR)</option>
                          <option value="LUN">Lunyr (LUN)</option>
                          <option value="MA">MA (MA)</option>
                          <option value="MACRO">MACRO (MACRO)</option>
                          <option value="MAN">MAN (MAN)</option>
                          <option value="MANA">MANA (MANA)</option>
                          <option value="MAO">MAO (MAO)</option>
                          <option value="MASK">MASK (MASK)</option>
                          <option value="MAT">MAT (MAT)</option>
                          <option value="MATIC">MATIC (MATIC)</option>
                          <option value="MBL">MBL (MBL)</option>
                          <option value="MBOX">MBOX (MBOX)</option>
                          <option value="MC">MC (MC)</option>
                          <option value="MCAR">MCAR (MCAR)</option>
                          <option value="MCRN">MCRN (MCRN)</option>
                          <option value="MDT">MDT (MDT)</option>
                          <option value="MDX">MDX (MDX)</option>
                          <option value="MESH">MESH (MESH)</option>
                          <option value="MET">MET (MET)</option>
                          <option value="METAL">METAL (METAL)</option>
                          <option value="METIS">METIS (METIS)</option>
                          <option value="MFT">MFT (MFT)</option>
                          <option value="MG">MG (MG)</option>
                          <option value="MICRO">MICRO (MICRO)</option>
                          <option value="MIM">MIM (MIM)</option>
                          <option value="MINA">MINA (MINA)</option>
                          <option value="MINEX">MINEX (MINEX)</option>
                          <option value="MIO">MIO (MIO)</option>
                          <option value="MIR">MIR (MIR)</option>
                          <option value="MIRO">MIRO (MIRO)</option>
                          <option value="MITH">MITH (MITH)</option>
                          <option value="MKR">MKR (MKR)</option>
                          <option value="MKRF0">MKRF0 (MKRF0)</option>
                          <option value="ML">ML (ML)</option>
                          <option value="MLN">MLN (MLN)</option>
                          <option value="MLNC">MLNC (MLNC)</option>
                          <option value="MNA">MNA (MNA)</option>
                          <option value="MOB">MOB (MOB)</option>
                          <option value="MOIN">MOIN (MOIN)</option>
                          <option value="MONEY">MONEY (MONEY)</option>
                          <option value="MOTO">MOTO (MOTO)</option>
                          <option value="MOVR">MOVR (MOVR)</option>
                          <option value="MPRO">MPRO (MPRO)</option>
                          <option value="MR">MR (MR)</option>
                          <option value="MST">MST (MST)</option>
                          <option value="MT">MT (MT)</option>
                          <option value="MTA">MTA (MTA)</option>
                          <option value="MTC">MTC (MTC)</option>
                          <option value="MTL">MTL (MTL)</option>
                          <option value="MULTI">MULTI (MULTI)</option>
                          <option value="MUU">MUU (MUU)</option>
                          <option value="MVC">MVC (MVC)</option>
                          <option value="MVL">MVL (MVL)</option>
                          <option value="MX">MX (MX)</option>
                          <option value="MXNT">MXNT (MXNT)</option>
                          <option value="XMG">Magi (XMG)</option>
                          <option value="MXT">MarteXcoin (MXT)</option>
                          <option value="MAX">Maxcoin (MAX)</option>
                          <option value="MND">MindCoin (MND)</option>
                          <option value="XMR">Monero (XMR)</option>
                          <option value="MOON">Mooncoin (MOON)</option>
                          <option value="NANOX">NANOX (NANOX)</option>
                          <option value="NAT">NAT (NAT)</option>
                          <option value="NBTK">NBTK (NBTK)</option>
                          <option value="NC">NC (NC)</option>
                          <option value="NCOIN">NCOIN (NCOIN)</option>
                          <option value="NCR">NCR (NCR)</option>
                          <option value="NCT">NCT (NCT)</option>
                          <option value="NDOGE">NDOGE (NDOGE)</option>
                          <option value="NDR">NDR (NDR)</option>
                          <option value="NEAR">NEAR (NEAR)</option>
                          <option value="NEBL">NEBL (NEBL)</option>
                          <option value="XEM">NEM (XEM)</option>
                          <option value="NEO">NEO (NEO)</option>
                          <option value="NEOF0">NEOF0 (NEOF0)</option>
                          <option value="NETC">NETC (NETC)</option>
                          <option value="NEXO">NEXO (NEXO)</option>
                          <option value="NFI">NFI (NFI)</option>
                          <option value="NFT">NFT (NFT)</option>
                          <option value="NGN">NGN (NGN)</option>
                          <option value="NGO">NGO (NGO)</option>
                          <option value="NI">NI (NI)</option>
                          <option value="NJ">NJ (NJ)</option>
                          <option value="NKC">NKC (NKC)</option>
                          <option value="NKN">NKN (NKN)</option>
                          <option value="NKR">NKR (NKR)</option>
                          <option value="NKT">NKT (NKT)</option>
                          <option value="NMR">NMR (NMR)</option>
                          <option value="NO">NO (NO)</option>
                          <option value="NPXS">NPXS (NPXS)</option>
                          <option value="NRC">NRC (NRC)</option>
                          <option value="NS">NS (NS)</option>
                          <option value="NT">NT (NT)</option>
                          <option value="NTM">NTM (NTM)</option>
                          <option value="NTR">NTR (NTR)</option>
                          <option value="NU">NU (NU)</option>
                          <option value="NUBIS">NUBIS (NUBIS)</option>
                          <option value="NUKE">NUKE (NUKE)</option>
                          <option value="NULS">NULS (NULS)</option>
                          <option value="NX">NX (NX)</option>
                          <option value="NXT">NXT (NXT)</option>
                          <option value="NYC">NYC (NYC)</option>
                          <option value="NZC">NZC (NZC)</option>
                          <option value="NEU">NeuCoin (NEU)</option>
                          <option value="NEVA">NevaCoin (NEVA)</option>
                          <option value="NBT">NuBits (NBT)</option>
                          <option value="OAX">OAX (OAX)</option>
                          <option value="OBA">OBA (OBA)</option>
                          <option value="OCEAN">OCEAN (OCEAN)</option>
                          <option value="ODL">ODL (ODL)</option>
                          <option value="ODNT">ODNT (ODNT)</option>
                          <option value="OG">OG (OG)</option>
                          <option value="OGN">OGN (OGN)</option>
                          <option value="OKB">OKB (OKB)</option>
                          <option value="OKE">OKE (OKE)</option>
                          <option value="OL">OL (OL)</option>
                          <option value="OLDGR">OLDGR (OLDGR)</option>
                          <option value="OLI">OLI (OLI)</option>
                          <option value="OLS">OLS (OLS)</option>
                          <option value="OM">OM (OM)</option>
                          <option value="OMG">OMG (OMG)</option>
                          <option value="OMGF0">OMGF0 (OMGF0)</option>
                          <option value="OMN">OMN (OMN)</option>
                          <option value="OMP">OMP (OMP)</option>
                          <option value="OND">OND (OND)</option>
                          <option value="ONE">ONE (ONE)</option>
                          <option value="ONG">ONG (ONG)</option>
                          <option value="ONT">ONT (ONT)</option>
                          <option value="ONX">ONX (ONX)</option>
                          <option value="OO">OO (OO)</option>
                          <option value="OOK">OOK (OOK)</option>
                          <option value="OOKI">OOKI (OOKI)</option>
                          <option value="OP">OP (OP)</option>
                          <option value="ORN">ORN (ORN)</option>
                          <option value="ORT">ORT (ORT)</option>
                          <option value="OS">OS (OS)</option>
                          <option value="OSMO">OSMO (OSMO)</option>
                          <option value="OT">OT (OT)</option>
                          <option value="OTI">OTI (OTI)</option>
                          <option value="OTN">OTN (OTN)</option>
                          <option value="OVR">OVR (OVR)</option>
                          <option value="OWR">OWR (OWR)</option>
                          <option value="OXT">OXT (OXT)</option>
                          <option value="OXY">OXY (OXY)</option>
                          <option value="P3R">P3R (P3R)</option>
                          <option value="PAI">PAI (PAI)</option>
                          <option value="PAL">PAL (PAL)</option>
                          <option value="PANDA">PANDA (PANDA)</option>
                          <option value="PARA">PARA (PARA)</option>
                          <option value="PARTY">PARTY (PARTY)</option>
                          <option value="PAS">PAS (PAS)</option>
                          <option value="PAX">PAX (PAX)</option>
                          <option value="PAXG">PAXG (PAXG)</option>
                          <option value="PAYP">PAYP (PAYP)</option>
                          <option value="PE">PE (PE)</option>
                          <option value="PEL">PEL (PEL)</option>
                          <option value="PEO">PEO (PEO)</option>
                          <option value="PERL">PERL (PERL)</option>
                          <option value="PERP">PERP (PERP)</option>
                          <option value="PHA">PHA (PHA)</option>
                          <option value="PHB">PHB (PHB)</option>
                          <option value="PHR">PHR (PHR)</option>
                          <option value="PI3">PI3 (PI3)</option>
                          <option value="PIE">PIE (PIE)</option>
                          <option value="PIVX">PIVX (PIVX)</option>
                          <option value="PL">PL (PL)</option>
                          <option value="PLA">PLA (PLA)</option>
                          <option value="PLAY">PLAY (PLAY)</option>
                          <option value="PLCU">PLCU (PLCU)</option>
                          <option value="PLN">PLN (PLN)</option>
                          <option value="PLU">PLU (PLU)</option>
                          <option value="PLUTO">PLUTO (PLUTO)</option>
                          <option value="PNC">PNC (PNC)</option>
                          <option value="PNK">PNK (PNK)</option>
                          <option value="PNT">PNT (PNT)</option>
                          <option value="POL">POL (POL)</option>
                          <option value="POLC">POLC (POLC)</option>
                          <option value="POLIS">POLIS (POLIS)</option>
                          <option value="POLS">POLS (POLS)</option>
                          <option value="POLY">POLY (POLY)</option>
                          <option value="POLYX">POLYX (POLYX)</option>
                          <option value="POND">POND (POND)</option>
                          <option value="PONY">PONY (PONY)</option>
                          <option value="POR">POR (POR)</option>
                          <option value="PORTO">PORTO (PORTO)</option>
                          <option value="POST">POST (POST)</option>
                          <option value="POSW">POSW (POSW)</option>
                          <option value="POWR">POWR (POWR)</option>
                          <option value="PPT">PPT (PPT)</option>
                          <option value="PRIX">PRIX (PRIX)</option>
                          <option value="PROM">PROM (PROM)</option>
                          <option value="PROS">PROS (PROS)</option>
                          <option value="PRQ">PRQ (PRQ)</option>
                          <option value="PRS">PRS (PRS)</option>
                          <option value="PSB">PSB (PSB)</option>
                          <option value="PSG">PSG (PSG)</option>
                          <option value="PSP">PSP (PSP)</option>
                          <option value="PT">PT (PT)</option>
                          <option value="PTA">PTA (PTA)</option>
                          <option value="PULSE">PULSE (PULSE)</option>
                          <option value="PUMP">PUMP (PUMP)</option>
                          <option value="PUTIN">PUTIN (PUTIN)</option>
                          <option value="PWR">PWR (PWR)</option>
                          <option value="PX">PX (PX)</option>
                          <option value="PXL">PXL (PXL)</option>
                          <option value="PYR">PYR (PYR)</option>
                          <option value="PPC">Peercoin (PPC)</option>
                          <option value="PXI">Prime-XI (PXI)</option>
                          <option value="QI">QI (QI)</option>
                          <option value="QKC">QKC (QKC)</option>
                          <option value="QLC">QLC (QLC)</option>
                          <option value="QNT">QNT (QNT)</option>
                          <option value="QQQ">QQQ (QQQ)</option>
                          <option value="QRDO">QRDO (QRDO)</option>
                          <option value="QT">QT (QT)</option>
                          <option value="QTF">QTF (QTF)</option>
                          <option value="QTM">QTM (QTM)</option>
                          <option value="QTUM">QTUM (QTUM)</option>
                          <option value="QTZ">QTZ (QTZ)</option>
                          <option value="QUBIX">QUBIX (QUBIX)</option>
                          <option value="QUICK">QUICK (QUICK)</option>
                          <option value="QWS">QWS (QWS)</option>
                          <option value="RAC">RAC (RAC)</option>
                          <option value="RACA">RACA (RACA)</option>
                          <option value="RAD">RAD (RAD)</option>
                          <option value="RADI">RADI (RADI)</option>
                          <option value="RARE">RARE (RARE)</option>
                          <option value="RARI">RARI (RARI)</option>
                          <option value="RAY">RAY (RAY)</option>
                          <option value="RBBT">RBBT (RBBT)</option>
                          <option value="RC">RC (RC)</option>
                          <option value="RCA">RCA (RCA)</option>
                          <option value="RCN">RCN (RCN)</option>
                          <option value="RD">RD (RD)</option>
                          <option value="REEF">REEF (REEF)</option>
                          <option value="REI">REI (REI)</option>
                          <option value="REN">REN (REN)</option>
                          <option value="REPV2">REPV2 (REPV2)</option>
                          <option value="REQ">REQ (REQ)</option>
                          <option value="RH">RH (RH)</option>
                          <option value="RIB">RIB (RIB)</option>
                          <option value="RICHX">RICHX (RICHX)</option>
                          <option value="RIF">RIF (RIF)</option>
                          <option value="RING">RING (RING)</option>
                          <option value="RLC">RLC (RLC)</option>
                          <option value="RLY">RLY (RLY)</option>
                          <option value="RM">RM (RM)</option>
                          <option value="RMS">RMS (RMS)</option>
                          <option value="RND">RND (RND)</option>
                          <option value="RNDR">RNDR (RNDR)</option>
                          <option value="ROBO">ROBO (ROBO)</option>
                          <option value="RON">RON (RON)</option>
                          <option value="ROSE">ROSE (ROSE)</option>
                          <option value="ROYAL">ROYAL (ROYAL)</option>
                          <option value="RPA">RPA (RPA)</option>
                          <option value="RPL">RPL (RPL)</option>
                          <option value="RR">RR (RR)</option>
                          <option value="RRT">RRT (RRT)</option>
                          <option value="RSR">RSR (RSR)</option>
                          <option value="RT">RT (RT)</option>
                          <option value="RU">RU (RU)</option>
                          <option value="RUBIT">RUBIT (RUBIT)</option>
                          <option value="RUC">RUC (RUC)</option>
                          <option value="RUNE">RUNE (RUNE)</option>
                          <option value="RUP">RUP (RUP)</option>
                          <option value="RUPX">RUPX (RUPX)</option>
                          <option value="RUST">RUST (RUST)</option>
                          <option value="RV">RV (RV)</option>
                          <option value="RVN">RVN (RVN)</option>
                          <option value="RX">RX (RX)</option>
                          <option value="XRA">RateCoin (XRA)</option>
                          <option value="RDD">Reddcoin (RDD)</option>
                          <option value="RBT">Rimbit (RBT)</option>
                          <option value="XRP">Ripple (XRP)</option>
                          <option value="RBIES">Rubies (RBIES)</option>
                          <option value="RUR">Ruble (RUR)</option>
                          <option value="SAMO">SAMO (SAMO)</option>
                          <option value="SAN">SAN (SAN)</option>
                          <option value="SAND">SAND (SAND)</option>
                          <option value="SAT">SAT (SAT)</option>
                          <option value="SATO">SATO (SATO)</option>
                          <option value="SBIT">SBIT (SBIT)</option>
                          <option value="SBT">SBT (SBT)</option>
                          <option value="SBTC">SBTC (SBTC)</option>
                          <option value="SCAM">SCAM (SCAM)</option>
                          <option value="SCITW">SCITW (SCITW)</option>
                          <option value="SCRT">SCRT (SCRT)</option>
                          <option value="SDT">SDT (SDT)</option>
                          <option value="SED">SED (SED)</option>
                          <option value="SEDO">SEDO (SEDO)</option>
                          <option value="SEEDS">SEEDS (SEEDS)</option>
                          <option value="SEL">SEL (SEL)</option>
                          <option value="SENSO">SENSO (SENSO)</option>
                          <option value="SEX">SEX (SEX)</option>
                          <option value="SFE">SFE (SFE)</option>
                          <option value="SFP">SFP (SFP)</option>
                          <option value="SGB">SGB (SGB)</option>
                          <option value="SHDW">SHDW (SHDW)</option>
                          <option value="SHFT">SHFT (SHFT)</option>
                          <option value="SHIB">SHIB (SHIB)</option>
                          <option value="SHIT">SHIT (SHIT)</option>
                          <option value="SHREK">SHREK (SHREK)</option>
                          <option value="SHRM">SHRM (SHRM)</option>
                          <option value="SIDUS">SIDUS (SIDUS)</option>
                          <option value="SIGT">SIGT (SIGT)</option>
                          <option value="SIGU">SIGU (SIGU)</option>
                          <option value="SIMON">SIMON (SIMON)</option>
                          <option value="SK">SK (SK)</option>
                          <option value="SKL">SKL (SKL)</option>
                          <option value="SLCO">SLCO (SLCO)</option>
                          <option value="SLEEP">SLEEP (SLEEP)</option>
                          <option value="SLP">SLP (SLP)</option>
                          <option value="SM">SM (SM)</option>
                          <option value="SMART">SMART (SMART)</option>
                          <option value="SMF">SMF (SMF)</option>
                          <option value="SMR">SMR (SMR)</option>
                          <option value="SNFT">SNFT (SNFT)</option>
                          <option value="SNM">SNM (SNM)</option>
                          <option value="SNTR">SNTR (SNTR)</option>
                          <option value="SNX">SNX (SNX)</option>
                          <option value="SOL">SOL (SOL)</option>
                          <option value="SOLAR">SOLAR (SOLAR)</option>
                          <option value="SOLF0">SOLF0 (SOLF0)</option>
                          <option value="SOLO">SOLO (SOLO)</option>
                          <option value="SOS">SOS (SOS)</option>
                          <option value="SP">SP (SP)</option>
                          <option value="SPC">SPC (SPC)</option>
                          <option value="SPELL">SPELL (SPELL)</option>
                          <option value="SPEX">SPEX (SPEX)</option>
                          <option value="SPHTX">SPHTX (SPHTX)</option>
                          <option value="SPITZ">SPITZ (SPITZ)</option>
                          <option value="SQUID">SQUID (SQUID)</option>
                          <option value="SRM">SRM (SRM)</option>
                          <option value="SSV">SSV (SSV)</option>
                          <option value="ST">ST (ST)</option>
                          <option value="STA">STA (STA)</option>
                          <option value="STAKE">STAKE (STAKE)</option>
                          <option value="STAR">STAR (STAR)</option>
                          <option value="STG">STG (STG)</option>
                          <option value="STGF0">STGF0 (STGF0)</option>
                          <option value="STJ">STJ (STJ)</option>
                          <option value="STMX">STMX (STMX)</option>
                          <option value="STORJ">STORJ (STORJ)</option>
                          <option value="STPT">STPT (STPT)</option>
                          <option value="STRAX">STRAX (STRAX)</option>
                          <option value="STX">STX (STX)</option>
                          <option value="SUKU">SUKU (SUKU)</option>
                          <option value="SUN">SUN (SUN)</option>
                          <option value="SUPER">SUPER (SUPER)</option>
                          <option value="SUSHI">SUSHI (SUSHI)</option>
                          <option value="SWAP">SWAP (SWAP)</option>
                          <option value="SWAT">SWAT (SWAT)</option>
                          <option value="SWEAT">SWEAT (SWEAT)</option>
                          <option value="SWEET">SWEET (SWEET)</option>
                          <option value="SWFTC">SWFTC (SWFTC)</option>
                          <option value="SWING">SWING (SWING)</option>
                          <option value="SWRV">SWRV (SWRV)</option>
                          <option value="SWT">SWT (SWT)</option>
                          <option value="SX">SX (SX)</option>
                          <option value="SXP">SXP (SXP)</option>
                          <option value="SXX">SXX (SXX)</option>
                          <option value="SDC">ShadowCash (SDC)</option>
                          <option value="SC">Siacoin (SC)</option>
                          <option value="SIB">Sibcoin (SIB)</option>
                          <option value="SONG">SongCoin (SONG)</option>
                          <option value="SQC">Squall Coin (SQC)</option>
                          <option value="START">Startcoin (START)</option>
                          <option value="SNT">Status (SNT)</option>
                          <option value="STEEM">Steem (STEEM)</option>
                          <option value="STR">Stellar (STR)</option>
                          <option value="XLM">Stellar (XLM)</option>
                          <option value="SUB">Subcriptio (SUB)</option>
                          <option value="AMP">Synereo (AMP)</option>
                          <option value="SYS">Syscoin (SYS)</option>
                          <option value="TAJ">TAJ (TAJ)</option>
                          <option value="TAKE">TAKE (TAKE)</option>
                          <option value="TALK">TALK (TALK)</option>
                          <option value="TC">TC (TC)</option>
                          <option value="TCN">TCN (TCN)</option>
                          <option value="TCR">TCR (TCR)</option>
                          <option value="TDFB">TDFB (TDFB)</option>
                          <option value="TDH">TDH (TDH)</option>
                          <option value="TEP">TEP (TEP)</option>
                          <option value="TFL">TFL (TFL)</option>
                          <option value="TFUEL">TFUEL (TFUEL)</option>
                          <option value="TG">TG (TG)</option>
                          <option value="TGS">TGS (TGS)</option>
                          <option value="TH2">TH2 (TH2)</option>
                          <option value="THETA">THETA (THETA)</option>
                          <option value="THOM">THOM (THOM)</option>
                          <option value="THUG">THUG (THUG)</option>
                          <option value="THW">THW (THW)</option>
                          <option value="TIME">TIME (TIME)</option>
                          <option value="TKLN">TKLN (TKLN)</option>
                          <option value="TKO">TKO (TKO)</option>
                          <option value="TKTX">TKTX (TKTX)</option>
                          <option value="TLA">TLA (TLA)</option>
                          <option value="TLEX">TLEX (TLEX)</option>
                          <option value="TLM">TLM (TLM)</option>
                          <option value="TLOS">TLOS (TLOS)</option>
                          <option value="TLOSH">TLOSH (TLOSH)</option>
                          <option value="TM">TM (TM)</option>
                          <option value="TMRW">TMRW (TMRW)</option>
                          <option value="TNT">TNT (TNT)</option>
                          <option value="TOKC">TOKC (TOKC)</option>
                          <option value="TOKEN">TOKEN (TOKEN)</option>
                          <option value="TOM">TOM (TOM)</option>
                          <option value="TOMO">TOMO (TOMO)</option>
                          <option value="TON">TON (TON)</option>
                          <option value="TOP">TOP (TOP)</option>
                          <option value="TOR">TOR (TOR)</option>
                          <option value="TORN">TORN (TORN)</option>
                          <option value="TRADE">TRADE (TRADE)</option>
                          <option value="TRB">TRB (TRB)</option>
                          <option value="TREEB">TREEB (TREEB)</option>
                          <option value="TRG">TRG (TRG)</option>
                          <option value="TRIBE">TRIBE (TRIBE)</option>
                          <option value="TRIX">TRIX (TRIX)</option>
                          <option value="TRNC">TRNC (TRNC)</option>
                          <option value="TRON">TRON (TRON)</option>
                          <option value="TRX">TRON (TRX)</option>
                          <option value="TROY">TROY (TROY)</option>
                          <option value="TRR">TRR (TRR)</option>
                          <option value="TRU">TRU (TRU)</option>
                          <option value="TRXF0">TRXF0 (TRXF0)</option>
                          <option value="TRY">TRY (TRY)</option>
                          <option value="TSD">TSD (TSD)</option>
                          <option value="TSI">TSI (TSI)</option>
                          <option value="TT">TT (TT)</option>
                          <option value="TUM">TUM (TUM)</option>
                          <option value="TUSD">TUSD (TUSD)</option>
                          <option value="TVK">TVK (TVK)</option>
                          <option value="TVT">TVT (TVT)</option>
                          <option value="TWT">TWT (TWT)</option>
                          <option value="TYV">TYV (TYV)</option>
                          <option value="TZ">TZ (TZ)</option>
                          <option value="TAG">TagCoin (TAG)</option>
                          <option value="USDT">Tether (USDT)</option>
                          <option value="TIT">Titcoin (TIT)</option>
                          <option value="TX">Transfercoin (TX)</option>
                          <option value="UAE">UAE (UAE)</option>
                          <option value="UBEX">UBEX (UBEX)</option>
                          <option value="UBTC">UBTC (UBTC)</option>
                          <option value="UDC">UDC (UDC)</option>
                          <option value="UDI">UDI (UDI)</option>
                          <option value="UDOWN">UDOWN (UDOWN)</option>
                          <option value="UFR">UFR (UFR)</option>
                          <option value="UFT">UFT (UFT)</option>
                          <option value="UGAS">UGAS (UGAS)</option>
                          <option value="UL">UL (UL)</option>
                          <option value="ULT">ULT (ULT)</option>
                          <option value="UMA">UMA (UMA)</option>
                          <option value="UMC">UMC (UMC)</option>
                          <option value="UMEE">UMEE (UMEE)</option>
                          <option value="UNA">UNA (UNA)</option>
                          <option value="UNE">UNE (UNE)</option>
                          <option value="UNFI">UNFI (UNFI)</option>
                          <option value="UNI">UNI (UNI)</option>
                          <option value="UNIF0">UNIF0 (UNIF0)</option>
                          <option value="UNITS">UNITS (UNITS)</option>
                          <option value="UOS">UOS (UOS)</option>
                          <option value="UPE">UPE (UPE)</option>
                          <option value="USD">US Dollar (USD)</option>
                          <option value="USC">USC (USC)</option>
                          <option value="USDC">USDC (USDC)</option>
                          <option value="USDD">USDD (USDD)</option>
                          <option value="USDJ">USDJ (USDJ)</option>
                          <option value="USDP">USDP (USDP)</option>
                          <option value="USDX">USDX (USDX)</option>
                          <option value="USH">USH (USH)</option>
                          <option value="UST">UST (UST)</option>
                          <option value="USTC">USTC (USTC)</option>
                          <option value="USTF0">USTF0 (USTF0)</option>
                          <option value="UT">UT (UT)</option>
                          <option value="UTK">UTK (UTK)</option>
                          <option value="UTLE">UTLE (UTLE)</option>
                          <option value="UAH">Ukrainian Hryvnia (UAH)</option>
                          <option value="UNO">Unobtanium (UNO)</option>
                          <option value="VAI">VAI (VAI)</option>
                          <option value="VAL">VAL (VAL)</option>
                          <option value="VAPOR">VAPOR (VAPOR)</option>
                          <option value="VAX">VAX (VAX)</option>
                          <option value="VC">VC (VC)</option>
                          <option value="VEE">VEE (VEE)</option>
                          <option value="VEG">VEG (VEG)</option>
                          <option value="VELO">VELO (VELO)</option>
                          <option value="VEN">VEN (VEN)</option>
                          <option value="VERS">VERS (VERS)</option>
                          <option value="VET">VET (VET)</option>
                          <option value="VGX">VGX (VGX)</option>
                          <option value="VIB">VIB (VIB)</option>
                          <option value="VIDT">VIDT (VIDT)</option>
                          <option value="VINU">VINU (VINU)</option>
                          <option value="VITE">VITE (VITE)</option>
                          <option value="VK">VK (VK)</option>
                          <option value="VLT">VLT (VLT)</option>
                          <option value="VLX">VLX (VLX)</option>
                          <option value="VOLT">VOLT (VOLT)</option>
                          <option value="VOXEL">VOXEL (VOXEL)</option>
                          <option value="VPN">VPNCoin (VPN)</option>
                          <option value="VRA">VRA (VRA)</option>
                          <option value="VRP">VRP (VRP)</option>
                          <option value="VSP">VSP (VSP)</option>
                          <option value="VSY">VSY (VSY)</option>
                          <option value="VTHO">VTHO (VTHO)</option>
                          <option value="VTL">VTL (VTL)</option>
                          <option value="VX">VX (VX)</option>
                          <option value="XVG">Verge (XVG)</option>
                          <option value="WABI">WABI (WABI)</option>
                          <option value="WAN">WAN (WAN)</option>
                          <option value="WATER">WATER (WATER)</option>
                          <option value="WAVES">WAVES (WAVES)</option>
                          <option value="WAX">WAX (WAX)</option>
                          <option value="WAXP">WAXP (WAXP)</option>
                          <option value="WBNB">WBNB (WBNB)</option>
                          <option value="WBT">WBT (WBT)</option>
                          <option value="WBTC">WBTC (WBTC)</option>
                          <option value="WDOGE">WDOGE (WDOGE)</option>
                          <option value="WETH">WETH (WETH)</option>
                          <option value="WGO">WGO (WGO)</option>
                          <option value="WHO">WHO (WHO)</option>
                          <option value="WILD">WILD (WILD)</option>
                          <option value="WIN">WIN (WIN)</option>
                          <option value="WING">WING (WING)</option>
                          <option value="WINK">WINK (WINK)</option>
                          <option value="WITCH">WITCH (WITCH)</option>
                          <option value="WLKN">WLKN (WLKN)</option>
                          <option value="WNCG">WNCG (WNCG)</option>
                          <option value="WNXM">WNXM (WNXM)</option>
                          <option value="WOMEN">WOMEN (WOMEN)</option>
                          <option value="WOO">WOO (WOO)</option>
                          <option value="WORK">WORK (WORK)</option>
                          <option value="WOW">WOW (WOW)</option>
                          <option value="WRT">WRT (WRT)</option>
                          <option value="WRX">WRX (WRX)</option>
                          <option value="WT">WT (WT)</option>
                          <option value="WTC">WTC (WTC)</option>
                          <option value="WTL">WTL (WTL)</option>
                          <option value="WTT">WTT (WTT)</option>
                          <option value="WXT">WXT (WXT)</option>
                          <option value="WBB">Wild Beast Block (WBB)</option>
                          <option value="X10">X10 (X10)</option>
                          <option value="X100">X100 (X100)</option>
                          <option value="X2">X2 (X2)</option>
                          <option value="X2Y2">X2Y2 (X2Y2)</option>
                          <option value="XAGF0">XAGF0 (XAGF0)</option>
                          <option value="XAUT">XAUT (XAUT)</option>
                          <option value="XAV">XAV (XAV)</option>
                          <option value="XBTS">XBTS (XBTS)</option>
                          <option value="XBU">XBU (XBU)</option>
                          <option value="XBY">XBY (XBY)</option>
                          <option value="XCAD">XCAD (XCAD)</option>
                          <option value="XCE">XCE (XCE)</option>
                          <option value="XC">XCurrency (XC)</option>
                          <option value="XDC">XDC (XDC)</option>
                          <option value="XEC">XEC (XEC)</option>
                          <option value="XEN">XEN (XEN)</option>
                          <option value="XFLR">XFLR (XFLR)</option>
                          <option value="XIN">XIN (XIN)</option>
                          <option value="XIOS">XIOS (XIOS)</option>
                          <option value="XJO">XJO (XJO)</option>
                          <option value="XLMF0">XLMF0 (XLMF0)</option>
                          <option value="XMON">XMON (XMON)</option>
                          <option value="XMRF0">XMRF0 (XMRF0)</option>
                          <option value="XNO">XNO (XNO)</option>
                          <option value="XPTX">XPTX (XPTX)</option>
                          <option value="XRD">XRD (XRD)</option>
                          <option value="XRPF0">XRPF0 (XRPF0)</option>
                          <option value="XRPUP">XRPUP (XRPUP)</option>
                          <option value="XS">XS (XS)</option>
                          <option value="XSY">XSY (XSY)</option>
                          <option value="XT">XT (XT)</option>
                          <option value="XTALK">XTALK (XTALK)</option>
                          <option value="XTR">XTR (XTR)</option>
                          <option value="XTZ">XTZ (XTZ)</option>
                          <option value="XTZF0">XTZF0 (XTZF0)</option>
                          <option value="XUP">XUP (XUP)</option>
                          <option value="XVE">XVE (XVE)</option>
                          <option value="XVS">XVS (XVS)</option>
                          <option value="XY">XY (XY)</option>
                          <option value="XYM">XYM (XYM)</option>
                          <option value="XYO">XYO (XYO)</option>
                          <option value="XAU">Xaurum (XAU)</option>
                          <option value="YDX">YDX (YDX)</option>
                          <option value="YES">YES (YES)</option>
                          <option value="YFI">YFI (YFI)</option>
                          <option value="YFII">YFII (YFII)</option>
                          <option value="YFL">YFL (YFL)</option>
                          <option value="YGG">YGG (YGG)</option>
                          <option value="YM">YM (YM)</option>
                          <option value="YN">YN (YN)</option>
                          <option value="YO">YO (YO)</option>
                          <option value="YOB2X">YOB2X (YOB2X)</option>
                          <option value="YODA">YODA (YODA)</option>
                          <option value="YOMI">YOMI (YOMI)</option>
                          <option value="YONE">YONE (YONE)</option>
                          <option value="YOTRA">YOTRA (YOTRA)</option>
                          <option value="YOZI">YOZI (YOZI)</option>
                          <option value="YUPA">YUPA (YUPA)</option>
                          <option value="ZAP">ZAP (ZAP)</option>
                          <option value="ZAR">ZAR (ZAR)</option>
                          <option value="ZCN">ZCN (ZCN)</option>
                          <option value="ZECD">ZECD (ZECD)</option>
                          <option value="ZECF0">ZECF0 (ZECF0)</option>
                          <option value="ZEN">ZEN (ZEN)</option>
                          <option value="ZENI">ZENI (ZENI)</option>
                          <option value="ZIL">ZIL (ZIL)</option>
                          <option value="ZIP">ZIP (ZIP)</option>
                          <option value="ZKS">ZKS (ZKS)</option>
                          <option value="ZLOT">ZLOT (ZLOT)</option>
                          <option value="ZMT">ZMT (ZMT)</option>
                          <option value="ZRX">ZRX (ZRX)</option>
                          <option value="ZUR">ZUR (ZUR)</option>
                          <option value="ZXZ">ZXZ (ZXZ)</option>
                          <option value="ZEC">Zcash (ZEC)</option>
                          <option value="ZEIT">Zeitcoin (ZEIT)</option>
                          <option value="ZRC">ZiftrCOIN (ZRC)</option>
                          <option value="ZBC">Zilbercoin (ZBC)</option>
                          <option value="ICASH">iCash (ICASH)</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input id="amount-2" type="text" placeholder="enter amount" style="width:100%;font-family: Arial; font-size: 100%; height: auto;">
                    </td>
                    <td>
                      <select id="cur-2" style="width:100%;font-family: Arial; font-size: 100%; height: auto;">
                        <option value="1337">1337 (1337)</option>
                        <option value="1INCH">1INCH (1INCH)</option>
                        <option value="2015">2015 (2015)</option>
                        <option value="256">256 (256)</option>
                        <option value="2GIVE">2GIVE (2GIVE)</option>
                        <option value="404">404 (404)</option>
                        <option value="420G">420G (420G)</option>
                        <option value="700">700 (700)</option>
                        <option value="777">777 (777)</option>
                        <option value="8BIT">8Bit (8BIT)</option>
                        <option value="98">98 (98)</option>
                        <option value="AAA">AAA (AAA)</option>
                        <option value="AAVE">AAVE (AAVE)</option>
                        <option value="ABYSS">ABYSS (ABYSS)</option>
                        <option value="ACA">ACA (ACA)</option>
                        <option value="ACES">ACES (ACES)</option>
                        <option value="ACH">ACH (ACH)</option>
                        <option value="ACM">ACM (ACM)</option>
                        <option value="ACOIN">ACOIN (ACOIN)</option>
                        <option value="AD">AD (AD)</option>
                        <option value="ADAF0">ADAF0 (ADAF0)</option>
                        <option value="ADAM">ADAM (ADAM)</option>
                        <option value="ADAUP">ADAUP (ADAUP)</option>
                        <option value="ADD">ADD (ADD)</option>
                        <option value="ADG">ADG (ADG)</option>
                        <option value="ADT">ADT (ADT)</option>
                        <option value="ADX">ADX (ADX)</option>
                        <option value="AE">AE (AE)</option>
                        <option value="AECC">AECC (AECC)</option>
                        <option value="AERGO">AERGO (AERGO)</option>
                        <option value="AFC">AFC (AFC)</option>
                        <option value="AGIX">AGIX (AGIX)</option>
                        <option value="AGLD">AGLD (AGLD)</option>
                        <option value="AGR">AGR (AGR)</option>
                        <option value="AGRI">AGRI (AGRI)</option>
                        <option value="AI">AI (AI)</option>
                        <option value="AION">AION (AION)</option>
                        <option value="AIR">AIR (AIR)</option>
                        <option value="AIX">AIX (AIX)</option>
                        <option value="AKITA">AKITA (AKITA)</option>
                        <option value="AKRO">AKRO (AKRO)</option>
                        <option value="AL">AL (AL)</option>
                        <option value="ALA">ALA (ALA)</option>
                        <option value="ALBT">ALBT (ALBT)</option>
                        <option value="ALC">ALC (ALC)</option>
                        <option value="ALCX">ALCX (ALCX)</option>
                        <option value="ALG">ALG (ALG)</option>
                        <option value="ALGF0">ALGF0 (ALGF0)</option>
                        <option value="ALGO">ALGO (ALGO)</option>
                        <option value="ALICE">ALICE (ALICE)</option>
                        <option value="ALIEN">ALIEN (ALIEN)</option>
                        <option value="ALISA">ALISA (ALISA)</option>
                        <option value="ALL">ALL (ALL)</option>
                        <option value="ALPHA">ALPHA (ALPHA)</option>
                        <option value="ALTC">ALTC (ALTC)</option>
                        <option value="AM">AM (AM)</option>
                        <option value="AMB">AMB (AMB)</option>
                        <option value="AMM">AMM (AMM)</option>
                        <option value="AMO">AMO (AMO)</option>
                        <option value="AMPF0">AMPF0 (AMPF0)</option>
                        <option value="ANA">ANA (ANA)</option>
                        <option value="AND">AND (AND)</option>
                        <option value="ANKR">ANKR (ANKR)</option>
                        <option value="ANO">ANO (ANO)</option>
                        <option value="APE">APE (APE)</option>
                        <option value="APEF0">APEF0 (APEF0)</option>
                        <option value="API3">API3 (API3)</option>
                        <option value="APT">APT (APT)</option>
                        <option value="APTF0">APTF0 (APTF0)</option>
                        <option value="APX">APX (APX)</option>
                        <option value="AR">AR (AR)</option>
                        <option value="ARA">ARA (ARA)</option>
                        <option value="ARCO">ARCO (ARCO)</option>
                        <option value="ARCX">ARCX (ARCX)</option>
                        <option value="ARDR">ARDR (ARDR)</option>
                        <option value="ARE">ARE (ARE)</option>
                        <option value="ARK">ARK (ARK)</option>
                        <option value="ARM">ARM (ARM)</option>
                        <option value="ARPA">ARPA (ARPA)</option>
                        <option value="ARTA">ARTA (ARTA)</option>
                        <option value="ASAFE">ASAFE (ASAFE)</option>
                        <option value="ASK">ASK (ASK)</option>
                        <option value="ASM">ASM (ASM)</option>
                        <option value="ASR">ASR (ASR)</option>
                        <option value="AST">AST (AST)</option>
                        <option value="ASTR">ASTR (ASTR)</option>
                        <option value="AT">AT (AT)</option>
                        <option value="ATA">ATA (ATA)</option>
                        <option value="ATB">ATB (ATB)</option>
                        <option value="ATI">ATI (ATI)</option>
                        <option value="ATLAS">ATLAS (ATLAS)</option>
                        <option value="ATM">ATM (ATM)</option>
                        <option value="ATO">ATO (ATO)</option>
                        <option value="ATOF0">ATOF0 (ATOF0)</option>
                        <option value="ATOM">ATOM (ATOM)</option>
                        <option value="AUDIO">AUDIO (AUDIO)</option>
                        <option value="AUTO">AUTO (AUTO)</option>
                        <option value="AV">AV (AV)</option>
                        <option value="AVA">AVA (AVA)</option>
                        <option value="AVAX">AVAX (AVAX)</option>
                        <option value="AVE">AVE (AVE)</option>
                        <option value="AXG">AXG (AXG)</option>
                        <option value="AXL">AXL (AXL)</option>
                        <option value="AXS">AXS (AXS)</option>
                        <option value="AXSF0">AXSF0 (AXSF0)</option>
                        <option value="AY">AY (AY)</option>
                        <option value="AZ">AZ (AZ)</option>
                        <option value="ANC">Anoncoin (ANC)</option>
                        <option value="ANTI">AntiBitcoin (ANTI)</option>
                        <option value="ANT">Aragon (ANT)</option>
                        <option value="ARG">Argentum (ARG)</option>
                        <option value="ARI">Aricoin (ARI)</option>
                        <option value="REP">Augur (REP)</option>
                        <option value="AUD">Australian Dollar (AUD)</option>
                        <option value="B2">B2 (B2)</option>
                        <option value="B20">B20 (B20)</option>
                        <option value="B2B">B2B (B2B)</option>
                        <option value="B2M">B2M (B2M)</option>
                        <option value="B2X">B2X (B2X)</option>
                        <option value="BA">BA (BA)</option>
                        <option value="BAB">BAB (BAB)</option>
                        <option value="BAKE">BAKE (BAKE)</option>
                        <option value="BAL">BAL (BAL)</option>
                        <option value="BAND">BAND (BAND)</option>
                        <option value="BAR">BAR (BAR)</option>
                        <option value="BASH">BASH (BASH)</option>
                        <option value="BAT">BAT (BAT)</option>
                        <option value="BBB">BBB (BBB)</option>
                        <option value="BBT">BBT (BBT)</option>
                        <option value="BCA">BCA (BCA)</option>
                        <option value="BCHN">BCHN (BCHN)</option>
                        <option value="BCHSV">BCHSV (BCHSV)</option>
                        <option value="BCM">BCM (BCM)</option>
                        <option value="BCP">BCP (BCP)</option>
                        <option value="BDC">BDC (BDC)</option>
                        <option value="BDOT">BDOT (BDOT)</option>
                        <option value="BEAM">BEAM (BEAM)</option>
                        <option value="BEATX">BEATX (BEATX)</option>
                        <option value="BEEP">BEEP (BEEP)</option>
                        <option value="BEL">BEL (BEL)</option>
                        <option value="BENJI">BENJI (BENJI)</option>
                        <option value="BERN">BERNcash (BERN)</option>
                        <option value="BEST">BEST (BEST)</option>
                        <option value="BETA">BETA (BETA)</option>
                        <option value="BETH">BETH (BETH)</option>
                        <option value="BFT">BFT (BFT)</option>
                        <option value="BGF">BGF (BGF)</option>
                        <option value="BHC">BHC (BHC)</option>
                        <option value="BICO">BICO (BICO)</option>
                        <option value="BID">BID (BID)</option>
                        <option value="BIDR">BIDR (BIDR)</option>
                        <option value="BIFI">BIFI (BIFI)</option>
                        <option value="BILL">BILL (BILL)</option>
                        <option value="BIO">BIO (BIO)</option>
                        <option value="BIT">BIT (BIT)</option>
                        <option value="BIT16">BIT16 (BIT16)</option>
                        <option value="BITCI">BITCI (BITCI)</option>
                        <option value="BITS">BITS (BITS)</option>
                        <option value="BLU">BLU (BLU)</option>
                        <option value="BLUE">BLUE (BLUE)</option>
                        <option value="BLY">BLY (BLY)</option>
                        <option value="BLZ">BLZ (BLZ)</option>
                        <option value="BMN">BMN (BMN)</option>
                        <option value="BNB">BNB (BNB)</option>
                        <option value="BNBUP">BNBUP (BNBUP)</option>
                        <option value="BNBX">BNBX (BNBX)</option>
                        <option value="BNT">BNT (BNT)</option>
                        <option value="BNX">BNX (BNX)</option>
                        <option value="BOBA">BOBA (BOBA)</option>
                        <option value="BOND">BOND (BOND)</option>
                        <option value="BONK">BONK (BONK)</option>
                        <option value="BOO">BOO (BOO)</option>
                        <option value="BOSON">BOSON (BOSON)</option>
                        <option value="BOSS">BOSS (BOSS)</option>
                        <option value="BOT">BOT (BOT)</option>
                        <option value="BR">BR (BR)</option>
                        <option value="BSC">BSC (BSC)</option>
                        <option value="BST">BST (BST)</option>
                        <option value="BSTK">BSTK (BSTK)</option>
                        <option value="BSW">BSW (BSW)</option>
                        <option value="BTCA">BTCA (BTCA)</option>
                        <option value="BTCF0">BTCF0 (BTCF0)</option>
                        <option value="BTCM">BTCM (BTCM)</option>
                        <option value="BTCS">BTCS (BTCS)</option>
                        <option value="BTCST">BTCST (BTCST)</option>
                        <option value="BTCU">BTCU (BTCU)</option>
                        <option value="BTCUP">BTCUP (BTCUP)</option>
                        <option value="BTSE">BTSE (BTSE)</option>
                        <option value="BTT">BTT (BTT)</option>
                        <option value="BTTC">BTTC (BTTC)</option>
                        <option value="BUM">BUM (BUM)</option>
                        <option value="BUSD">BUSD (BUSD)</option>
                        <option value="BIGUP">BigUp (BIGUP)</option>
                        <option value="BTS">BitShares (BTS)</option>
                        <option value="BTC">Bitcoin (BTC)</option>
                        <option value="BCH">Bitcoin Cash (BCH)</option>
                        <option value="BTG">Bitcoin Gold (BTG)</option>
                        <option value="BTM">Bitmark (BTM)</option>
                        <option value="JACK">BlackJack (JACK)</option>
                        <option value="BLK">Blackcoin (BLK)</option>
                        <option value="BRL">Brazilian Real (BRL)</option>
                        <option value="GBP">British Pound Sterling (GBP)</option>
                        <option value="C20">C20 (C20)</option>
                        <option value="C98">C98 (C98)</option>
                        <option value="CA">CA (CA)</option>
                        <option value="CAG">CAG (CAG)</option>
                        <option value="CAID">CAID (CAID)</option>
                        <option value="CAKE">CAKE (CAKE)</option>
                        <option value="CAT">CAT (CAT)</option>
                        <option value="CAW">CAW (CAW)</option>
                        <option value="CB">CB (CB)</option>
                        <option value="CBC">CBC (CBC)</option>
                        <option value="CCB">CCB (CCB)</option>
                        <option value="CCC">CCC (CCC)</option>
                        <option value="CCD">CCD (CCD)</option>
                        <option value="CCL">CCL (CCL)</option>
                        <option value="CCUP">CCUP (CCUP)</option>
                        <option value="CDT">CDT (CDT)</option>
                        <option value="CEA">CEA (CEA)</option>
                        <option value="CEEK">CEEK (CEEK)</option>
                        <option value="CEL">CEL (CEL)</option>
                        <option value="CELO">CELO (CELO)</option>
                        <option value="CELR">CELR (CELR)</option>
                        <option value="CF">CF (CF)</option>
                        <option value="CFC">CFC (CFC)</option>
                        <option value="CFX">CFX (CFX)</option>
                        <option value="CH">CH (CH)</option>
                        <option value="CHEMX">CHEMX (CHEMX)</option>
                        <option value="CHESS">CHESS (CHESS)</option>
                        <option value="CHEX">CHEX (CHEX)</option>
                        <option value="CHR">CHR (CHR)</option>
                        <option value="CHRG">CHRG (CHRG)</option>
                        <option value="CHSB">CHSB (CHSB)</option>
                        <option value="CHZ">CHZ (CHZ)</option>
                        <option value="CIN">CIN (CIN)</option>
                        <option value="CITY">CITY (CITY)</option>
                        <option value="CJC">CJC (CJC)</option>
                        <option value="CKB">CKB (CKB)</option>
                        <option value="CKS">CKS (CKS)</option>
                        <option value="CLO">CLO (CLO)</option>
                        <option value="CLUD">CLUD (CLUD)</option>
                        <option value="CLV">CLV (CLV)</option>
                        <option value="CMC">CMC (CMC)</option>
                        <option value="CME">CME (CME)</option>
                        <option value="CN">CN (CN)</option>
                        <option value="CNB">CNB (CNB)</option>
                        <option value="CNG">CNG (CNG)</option>
                        <option value="CNH">CNH (CNH)</option>
                        <option value="CNHT">CNHT (CNHT)</option>
                        <option value="CNN">CNN (CNN)</option>
                        <option value="COCOS">COCOS (COCOS)</option>
                        <option value="COMP">COMP (COMP)</option>
                        <option value="CONV">CONV (CONV)</option>
                        <option value="CONX">CONX (CONX)</option>
                        <option value="CORAL">CORAL (CORAL)</option>
                        <option value="CORG">CORG (CORG)</option>
                        <option value="COS">COS (COS)</option>
                        <option value="COTI">COTI (COTI)</option>
                        <option value="CP">CP (CP)</option>
                        <option value="CRAVE">CRAVE (CRAVE)</option>
                        <option value="CRC">CRC (CRC)</option>
                        <option value="CREAM">CREAM (CREAM)</option>
                        <option value="CRED">CRED (CRED)</option>
                        <option value="CRM">CRM (CRM)</option>
                        <option value="CROC">CROC (CROC)</option>
                        <option value="CRON">CRON (CRON)</option>
                        <option value="CRPS">CRPS (CRPS)</option>
                        <option value="CRPT">CRPT (CRPT)</option>
                        <option value="CRT">CRT (CRT)</option>
                        <option value="CRV">CRV (CRV)</option>
                        <option value="CRVF0">CRVF0 (CRVF0)</option>
                        <option value="CRW">CRW (CRW)</option>
                        <option value="CRX">CRX (CRX)</option>
                        <option value="CTIC">CTIC (CTIC)</option>
                        <option value="CTK">CTK (CTK)</option>
                        <option value="CTSI">CTSI (CTSI)</option>
                        <option value="CTXC">CTXC (CTXC)</option>
                        <option value="CUDOS">CUDOS (CUDOS)</option>
                        <option value="CULT">CULT (CULT)</option>
                        <option value="CVC">CVC (CVC)</option>
                        <option value="CVP">CVP (CVP)</option>
                        <option value="CVX">CVX (CVX)</option>
                        <option value="CX">CX (CX)</option>
                        <option value="CXT">CXT (CXT)</option>
                        <option value="CYT">CYT (CYT)</option>
                        <option value="CAD">Canadian Dollar (CAD)</option>
                        <option value="CPC">Capricoin (CPC)</option>
                        <option value="ADA">Cardano (ADA)</option>
                        <option value="CLAM">Clams (CLAM)</option>
                        <option value="XCH">ClearingHouse (XCH)</option>
                        <option value="CLOAK">Cloakcoin (CLOAK)</option>
                        <option value="C2">Coin2.1 (C2)</option>
                        <option value="CV2">Colossuscoin2.0 (CV2)</option>
                        <option value="XCN">Cryptonite (XCN)</option>
                        <option value="DA">DA (DA)</option>
                        <option value="DAI">DAI (DAI)</option>
                        <option value="DALC">DALC (DALC)</option>
                        <option value="DANK">DANK (DANK)</option>
                        <option value="DAR">DAR (DAR)</option>
                        <option value="DASHS">DASHS (DASHS)</option>
                        <option value="DATA">DATA (DATA)</option>
                        <option value="DB">DB (DB)</option>
                        <option value="DBET">DBET (DBET)</option>
                        <option value="DBTC">DBTC (DBTC)</option>
                        <option value="DC">DC (DC)</option>
                        <option value="DCYP">DCYP (DCYP)</option>
                        <option value="DEA">DEA (DEA)</option>
                        <option value="DEBT">DEBT (DEBT)</option>
                        <option value="DEC">DEC (DEC)</option>
                        <option value="DECR">DECR (DECR)</option>
                        <option value="DEGO">DEGO (DEGO)</option>
                        <option value="DENT">DENT (DENT)</option>
                        <option value="DEX">DEX (DEX)</option>
                        <option value="DEXE">DEXE (DEXE)</option>
                        <option value="DF">DF (DF)</option>
                        <option value="DGCS">DGCS (DGCS)</option>
                        <option value="DIA">DIA (DIA)</option>
                        <option value="DICE">DICE (DICE)</option>
                        <option value="DLC">DLC (DLC)</option>
                        <option value="DLRS">DLRS (DLRS)</option>
                        <option value="DMT">DMT (DMT)</option>
                        <option value="DN">DN (DN)</option>
                        <option value="DO">DO (DO)</option>
                        <option value="DOCK">DOCK (DOCK)</option>
                        <option value="DODO">DODO (DODO)</option>
                        <option value="DORA">DORA (DORA)</option>
                        <option value="DOT">DOT (DOT)</option>
                        <option value="DOTA">DOTA (DOTA)</option>
                        <option value="DOTF0">DOTF0 (DOTF0)</option>
                        <option value="DRACO">DRACO (DRACO)</option>
                        <option value="DREP">DREP (DREP)</option>
                        <option value="DRKT">DRKT (DRKT)</option>
                        <option value="DROP">DROP (DROP)</option>
                        <option value="DRT">DRT (DRT)</option>
                        <option value="DTT">DTT (DTT)</option>
                        <option value="DUO">DUO (DUO)</option>
                        <option value="DUR">DUR (DUR)</option>
                        <option value="DUSK">DUSK (DUSK)</option>
                        <option value="DUST">DUST (DUST)</option>
                        <option value="DVF">DVF (DVF)</option>
                        <option value="DX">DX (DX)</option>
                        <option value="DXC">DXC (DXC)</option>
                        <option value="DXO">DXO (DXO)</option>
                        <option value="DYDX">DYDX (DYDX)</option>
                        <option value="DYP">DYP (DYP)</option>
                        <option value="DASH">Dash (DASH)</option>
                        <option value="DCR">Decred (DCR)</option>
                        <option value="DGB">Digibyte (DGB)</option>
                        <option value="DIME">Dimecoin (DIME)</option>
                        <option value="DOGE">Dogecoin (DOGE)</option>
                        <option value="EAGS">EAGS (EAGS)</option>
                        <option value="EAR">EAR (EAR)</option>
                        <option value="ECASH">ECASH (ECASH)</option>
                        <option value="ECLI">ECLI (ECLI)</option>
                        <option value="ECN">ECN (ECN)</option>
                        <option value="ECS">ECS (ECS)</option>
                        <option value="ED">ED (ED)</option>
                        <option value="EDC">EDC (EDC)</option>
                        <option value="EDIT">EDIT (EDIT)</option>
                        <option value="EDO">EDO (EDO)</option>
                        <option value="EEP">EEP (EEP)</option>
                        <option value="EER">EER (EER)</option>
                        <option value="EET">EET (EET)</option>
                        <option value="EFFM">EFFM (EFFM)</option>
                        <option value="EFI">EFI (EFI)</option>
                        <option value="EGAME">EGAME (EGAME)</option>
                        <option value="EGG">EGG (EGG)</option>
                        <option value="EGLD">EGLD (EGLD)</option>
                        <option value="EGO">EGO (EGO)</option>
                        <option value="EGOLD">EGOLD (EGOLD)</option>
                        <option value="ELC">ELC (ELC)</option>
                        <option value="ELE">ELE (ELE)</option>
                        <option value="ELF">ELF (ELF)</option>
                        <option value="ELON">ELON (ELON)</option>
                        <option value="ELR">ELR (ELR)</option>
                        <option value="EMB">EMB (EMB)</option>
                        <option value="EMT">EMT (EMT)</option>
                        <option value="EN">EN (EN)</option>
                        <option value="ENE">ENE (ENE)</option>
                        <option value="ENJ">ENJ (ENJ)</option>
                        <option value="ENS">ENS (ENS)</option>
                        <option value="ENT">ENT (ENT)</option>
                        <option value="ENTER">ENTER (ENTER)</option>
                        <option value="EOC">EOC (EOC)</option>
                        <option value="EOS">EOS (EOS)</option>
                        <option value="EOSF0">EOSF0 (EOSF0)</option>
                        <option value="EPC">EPC (EPC)</option>
                        <option value="EPV">EPV (EPV)</option>
                        <option value="EPX">EPX (EPX)</option>
                        <option value="EPY">EPY (EPY)</option>
                        <option value="EQ">EQ (EQ)</option>
                        <option value="EQUAL">EQUAL (EQUAL)</option>
                        <option value="ERN">ERN (ERN)</option>
                        <option value="ERP">ERP (ERP)</option>
                        <option value="ERR">ERR (ERR)</option>
                        <option value="ERSDL">ERSDL (ERSDL)</option>
                        <option value="ESC">ESC (ESC)</option>
                        <option value="ESP">ESP (ESP)</option>
                        <option value="ET">ET (ET)</option>
                        <option value="ETCF0">ETCF0 (ETCF0)</option>
                        <option value="ETCV">ETCV (ETCV)</option>
                        <option value="ETH2X">ETH2X (ETH2X)</option>
                        <option value="ETHF">ETHF (ETHF)</option>
                        <option value="ETHF0">ETHF0 (ETHF0)</option>
                        <option value="ETHS">ETHS (ETHS)</option>
                        <option value="ETHUP">ETHUP (ETHUP)</option>
                        <option value="ETHW">ETHW (ETHW)</option>
                        <option value="ETP">ETP (ETP)</option>
                        <option value="EUC">EUC (EUC)</option>
                        <option value="EURC">EURC (EURC)</option>
                        <option value="EURF0">EURF0 (EURF0)</option>
                        <option value="EUS">EUS (EUS)</option>
                        <option value="EUT">EUT (EUT)</option>
                        <option value="EUTF0">EUTF0 (EUTF0)</option>
                        <option value="EVIL">EVIL (EVIL)</option>
                        <option value="EVN">EVN (EVN)</option>
                        <option value="EXFI">EXFI (EXFI)</option>
                        <option value="EXIT">EXIT (EXIT)</option>
                        <option value="EXM">EXM (EXM)</option>
                        <option value="EXMR">EXMR (EXMR)</option>
                        <option value="EXO">EXO (EXO)</option>
                        <option value="EY">EY (EY)</option>
                        <option value="ETH">Ethereum (ETH)</option>
                        <option value="ETC">Ethereum Classic (ETC)</option>
                        <option value="EUR">Euro (EUR)</option>
                        <option value="EGC">EverGreenCoin (EGC)</option>
                        <option value="EXE">Execoin (EXE)</option>
                        <option value="EXP">Expanse (EXP)</option>
                        <option value="F16">F16 (F16)</option>
                        <option value="FARM">FARM (FARM)</option>
                        <option value="FAZZ">FAZZ (FAZZ)</option>
                        <option value="FBT">FBT (FBT)</option>
                        <option value="FC">FC (FC)</option>
                        <option value="FCL">FCL (FCL)</option>
                        <option value="FCT2">FCT2 (FCT2)</option>
                        <option value="FET">FET (FET)</option>
                        <option value="FFC">FFC (FFC)</option>
                        <option value="FG">FG (FG)</option>
                        <option value="FGZ">FGZ (FGZ)</option>
                        <option value="FI">FI (FI)</option>
                        <option value="FIDA">FIDA (FIDA)</option>
                        <option value="FIDGT">FIDGT (FIDGT)</option>
                        <option value="FIL">FIL (FIL)</option>
                        <option value="FILF0">FILF0 (FILF0)</option>
                        <option value="FIN">FIN (FIN)</option>
                        <option value="FIO">FIO (FIO)</option>
                        <option value="FIRE">FIRE (FIRE)</option>
                        <option value="FIRO">FIRO (FIRO)</option>
                        <option value="FIS">FIS (FIS)</option>
                        <option value="FIST">FIST (FIST)</option>
                        <option value="FIT">FIT (FIT)</option>
                        <option value="FITFI">FITFI (FITFI)</option>
                        <option value="FJC">FJC (FJC)</option>
                        <option value="FLM">FLM (FLM)</option>
                        <option value="FLOKI">FLOKI (FLOKI)</option>
                        <option value="FLOW">FLOW (FLOW)</option>
                        <option value="FLR">FLR (FLR)</option>
                        <option value="FLUX">FLUX (FLUX)</option>
                        <option value="FNTB">FNTB (FNTB)</option>
                        <option value="FOAM">FOAM (FOAM)</option>
                        <option value="FOR">FOR (FOR)</option>
                        <option value="FORTH">FORTH (FORTH)</option>
                        <option value="FPFT">FPFT (FPFT)</option>
                        <option value="FRAX">FRAX (FRAX)</option>
                        <option value="FRE">FRE (FRE)</option>
                        <option value="FREE">FREE (FREE)</option>
                        <option value="USD" selected="">US Dollar (USD)</option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
                <div style="text-align:center;font-size:10px;opacity:0.6;padding-top:10px;">Powered by <a
                    href="https://www.cryptonator.com" style="color:#000000;" target="_blank">Cryptonator Bitcoin
                    wallet</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="ms-lg-4">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">Frequently asked questions</h4>
            <p class="text-muted para-desc mb-0">Start working with <span class="text-primary fw-bold">Landrick</span>
              that can provide everything you need to generate
              awareness, drive traffic, connect.</p>
          </div>

          <div class="faq-content mt-4">
            <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
              <ngb-panel cardClass="border-0 rounded shadow mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingone">
                    <h6 class="title mb-0"> How does it work ? </h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but
                    the majority have suffered alteration in some form.</p>
                </ng-template>
              </ngb-panel>
              <ngb-panel cardClass="border-0 rounded shadow mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingtwo">
                    <h6 class="title mb-0"> Do I need a designer to use Landrick ? </h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but
                    the majority have suffered alteration in some form.</p>
                </ng-template>
              </ngb-panel>
              <ngb-panel cardClass="border-0 rounded shadow mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0"> What do I need to do to start selling ? </h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but
                    the majority have suffered alteration in some form.</p>
                </ng-template>
              </ngb-panel>
              <ngb-panel cardClass="border-0 rounded shadow mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingfour">
                    <h6 class="title mb-0"> What happens when I receive an order ? </h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but
                    the majority have suffered alteration in some form.</p>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!-- Rate End -->

  <!-- Testi Start -->
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Buyer's Review</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-lg-9 mt-4 pt-2 text-center">
        <app-review-testmonial [reviewData]="reviewData" [firstimage]="1"></app-review-testmonial>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!-- Testi End -->
</section>
<!--end section-->
<!-- Section End -->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->