  <!-- Hero Start -->
  <section class="bg-half-170 d-table w-100" style="background: url('assets/images/corporate/pages.jpg') top;" id="home">
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading title-heading">
                    <div class="pages-heading">
                        <h2 class="text-white title-dark"> Corporate Teams Respond After Crisis </h2>
                        <ul class="list-unstyled mt-4">
                            <li class="list-inline-item h6 user text-white-50 me-2"><i class="mdi mdi-account"></i> Calvin Carlo</li>
                            <li class="list-inline-item h6 date text-white-50"><i class="mdi mdi-calendar-check"></i> 25th June 2021</li>
                        </ul>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row--> 

        
        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a routerLink="/index-corporate">Business</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Blog</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Hero End -->

<!-- Blog STart -->
<section class="section">
    <div class="container">
        <div class="row">
            <!-- BLog Start -->
            <div class="col-lg-8 col-md-6">
                <div class="me-lg-5">
                    <p class="text-muted">This is required when, for example, the final text is not yet available. Dummy text is also known as 'fill text'. It is said that song composers of the past used dummy texts as lyrics when writing melodies in order to have a 'ready-made' text to sing with the melody.</p>
                        
                    <ul class="list-unstyled d-flex justify-content-between mt-4">
                        <li class="list-inline-item user me-2"><a href="javascript:void(0)" class="text-muted"><i class="uil uil-user text-dark"></i> Calvin Carlo</a></li>
                        <li class="list-inline-item date text-muted"><i class="uil uil-calendar-alt text-dark"></i> 25th June 2021</li>
                    </ul>
                    
                    <img src="assets/images/blog/bg2.jpg" class="img-fluid rounded-md shadow" alt="">

                    <h5 class="mt-4">Mornings contain the secret to an extraordinarily successful life</h5>

                    <p class="text-muted">Due to its widespread use as filler text for layouts, non-readability is of great importance: human perception is tuned to recognize certain patterns and repetitions in texts. If the distribution of letters and 'words' is random, the reader will not be distracted from making a neutral judgement on the visual impact and readability of the typefaces (typography), or the distribution of text on the page (layout or type area).</p>

                    <h5 class="mt-4">Comments :</h5>

                    <ul class="media-list list-unstyled mb-0">
                        <li class="mt-4">
                            <div class="d-flex justify-content-between">
                                <div class="d-flex align-items-center">
                                    <a class="pe-3" href="javascript:void(0)">
                                        <img src="assets/images/client/01.jpg" class="img-fluid avatar avatar-md-sm rounded-circle shadow" alt="img">
                                    </a>
                                    <div class="flex-1 commentor-detail">
                                        <h6 class="mb-0"><a href="javascript:void(0)" class="text-dark media-heading">Lorenzo Peterson</a></h6>
                                        <small class="text-muted">15th August, 2021 at 01:25 pm</small>
                                    </div>
                                </div>
                                <a href="javascript:void(0)" class="text-muted"><i class="mdi mdi-reply"></i> Reply</a>
                            </div>
                            <div class="mt-3">
                                <p class="text-muted fst-italic p-3 bg-light rounded">" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "</p>
                            </div>
                        </li>

                        <li class="mt-4">
                            <div class="d-flex justify-content-between">
                                <div class="d-flex align-items-center">
                                    <a class="pe-3" href="javascript:void(0)">
                                        <img src="assets/images/client/02.jpg" class="img-fluid avatar avatar-md-sm rounded-circle shadow" alt="img">
                                    </a>
                                    <div class="flex-1 commentor-detail">
                                        <h6 class="mb-0"><a href="javascript:void(0)" class="media-heading text-dark">Tammy Camacho</a></h6>
                                        <small class="text-muted">15th August, 2021 at 05:44 pm</small>
                                    </div>
                                </div>
                                <a href="javascript:void(0)" class="text-muted"><i class="mdi mdi-reply"></i> Reply</a>
                            </div>
                            <div class="mt-3">
                                <p class="text-muted fst-italic p-3 bg-light rounded">" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "</p>
                            </div>
                        </li>
                        
                        <li class="mt-4">
                            <div class="d-flex justify-content-between">
                                <div class="d-flex align-items-center">
                                    <a class="pe-3" href="javascript:void(0)">
                                        <img src="assets/images/client/03.jpg" class="img-fluid avatar avatar-md-sm rounded-circle shadow" alt="img">
                                    </a>
                                    <div class="flex-1 commentor-detail">
                                        <h6 class="mb-0"><a href="javascript:void(0)" class="media-heading text-dark">Tammy Camacho</a></h6>
                                        <small class="text-muted">16th August, 2021 at 03:44 pm</small>
                                    </div>
                                </div>
                                <a href="javascript:void(0)" class="text-muted"><i class="mdi mdi-reply"></i> Reply</a>
                            </div>
                            <div class="mt-3">
                                <p class="text-muted fst-italic p-3 bg-light rounded">" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "</p>
                            </div>

                            <ul class="list-unstyled ps-4 ps-md-5 sub-comment">
                                <li class="mt-4">
                                    <div class="d-flex justify-content-between">
                                        <div class="d-flex align-items-center">
                                            <a class="pe-3" href="javascript:void(0)">
                                                <img src="assets/images/client/01.jpg" class="img-fluid avatar avatar-md-sm rounded-circle shadow" alt="img">
                                            </a>
                                            <div class="flex-1 commentor-detail">
                                                <h6 class="mb-0"><a href="javascript:void(0)" class="text-dark media-heading">Lorenzo Peterson</a></h6>
                                                <small class="text-muted">17th August, 2021 at 01:25 pm</small>
                                            </div>
                                        </div>
                                        <a href="javascript:void(0)" class="text-muted"><i class="mdi mdi-reply"></i> Reply</a>
                                    </div>
                                    <div class="mt-3">
                                        <p class="text-muted fst-italic p-3 bg-light rounded">" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "</p>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <h5 class="mt-4">Leave A Comment :</h5>

                    <form class="mt-3">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="mb-3">
                                    <label class="form-label">Your Comment</label>
                                    <div class="form-icon position-relative">
                                        <i-feather name="message-circle" class="fea icon-sm icons"></i-feather>
                                        <textarea id="message" placeholder="Your Comment" rows="5" name="message" class="form-control ps-5" required=""></textarea>
                                    </div>
                                </div>
                            </div><!--end col-->

                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Name <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                        <input id="name2" name="name" type="text" placeholder="Name" class="form-control ps-5" required="">
                                    </div>
                                </div>
                            </div><!--end col-->

                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Your Email <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                        <input id="email2" type="email" placeholder="Email" name="email" class="form-control ps-5" required="">
                                    </div>
                                </div>
                            </div><!--end col-->

                            <div class="col-md-12">
                                <div class="send d-grid">
                                    <button type="submit" class="btn btn-primary">Send Message</button>
                                </div>
                            </div><!--end col-->
                        </div><!--end row-->
                    </form><!--end form-->
                </div>
            </div><!--end col-->
            <!-- BLog End -->

            <!-- START SIDEBAR -->
            <div class="col-lg-4 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="card border-0 sidebar sticky-bar rounded shadow">
                    <div class="card-body">
                        <!-- SEARCH -->
                        <div class="widget">
                            <form role="search" method="get">
                                <div class="input-group mb-3 border rounded">
                                    <input type="text" id="s" name="s" class="form-control border-0" placeholder="Search Keywords...">
                                    <button type="submit" class="input-group-text bg-transparent border-0" id="searchsubmit"><i class="uil uil-search"></i></button>
                                </div>
                            </form>
                        </div>
                        <!-- SEARCH -->

                        <!-- Categories -->
                        <div class="widget mb-4 pb-2">
                            <h5 class="widget-title">Categories</h5>
                            <ul class="list-unstyled mt-4 mb-0 blog-categories">
                                <li><a href="jvascript:void(0)">Finance</a> <span class="float-end">13</span></li>
                                <li><a href="jvascript:void(0)">Business</a> <span class="float-end">09</span></li>
                                <li><a href="jvascript:void(0)">Blog</a> <span class="float-end">18</span></li>
                                <li><a href="jvascript:void(0)">Corporate</a> <span class="float-end">20</span></li>
                                <li><a href="jvascript:void(0)">Investment</a> <span class="float-end">22</span></li>
                            </ul>
                        </div>
                        <!-- Categories -->

                        <!-- RECENT POST -->
                        <div class="widget mb-4 pb-2">
                            <h5 class="widget-title">Recent Post</h5>
                            <div class="mt-4">
                                <div class="d-flex align-items-center">
                                    <img src="assets/images/blog/01.jpg" class="avatar avatar-small rounded" style="width: auto;" alt="">
                                    <div class="flex-1 ms-3">
                                        <a href="javascript:void(0)" class="d-block title text-dark">Consultant Business</a>
                                        <span class="text-muted">15th April 2021</span>
                                    </div>
                                </div>

                                <div class="d-flex align-items-center mt-3">
                                    <img src="assets/images/blog/02.jpg" class="avatar avatar-small rounded" style="width: auto;" alt="">
                                    <div class="flex-1 ms-3">
                                        <a href="javascript:void(0)" class="d-block title text-dark">Grow Your Business</a>
                                        <span class="text-muted">15th April 2021</span>
                                    </div>
                                </div>
                                
                                <div class="d-flex align-items-center mt-3">
                                    <img src="assets/images/blog/03.jpg" class="avatar avatar-small rounded" style="width: auto;" alt="">
                                    <div class="flex-1 ms-3">
                                        <a href="javascript:void(0)" class="d-block title text-dark">Look On The Glorious Balance</a>
                                        <span class="text-muted">15th April 2021</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- RECENT POST -->

                        <!-- TAG CLOUDS -->
                        <div class="widget mb-4 pb-2">
                            <h5 class="widget-title">Tags Cloud</h5>
                            <div class="tagcloud mt-4">
                                <a href="jvascript:void(0)" class="rounded">Business</a>
                                <a href="jvascript:void(0)" class="rounded">Finance</a>
                                <a href="jvascript:void(0)" class="rounded">Marketing</a>
                                <a href="jvascript:void(0)" class="rounded">Fashion</a>
                                <a href="jvascript:void(0)" class="rounded">Bride</a>
                                <a href="jvascript:void(0)" class="rounded">Lifestyle</a>
                                <a href="jvascript:void(0)" class="rounded">Travel</a>
                                <a href="jvascript:void(0)" class="rounded">Beauty</a>
                                <a href="jvascript:void(0)" class="rounded">Video</a>
                                <a href="jvascript:void(0)" class="rounded">Audio</a>
                            </div>
                        </div>
                        <!-- TAG CLOUDS -->
                        
                        <!-- SOCIAL -->
                        <div class="widget">
                            <h5 class="widget-title">Follow us</h5>
                            <ul class="list-unstyled social-icon social mb-0 mt-4">
                                <li class="list-inline-item"><a href="javascript:void(0)" class="rounded"><i-feather name="facebook" class="fea icon-sm fea-social"></i-feather></a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="rounded"><i-feather name="instagram" class="fea icon-sm fea-social"></i-feather></a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="rounded"><i-feather name="twitter" class="fea icon-sm fea-social"></i-feather></a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="rounded"><i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather></a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="rounded"><i-feather name="github" class="fea icon-sm fea-social"></i-feather></a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="rounded"><i-feather name="youtube" class="fea icon-sm fea-social"></i-feather></a></li>
                                <li class="list-inline-item"><a href="javascript:void(0)" class="rounded"><i-feather name="gitlab" class="fea icon-sm fea-social"></i-feather></a></li>
                            </ul><!--end icon-->
                        </div>
                        <!-- SOCIAL -->
                    </div>
                </div>
            </div><!--end col-->
            <!-- END SIDEBAR -->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->
