import { Component } from '@angular/core';

@Component({
  selector: 'app-crypto-blog-detail',
  templateUrl: './crypto-blog-detail.component.html',
  styleUrls: ['./crypto-blog-detail.component.css']
})
export class CryptoBlogDetailComponent {
// Set Topbar
Menuoption = 'cryptocurrency'
footerVariant = 'footer-three';


}
