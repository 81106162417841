<!-- Hero End -->
<section class="home-slider position-relative" id="home">
  <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <owl-carousel-o id="customer-testi" [options]="customOptions">
        <ng-template carouselSlide>
          <div class="slide-inner d-flex align-items-center vh-100" style="background: center center;"
            style="background-image:url('assets/images/corporate/1.jpg')">
            <div class="bg-overlay"></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12">
                  <div class="title-heading text-center">
                    <h1 class="heading text-white title-dark mb-4">Discover the world of <br> business</h1>
                    <p class="para-desc mx-auto text-white-50">Launch your campaign and benefit from our expertise on
                      designing and managing conversion centered bootstrap4 html page.</p>

                    <div class="mt-4 pt-2">
                      <a href="javascript:void(0)" class="btn btn-primary">Get Started</a>
                    </div>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </div>
            <!--end container-->
          </div><!-- end slide-inner -->
          <!--end slide-->
        </ng-template>
        <ng-template carouselSlide>
          <div class="slide-inner d-flex align-items-center vh-100" style="background: center center;"
            style="background-image:url('assets/images/corporate/2.jpg')">
            <div class="bg-overlay"></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12">
                  <div class="title-heading text-center">
                    <h1 class="heading text-white title-dark mb-4">Meet our brand <br> new solution</h1>
                    <p class="para-desc mx-auto text-white-50">Launch your campaign and benefit from our expertise on
                      designing
                      and managing conversion centered bootstrap4 html page.</p>

                    <div class="mt-4 pt-2">
                      <a href="javascript:void(0)" class="btn btn-primary">Contact us</a>
                    </div>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </div>
            <!--end container-->
          </div><!-- end slide-inner -->
          <!--end slide-->
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</section>


<section class="section bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="features-absolute">
          <div class="row">
            <div class="col-lg-3 col-md-6 col-12">
              <div
                class="card features feature-clean feature-primary explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                <div class="icons text-primary text-center mx-auto">
                  <i class="uil uil-airplay d-block rounded h3 mb-0"></i>
                </div>

                <div class="card-body p-0 content">
                  <h5 class="mt-4"><a href="javascript:void(0)" class="title text-dark">Best Financial Advice</a></h5>
                  <p class="text-muted">The most well-known which is said to have originated</p>

                  <a href="javascript:void(0)" class="text-primary">Read More <i
                      class="uil uil-angle-right-b align-middle"></i></a>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-12 mt-4 mt-md-0 pt-2 pt-md-0">
              <div
                class="card features feature-clean feature-primary explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                <div class="icons text-primary text-center mx-auto">
                  <i class="uil uil-clipboard-alt d-block rounded h3 mb-0"></i>
                </div>

                <div class="card-body p-0 content">
                  <h5 class="mt-4"><a href="javascript:void(0)" class="title text-dark">Authorised Finance Brand</a>
                  </h5>
                  <p class="text-muted">The most well-known which is said to have originated</p>

                  <a href="javascript:void(0)" class="text-primary">Read More <i
                      class="uil uil-angle-right-b align-middle"></i></a>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
              <div
                class="card features feature-clean feature-primary explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                <div class="icons text-primary text-center mx-auto">
                  <i class="uil uil-credit-card-search d-block rounded h3 mb-0"></i>
                </div>

                <div class="card-body p-0 content">
                  <h5 class="mt-4"><a href="javascript:void(0)" class="title text-dark">Compehensive Advices</a></h5>
                  <p class="text-muted">The most well-known which is said to have originated</p>

                  <a href="javascript:void(0)" class="text-primary">Read More <i
                      class="uil uil-angle-right-b align-middle"></i></a>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
              <div
                class="card features feature-clean feature-primary explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                <div class="icons text-primary text-center mx-auto">
                  <i class="uil uil-ruler-combined d-block rounded h3 mb-0"></i>
                </div>

                <div class="card-body p-0 content">
                  <h5 class="mt-4"><a href="javascript:void(0)" class="title text-dark">Best Tax Advantages</a></h5>
                  <p class="text-muted">The most well-known which is said to have originated</p>

                  <a href="javascript:void(0)" class="text-primary">Read More <i
                      class="uil uil-angle-right-b align-middle"></i></a>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="position-relative">
          <img src="assets/images/company/about2.png" class="img-fluid mx-auto" alt="">
          <div class="play-icon">
            <a (click)="openModal(contentmodal)" href="javascript:void(0);" class="play-btn video-play-icon border-0">
              <i class="mdi mdi-play text-primary rounded-circle shadow"></i>
            </a>
            <ng-template #contentmodal let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <youtube-player [videoId]="'yba7hPeTSjk'" height="450" width="780">
                </youtube-player>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="section-title ms-lg-4">
          <h4 class="title mb-4">We are the largest <br> Business expert </h4>
          <p class="text-muted">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide
            everything you need to generate awareness, drive traffic, connect. Dummy text is text that is used in the
            publishing industry or by web designers to occupy the space which will later be filled with 'real' content.
            This is required when, for example, the final text is not yet available. Dummy texts have been in use by
            typesetters since the 16th century.</p>
          <a href="javascript:void(0)" class="btn btn-primary mt-3">Buy Now <i class="mdi mdi-chevron-right"></i></a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>

<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Key Features</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <app-features></app-features>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Start -->
<section class="section bg-white pt-0">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="video-solution-cta position-relative" style="z-index: 1;">

          <div class="position-relative">
            <img src="assets/images/cta-bg.jpg" class="img-fluid rounded-md shadow-lg" alt="">
            <div class="play-icon">
              <a (click)="openWindowCustomClass(content)" href="javascript:void(0);"
                class="play-btn video-play-icon border-0">
                <i class="mdi mdi-play text-primary rounded-circle shadow-lg"></i>
              </a>
              <ng-template #content let-modal>
                <div class="modal-header">
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <youtube-player [videoId]="'yba7hPeTSjk'" height="450" width="780">
                  </youtube-player>
                </div>
              </ng-template>
            </div>
          </div>

          <div class="content mt-md-4 pt-md-2">
            <div class="row justify-content-center">
              <div class="col-lg-10 text-center">
                <div class="row align-items-center">
                  <div class="col-md-6 mt-4 pt-2">
                    <div class="section-title text-md-start">
                      <h6 class="text-white-50">Team</h6>
                      <h4 class="title text-white title-dark mb-0">Meet Experience <br> Team Member</h4>
                    </div>
                  </div>

                  <div class="col-md-6 col-12 mt-4 pt-md-2">
                    <div class="section-title text-md-start">
                      <p class="text-white-50 para-desc">Start working with Landrick that can provide everything you
                        need to generate awareness, drive traffic, connect.</p>
                      <a href="javascript:void(0)" class="text-white title-dark">Read More <i-feather name="arrow-right"
                          class="fea icon-sm"></i-feather></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row -->
    <div class="feature-posts-placeholder bg-primary"></div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End -->

<section class="section">
  <div class="container">
    <div class="row mt-lg-4 align-items-center">
      <div class="col-lg-5 col-md-12 text-center text-lg-start">
        <div class="section-title mb-4 mb-lg-0 pb-2 pb-lg-0">
          <h4 class="title mb-4">Our Consulting Charge</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
          <a href="https://1.envato.market/4n73n" target="_blank" class="btn btn-primary mt-4">Buy Now <span
              class="badge rounded-pill bg-danger ms-2">v4.2</span></a>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-12">
        <div class="ms-lg-5">
          <div class="row align-items-center">
            <div class="col-md-6 col-12 mt-4 pt-4 px-md-0">
              <div class="card pricing pricing-primary starter-plan shadow rounded border-0">
                <div class="card-body py-5">
                  <h6 class="title fw-bold text-uppercase text-primary mb-4">Starter</h6>
                  <div class="d-flex mb-4">
                    <span class="h4 mb-0 mt-2">$</span>
                    <span class="price h1 mb-0">39</span>
                    <span class="h4 align-self-end mb-1">/mo</span>
                  </div>

                  <ul class="list-unstyled mb-0 ps-0">
                    <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                          class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                    <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                          class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                    <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                          class="uil uil-check-circle align-middle"></i></span>Free Appointments</li>
                    <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                          class="uil uil-check-circle align-middle"></i></span>Enhanced Security</li>
                  </ul>
                  <a href="javascript:void(0)" class="btn btn-primary mt-4">Get Started</a>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-md-6 col-12 mt-4 pt-2 pt-md-4 px-md-0">
              <div class="card pricing-rates bg-light shadow rounded border-0">
                <div class="card-body py-5">
                  <h6 class="title fw-bold text-uppercase text-primary mb-4">Professional</h6>
                  <div class="d-flex mb-4">
                    <span class="h4 mb-0 mt-2">$</span>
                    <span class="price h1 mb-0">59</span>
                    <span class="h4 align-self-end mb-1">/mo</span>
                  </div>

                  <ul class="list-unstyled mb-0 ps-0">
                    <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                          class="uil uil-check-circle align-middle"></i></span>Full Access</li>
                    <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                          class="uil uil-check-circle align-middle"></i></span>Source Files</li>
                    <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                          class="uil uil-check-circle align-middle"></i></span>Free Appointments</li>
                    <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                          class="uil uil-check-circle align-middle"></i></span>Enhanced Security</li>
                  </ul>
                  <a href="javascript:void(0)" class="btn btn-primary mt-4">Try it now</a>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Our Clients Said</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-lg-12 mt-4">
        <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center mb-4 pb-2">
      <div class="col-lg-6">
        <div class="section-title text-center text-lg-start">
          <h6 class="text-primary">Blog</h6>
          <h4 class="title mb-4 mb-lg-0">Reads Our Latest <br> News & Blog</h4>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6">
        <div class="section-title text-center text-lg-start">
          <p class="text-muted mb-0 mx-auto para-desc">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <app-blog [blogData]="blogData"></app-blog>
  </div>
  <!--end container-->
</section>
<!--end section-->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->